<template>
  <div>
    <el-select
      v-model="internalValue"
      placeholder="Select Font"
      size="small"
      @change="doChange"
    >
      <el-option-group :label="'Sans Serif Web Safe Fonts'">
        <el-option label="Arial" value="arial"></el-option>
        <el-option label="Arial Black" value="arial-black"></el-option>
        <el-option label="Tahoma" value="tahoma"></el-option>
        <el-option label="Trebuchet MS" value="trebuchet"></el-option>
        <el-option label="Verdana" value="verdana"></el-option>
      </el-option-group>
      <el-option-group :label="'Serif Web Safe Fonts'">
        <el-option label="Courier" value="courier"></el-option>
        <el-option label="Courier New" value="courier-new"></el-option>
        <el-option label="Georgia" value="georgia"></el-option>
        <el-option label="Times" value="times"></el-option>
        <el-option label="Times New Roman" value="times-new-roman"></el-option>
      </el-option-group>
      <el-option-group :label="'Google Fonts'">
        <el-option label="Open Sans" value="google-open"></el-option>
        <el-option label="Roboto" value="google-roboto"></el-option>
        <el-option label="Lato" value="google-lato"></el-option>
        <el-option label="Oswald" value="google-oswald"></el-option>
        <el-option
          label="Source Sans Pro"
          value="google-source-sans-pro"
        ></el-option>
        <el-option label="Montserrat" value="google-montserrat"></el-option>
        <el-option label="Raleway" value="google-raleway"></el-option>
        <el-option label="PT Sans" value="google-pt-sans"></el-option>
        <el-option label="Lora" value="google-lora"></el-option>
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    disabled: Boolean,
    value: {
      required: false,
      default: () => {
        return 'Arial';
      },
    },
    transport: {
      type: String,
      required: false,
      default: 'textFont',
    },
  },
  computed: {
    ...mapGetters('editor', ['getDecodedSelectedBlock']),
  },
  data() {
    return {
      currentValue: this.value,
      internalValue: undefined,
      inputDisabled: false,
    };
  },
  watch: {
    value(val) {
      this.internalValue = String(val);
    },
  },
  created() {
    if (this.value) {
      this.internalValue = String(this.value);
    }
  },
  methods: {
    ...mapActions('editor', ['transportBlockSettings']),
    doChange() {
      this.$emit('update:modelValue', this.internalValue);
      this.$emit('change', this.internalValue);
      this.transportProperties();
    },
    transportProperties() {
      if (this.transport === 'background.font')
        return this.transportBlockSettings({
          section: 'background',
          row: 1,
          column: 1,
          data: {
            useBackground: true,
            font: this.internalValue,
          },
        });

      return this.transportBlockSettings({
        section: this.getDecodedSelectedBlock.section,
        row: this.getDecodedSelectedBlock.row,
        column: this.getDecodedSelectedBlock.column,
        data: { textFont: this.internalValue },
      });
    },
  },
};
</script>
