<template>
  <span>
    <template v-if="loading"><font-awesome-icon icon="spinner" /></template>
    <template v-if="!loading">
      {{ getDisplayName }}
    </template>
  </span>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ContactFieldView',
  computed: {
    ...mapGetters('contacts', ['getContact']),
    getDisplayName() {
      return this.display.map((col) => this.getContactColumn(col)).join(' ');
    },
  },
  props: {
    id: {
      type: Number,
      require: true,
    },
    display: { type: Array },
  },
  data() {
    return {
      loading: true,
      contact: undefined, // Local copy of the data
    };
  },
  watch: {
    id() {
      this.load();
    },
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions('contacts', ['fetchContact']),
    async load() {
      this.loading = true;
      try {
        const r = await this.fetchContact(this.id);
        this.contact = r;
        this.loading = false;

      } catch (error) {
        console.error('error loading contact', error);
      }
    },
    // don't allow listing of undefined cols
    getContactColumn(col) {
      if (col.includes('.')) {
        let parts = col.split('.');
        const tmp = this.contact[parts[0]];
        return tmp ? tmp[parts[1]] : undefined;
      }
      if (this.contact && this.contact[col]) {
        // Hack to get formatting in here
        if (col == 'mobile_phone' || col == 'home_phone') {
          return this.formatPhoneNumber(this.contact[col]);
        }
        return this.contact[col];
      }
      return '';
    },
  },
};
</script>
