<template>
  <div>
    <el-row style="width: 300px" justify="space-around" align="middle">
      <el-col :span="12" align="middle">
        <el-avatar :size="60" :src="getProfilePhoto" fit="cover"></el-avatar>
      </el-col>
      <el-col :span="12">
        <el-row>
          <el-col>{{ getLoginAccountName }}</el-col>
        </el-row>
        <el-row>
          <el-col>
            <!-- TODO: Check for acl -->
            <agent-status-icon></agent-status-icon>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col :span="24">
        <el-button
          v-if="!isLoggedIn"
          size="large"
          style="width: 282px; margin:8px;"
          @click="$emit('agent-login', {})"
        >
          Agent Login
        </el-button>
        <el-button
          v-if="isLoggedIn"
          size="large"
          style="width: 282px; margin:8px;"
          @click="logout()"
        >
          Agent Logout
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AgentStatusIcon from 'src/components/UIComponents/AgentStatusIcon';

export default {
  props: {},
  components: {
    AgentStatusIcon,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  created() {},
  computed: {
    isLoggedIn() {
      return this.getMyAgentStatus(this.getLoginAccount.userId);
    },
    ...mapGetters('realtime', ['getMyAgentStatus']),
    ...mapGetters('auth', [
      'getProfilePhoto',
      'getLoginAccount',
      'getLoginAccountName',
    ]),
  },
  watch: {},
  methods: {
    async logout() {
      // status are user controlled, while state is controlled on the backend.
      // the three states we use currently: active, busy, away
      //
      // These states are modeled on the backend.
      //

      if (this.getMyAgentStatus(this.getLoginAccount.userId).state === 'busy') {
        // message
        this.$message({
          message: `Unable to logout because your busy in [${
            this.getMyAgentStatus(this.getLoginAccount.userId).status
          }]`,
          type: 'error',
          showClose: true,
        });
        return;
      }
      this.$emit('agent-logout');
    },
  },
};
</script>
