<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page section-image">
        <el-row justify="center" align="middle" class="login-frame">
          <el-col :md="8" :xs="24">
            <el-form
              @submit.prevent="loginUser"
              :label-position="'left'"
              :class="shakeClass"
            >
              <el-card class="login-card">
                <div class="logo">
                  <img
                    src="~img/logo/ss-logo-8-12-2021.svg"
                    alt="SendSquared"
                  />
                </div>

                <!-- Updated error message, closable -->
                <el-row v-if="form.status">
                  <el-col :span="24" align="center">
                    <el-tag
                      closable
                      @close="form.status = ''"
                      type="danger"
                      style="margin-bottom: 10px"
                      >{{ form.status }}</el-tag
                    >
                  </el-col>
                </el-row>

                <!-- form labels give us consistent spacing -->
                <el-form-item label="">
                  <el-input
                    v-model="form.username"
                    placeholder="Enter your email address"
                    autocomplete="username"
                    size="large"
                    class="login-form-input"
                  ></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input
                    v-model="form.password"
                    placeholder="Password"
                    autocomplete="current-password"
                    size="large"
                    :type="formElements.passwordType"
                    :show-password="formElements.passwordType === 'password'"
                    @click:append="changePasswordType"
                    class="login-form-input"
                  ></el-input
                ></el-form-item>

                <el-row>
                  <el-col :span="12">
                    <router-link
                      to="/forgotten"
                      style="color: rgb(250, 250, 250)"
                      >Forgot Password?</router-link
                    >
                  </el-col>
                  <el-col :span="12" align="right">
                    <el-link
                      href="https://sendsquared.com"
                      style="color: rgb(250, 250, 250)"
                      >Website</el-link
                    >
                  </el-col>
                </el-row>

                <el-divider></el-divider>

                <el-row>
                  <el-col :span="24" align="center">
                    <el-button
                      type="primary"
                      size="large"
                      native-type="submit"
                      :disabled="loggingIn"
                    >
                      {{ loggingIn ? 'Please wait' : 'Login' }}
                    </el-button>
                  </el-col></el-row
                >
              </el-card>
            </el-form>
          </el-col>
        </el-row>
        <app-footer></app-footer>
        <div
          class="full-page-background color-yellow"
          :style="getBackground"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

import AppFooter from './Layout/AppFooter';
import ConfigService from '/src/services/configService';

export default {
  computed: {
    ...mapState({
      account: (state) => state.auth.authAccount,
    }),
    getBackground() {
      const number = Math.floor(Math.random() * 4 + 1);
      return `background-image: url('https://static.sendsquared.com/images/oct-2021-background-${number}.jpg');`;
    },
  },
  components: {
    AppFooter,
  },
  methods: {
    ...mapActions('auth', ['processLogin']),

    async loginUser() {
      this.loadingScreen(true);
      try {
        await this.processLogin(this.form);
        await ConfigService.loadConfig();
        this.removeLoginClass();
        await this.$router.push('/admin/overview');
      } catch (e) {
        if (e.response) {
          // TODO: we should be passing more specific error messages from the API
          const status = {
            422: 'Invalid input. Confirm this is a valid email address',
            403: 'username/password do not match',
            401: 'Username/password are not valid',
          };
          this.form.status =
            status[e.response.status] ||
            e.response.data?.error ||
            'Unexpected Authorization Error. Please try back later';
          console.error(e.response);
        } else {
          this.form.status =
            'Unexpected System Error logging in. Please try again later';
          console.error(e);
        }
        this.shakeForm();
      } finally {
        this.loadingScreen(false);
      }
    },
    changePasswordType() {
      if (this.formElements.passwordType == 'password') {
        this.formElements.passwordType = 'text';
        this.formElements.passwordIcon = 'el-icon-lock';
      } else {
        this.formElements.passwordType = 'password';
        this.formElements.passwordIcon = 'el-icon-view';
      }
    },
    shakeForm() {
      this.shakeClass = 'ahashakeheartache';
      setTimeout(() => {
        this.shakeClass = '';
      }, 800);
    },
    loadingScreen(active) {
      if (active) {
        this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)',
        });
      } else if (this.loading) {
        this.loading.close();
      }
      this.loggingIn = active;
    },
    addLoginClass() {
      document.body.classList.add('drop-margin');
    },
    removeLoginClass() {
      document.body.classList.remove('drop-margin');
    },
  },
  created() {
    this.addLoginClass();
  },
  data() {
    return {
      shakeClass: '',
      form: {
        username: '',
        password: '',
        status: '',
      },
      formElements: {
        passwordType: 'password',
        passwordIcon: 'el-icon-view',
      },
      loading: undefined,
      loggingIn: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.logo {
  text-align: center;

  img {
    // width: 75%;
    margin: 0 auto;
    margin-bottom: 2em;
  }
}

.full-page-background {
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.login-frame {
  z-index: 4;
  height: 90vh;
}

.login-card {
  background-color: rgba(37, 36, 34, 0.85);
  border-color: rgba(37, 36, 34, 0.95);
}

.logo img {
  width: 230px;
  margin-top: 1em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.status {
  color: red;
  text-align: center;
}
.el-input__inner {
  color: #222222;
}

.ahashakeheartache {
  animation: kf_shake 0.4s 1 linear;
}

@keyframes kf_shake {
  0% {
    transform: translate(30px);
  }

  20% {
    transform: translate(-30px);
  }

  40% {
    transform: translate(15px);
  }

  60% {
    transform: translate(-15px);
  }

  80% {
    transform: translate(8px);
  }

  100% {
    transform: translate(0px);
  }
}
</style>
