<template>
  <span>
    <!-- Editor Names -->
    <span
      v-if="
        editorMode &&
        getEditorType == 'email' &&
        getCurrentWorkflow &&
        getCurrentWorkflow.workflow === undefined
      "
      class="navbar-brand"
      >Email Editor</span
    >
    <!-- <a
      v-if="
        editorMode &&
        getEditorType == 'landing' &&
        getCurrentWorkflow &&
        getCurrentWorkflow.workflow === undefined
      "
      class="navbar-brand"
      href="#"
      >Landing Page Editor</a
    > -->
    <!-- <a v-if="editorMode && getEditorType == 'sms'" class="navbar-brand" href="#"
      >SMS Editor</a
    > -->
    <!-- <a
      v-if="editorMode && getEditorType == 'popup'"
      class="navbar-brand"
      href="#"
      >Popup Editor</a
    > -->
    <span v-if="automationMode" class="navbar-brand"> 
      <el-divider direction="vertical" />
      Automation Editor</span>

    <!-- non agency title bar -->
    <span v-if="!editorMode && !automationMode">
      <el-divider direction="vertical" />
      {{ $route.name }}
    </span>
  </span>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    editorMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    automationMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    getEditorType: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('workflows', ['getCurrentWorkflow']),
  },
};
</script>
