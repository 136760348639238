<template>
  <div>
    <el-table :data="activeAgents" size="small" :row-class-name="agentRowStatus">
      <el-table-column prop="user_id" label="Agent" width="180">
        <template #default="props">
          <agent-field-view
            v-if="props.row.user_id"
            :agent-id="props.row.user_id"
            :display="['first_name', 'last_name']"
          ></agent-field-view>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="State" width="180"></el-table-column>
      <el-table-column prop="status" label="Status"></el-table-column>
      <el-table-column
        prop="duration"
        label="Duration"
      ></el-table-column>
      <el-table-column
        prop="actions"
        label="Actions"
        align="right"
      >
        <template #default="props">
          <el-button-group size="small">
            <el-button
              v-if="getAcls('is_agent_admin') &&
              props.row.user_id != getLoginAccount.userId"
              size="small"
              @click="logoutUser(props.row.user_id)"
              :icon="Close"
              :type="'danger'"
            >
            </el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { Close } from '@element-plus/icons-vue';

import { processError } from 'src/util/processError.js';
import AgentFieldView from 'src/components/UIComponents/AgentFieldView';
import AgentService from 'src/services/agentService';

export default {
  name: 'AgentStatusList',
  components: {
    AgentFieldView,
    // Icon
    Close,
  },
  computed: {
    ...mapGetters('realtime', ['getActiveAgents']),
    ...mapGetters('auth', ['getAcls', 'getLoginAccount']),
  },
  props: {},
  data() {
    return {
      activeAgents: [],
      timer: undefined,
      // Icon
      Close,
    };
  },
  watch: {
    // Copy the store values in so we are not updating the store
    // each second and that will cause lots of UI refreshes.
    getActiveAgents: {
      immediate: true,
      deep: true,
      handler(newVals, oldvals) {
        this.activeAgents.splice(0);
        // We have to set all properties explicietly for the reactivity to flow
        // into el-table
        for (const a of newVals) {
          this.activeAgents.push({
            user_id: a.user_id,
            status: a.status,
            state: a.state,
            duration: 0,
            modified_at: a.modified_at
          });
        }
        // this.activeAgents = [...newVals];
        this.updateTime();
      },
    },
  },
  async created() {
    this.updateTime();
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    agentRowStatus({ row, rowIndex }) {
      switch (row.state) {
        case 'busy':
          return 'agent-busy';
        case 'active':
          return 'agent-active';
        case 'away':
          return 'agent-away';
      }
    },
    updateTime() {
      const now = new Date();
      this.activeAgents.forEach((a) => {
        const duration = Math.round(now - new Date(a.modified_at));
        a.duration = new Date(duration).toISOString().substr(11, 8);
      });
      this.activeAgents = this.activeAgents.slice();
    },
    async logoutUser(userId) {
      try {
        await AgentService.forceLogout(userId);
      }
      catch (e) {
        const details = processError(e.response);
        this.$message({
          type: 'error',
          message: 'Error saving lead: ' + details,
          showClose: true
        });
        return false;
      }
    },
  },
};
</script>

<style>
.status-frame {
  margin-top: 10px;
  margin-right: 15px;
}
.el-table .agent-active {
  background: rgb(218, 236, 218);
}
.el-table .agent-away {
  background: rgb(245, 245, 245);
}
.el-table .agent-busy {
  background: rgb(245, 232, 232);
}
</style>
