<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a
              :href="'https://sendsquared.com/about'"
              target="_blank"
              rel="noopener"
              >About SendSquared</a
            >
          </li>
          <li>
            <a
              :href="'https://sendsquared.com/legal/privacy-policy'"
              target="_blank"
              rel="noopener"
              >Privacy</a
            >
          </li>
          <li>
            <a
              :href="'https://sendsquared.com/legal/terms-of-use'"
              target="_blank"
              rel="noopener"
              >Terms</a
            >
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; Coded with
        <i class="fa fa-heart heart"></i> by
        <a :href="'https://sendsquared.com'" target="_blank" rel="noopener"
          >SendSquared</a
        >.
      </div>
    </div>
  </footer>
</template>
