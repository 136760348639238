<template>
  <div>
    <div class="block-click" @click="togglePicker()">
      <div :style="{ background: this.background }">
        <i></i>
      </div>
    </div>
    <div v-if="showPicker" class="color-picker-frame">
      <div class="color-picker" :style="this.getFramePosition">
        <!-- Frame to hold window -->
        <sketch-picker
          v-model="colors"
          :disable-alpha="true"
          :preset-colors="this.getColors"
        ></sketch-picker>
      </div>
    </div>
    <div v-if="showPicker" class="outside" v-on:click="closeWindow()"></div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
// import Sketch from 'vue-color/src/components/Sketch.vue';

import { Sketch } from '@ckpack/vue-color';

export default {
  name: 'ColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
  props: {
    value: {
      required: false,
      default: 0,
    },
    framePosition: {
      required: false,
      default: 'right',
    },
  },
  computed: {
    ...mapGetters('editor', ['getColors']),
    getFramePosition() {
      if (this.framePosition === 'left') {
        return { left: '0px' };
      }
      return { right: '10px' };
    },
  },
  data() {
    return {
      currentValue: this.value,
      showPicker: false,
      colors: { r: 255, g: 255, b: 255, a: 1 },
      background: '#ffffff',

      colorDebounce: undefined,
      disableColorSet: false,

      colorTimeout: undefined,
    };
  },
  methods: {
    ...mapActions('editor', ['addColor', 'clearColors']),
    togglePicker() {
      this.showPicker = !this.showPicker;
    },
    RGBToHex(r, g, b) {
      const hex = `#${r.toString(16).padStart(2, '0')}${g
        .toString(16)
        .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
      return hex;
    },
    hexToRGB(hex) {
      const [, r, g, b, a] =
        /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex) ||
        [];
      return {
        r: parseInt(r, 16),
        g: parseInt(g, 16),
        b: parseInt(b, 16),
        a: (parseInt(a, 16) ?? 255) / 0xff,
      };
    },
    closeWindow() {
      this.addColor(this.currentValue);
      this.showPicker = false;
    },
    setColor() {
      if (this.colorTimeout) clearTimeout(this.colorTimeout);

      this.colorTimeout = setTimeout(() => {
        if (this.value) {
          this.background = this.value;
          this.colors = Object.assign({}, { rgba: this.hexToRGB(this.value) });
        } else {
          this.background = '#ffffff';
          this.currentValue = '#ffffff';
          this.colors = { rgba: this.hexToRGB('#ffffffff') };
        }
        this.colorTimeout = null;
      }, 250);
    },
  },
  mounted() {
    this.setColor();
  },
  watch: {
    colors(newColor) {
      if (newColor.hex === undefined) return;
      if (new Date() - this.time < 900) return;
      if (this.throttleTimeout) return;

      this.disableColorSet = true;
      this.background = newColor.hex;
      this.currentValue = newColor.hex;

      this.throttleTimeout = setTimeout(() => {
        this.$emit('update:modelValue', newColor.hex8);
        this.$emit('change', newColor.hex8);
        this.disableColorSet = false;
        this.throttleTimeout = null;
      }, 300);

      // disable the color set.
      setTimeout(() => {
        this.disableColorSet = false;
      }, 3000);
    },
    value() {
      if (this.showPicker || this.disableColorSet) return;
      this.setColor();
    },
  },
};
</script>
<style lang="scss" scoped>
.block-click {
  display: inline-block;
  padding: 4px;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 3px;
  cursor: pointer;

  div {
    background: green;
    width: 18px;
    height: 18px;
  }
}

.color-picker-frame {
  position: relative;

  .color-picker {
    position: absolute;
    top: 0;
    z-index: 1801;
  }
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1800;
  top: 0;
  left: 0;
}
</style>
