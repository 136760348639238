<template>
  <el-dialog :title="associateTitle" v-model="isVisible">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Existing" name="existing">
        <el-form label-width="120px">
          <el-form-item :label="'C' + relationshipType.substring(1)">
            <el-autocomplete
              v-model="contact"
              :fetch-suggestions="findContact"
              :placeholder="'Search for C' + relationshipType.substring(1)"
              @select="handleSelect"
              style="width: 100%"
            >
              <template #default="{ item }">
                <div style="line-height: 16px; margin-bottom: 10px">
                  <template
                    v-if="item.displayName"
                  >
                    <span>{{ item.displayName }}</span>
                    <small v-if="item.email"><br />{{ item.email }}</small>
                    <small v-if="item.phone"><br />{{ item.phone }}</small>
                  </template>
                  <template
                    v-if="!item.displayName && item.name"
                  >
                    <span>{{ item.name }}</span>
                    <small v-if="item.email"><br />{{ item.email }}</small>
                    <small v-if="item.phone"><br />{{ item.phone }}</small>
                  </template>
                  <template
                    v-if="!item.displayName && !item.name"
                  >
                    {{ item.email }}
                  </template>
                </div>
              </template>
            </el-autocomplete>
            <!--  -->
          </el-form-item>
          <el-form-item label="Type">
            <el-select
              v-model="contactType"
              placeholder="Contact Type"
              size="small"
            >
              <el-option label="Primary" value="primary"></el-option>
              <el-option label="Decision" value="decision"></el-option>
              <el-option label="Contact" value="contact"></el-option>
            </el-select>
          </el-form-item>
        </el-form> </el-tab-pane
      ><el-tab-pane label="New" name="new">
        <el-form label-width="120px" size="small">
          <el-form-item
            v-if="relationshipType === 'company'"
            label="Display Name"
          >
            <el-input v-model="newContact.display_name"></el-input>
          </el-form-item>
          <el-form-item
            v-if="relationshipType === 'contact'"
            label="First Name"
          >
            <el-input v-model="newContact.first_name"></el-input>
          </el-form-item>
          <el-form-item v-if="relationshipType === 'contact'" label="Last Name">
            <el-input v-model="newContact.last_name"></el-input>
          </el-form-item>
          <el-form-item label="Email">
            <el-input v-model="newContact.email" type="email"></el-input>
          </el-form-item>
          <el-form-item label="Mobile Phone">
            <el-input v-model="newContact.mobile_phone"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isVisible = false">Cancel</el-button>
      <el-button type="primary" @click="postChanges()">
        {{ edit ? 'Update' : 'Save' }}
        Association
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import searchServices from 'src/services/searchServices';
import contactServices from 'src/services/contactServices';

export default {
  props: {
    contactIdFrom: {
      type: Number,
      required: true,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    relationshipType: {
      type: String,
      default: () => {
        return 'contact';
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'existing',
      associateTitle: 'New Contact Association',
      isVisible: false,
      contactIdTo: undefined,
      relateId: undefined,
      contact: undefined,
      contactType: undefined,
      // New Contact
      newContact: {
        first_name: '',
        last_name: '',
        email: '',
        mobile_phone: '',
      },
    };
  },
  watch: {
    showDialog() {
      this.associateTitle =
        'New C' + this.relationshipType.substring(1) + ' Association';
      this.isVisible = !this.isVisible;
      this.contactIdTo = undefined;
      this.contact = undefined;
      this.contactType = undefined;
    },
  },
  methods: {
    async findContact(query, callback) {
      const search = await searchServices.searchByContactNameEmail(
        query,
        0,
        false
      );
      const r = [];
      for (const contact of search.data.contacts) {
        let name = contact.email;

        // Check to see if display name exists
        if (contact.display_name) {
          name = contact.display_name;
        }
        if (contact.first_name) {
          name = contact.first_name + ' ' + contact.last_name;
        }

        r.push({
          value: name,
          name: name,
          displayName: contact.display_name,
          email: contact.email,
          phone: contact.mobile_phone,
          id: contact.id,
          contactType: contact.contact_type,
        });
      }
      callback(r);
    },

    // Handle Select
    handleSelect(item) {
      this.contactIdTo = item.id;
    },
    async postChanges() {
      switch (this.activeTab) {
        case 'new':
          // Attempt to create new ID.
          try {
            const contact = await contactServices.newContact(this.newContact);
            this.$emit('set-association', {
              contactId: contact.data.id,
              contactType: this.contactType,
            });
          } catch (e) {
            this.$message.error(e.response.data.message);
          }
          break;
        case 'existing':
          if (!this.contactIdTo) {
            throw 'no contact id set';
          }
          if (!this.contactIdFrom) {
            throw 'no contact id set';
          }
          if (!this.contactType) {
            throw 'no contact type set';
          }
          try {
            const relate = await contactServices.relateContact(this.contactIdFrom, this.contactIdTo, this.contactType);
            this.$emit('set-association', {
              contactIdFrom: this.contactIdFrom,
              contactId: this.contactIdTo,
              contactType: this.contactType,
            });
          } catch (e) {
            console.error(e);
            this.$message.error(e.response.data.message);
            this.$emit('set-association', undefined);
          }
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.el-autocomplete-suggestion {
  width: 100%;
}
</style>
