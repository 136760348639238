<template>
  <div>
    <div v-if="!isAgentLoggedIn" class="d-flex align-items-center">
      Logged Out
    </div>
    <div v-if="isAgentLoggedIn" class="d-flex align-items-center">
      <span class="mr-3">{{ status.icon }}</span>
      {{ status.name }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AgentStatusIcon',
  computed: {
    ...mapGetters('realtime', ['getMyAgentStatus']),
    ...mapGetters('auth', ['getLoginAccount']),
    ...mapGetters('agents', ['getAgentStatuses']),
    isAgentLoggedIn() {
      return !!this.getMyAgentStatus(this.getLoginAccount.userId);
    },
    status() {
      const status = this.getAgentStatuses.find(s => s.status ==
        this.getMyAgentStatus(this.getLoginAccount.userId).status);
      if (status) {
        return status;
      }
      return {icon: '', name: 'Pending'};
    }
  },
};
</script>
