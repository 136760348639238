import { authedAxios } from '../shared/axiosWrapper';
import {
  AD_BASE_PHONE_NUMBER_PATH,
} from '../constants';

export default {
  fetchPhoneNumbers() {
    // Just fetch them all for now. Paginate eventually
    return authedAxios.get(AD_BASE_PHONE_NUMBER_PATH);
  },
  changeLabel(id, label) {
    return authedAxios.put(AD_BASE_PHONE_NUMBER_PATH + id + '/change-label',
      {label: label});
  },
  changeCallPlan(id, callPlanId) {
    return authedAxios.put(AD_BASE_PHONE_NUMBER_PATH + id + '/change-callplan',
      {call_plan_id: callPlanId});
  },
}

