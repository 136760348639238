<template>
  <router-view v-slot="{ Component }">
    <transition type="slide">
      <div v-if="loading" class="loading-window">
        <p v-if="noQuote === false" class="phrase">{{ getPhrase.statement }}</p>
        <p v-if="noQuote === false" class="author">{{ getPhrase.author }}</p>
        <font-awesome-icon icon="spinner" />
      </div>
    </transition>
  </router-view>
</template>
<script>
import loadingQuotes from 'src/assets/data/loading-quotes.json';

export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    noQuote: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getPhrase() {
      const index = Math.floor(Math.random() * this.phrase.length);
      return this.phrase[index];
    },
  },
  data() {
    return {
      phrase: [...loadingQuotes],
    };
  },
};
</script>
<style scoped>
.loading-window {
  margin-top: 4em;
  text-align: center;
}
.phrase {
  color: rgb(180, 180, 180);
}
.author {
  color: rgb(197, 197, 197);
}
.spinner {
  color: rgb(200, 200, 200);
}
</style>
