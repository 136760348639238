<template>
  <span class="call-control-container">
    <span class="call-control">
      <el-row>
        <el-col :span="24">
          <input
            v-model="digits"
            pattern="[\d+]"
            style="width: 100%"
            @input="updateDigits"
            @keyup.enter="keyUp"
        /></el-col>
      </el-row>
      <el-row
        v-for="(numberRow, index) in numbers"
        :key="index + '-numrow'"
        style="margin-top: 5px"
      >
        <el-col
          :span="8"
          v-for="(num, numberIndex) in numberRow"
          :key="num"
          :class="getNumberClass(numberIndex)"
        >
          <el-button @click="dial(num)">{{ num }}</el-button>
          <!-- size="small" -->
        </el-col>
      </el-row>

      <el-row style="margin-top: 10px">
        <caller-id-select v-if="requireCallerId" @change="setCallerId"
         style="z-index: 1010;">
        </caller-id-select>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="12" align="center">
          <el-button
            type="primary"
            @click="accept"
            :disabled="!showAccept"
            size="large"
            class=""
          >
            <div class="dial-button">
              <font-awesome-icon icon="phone" />
              <span>{{ answerText }}</span>
            </div>
          </el-button>
        </el-col>
        <el-col :span="12" align="center">
          <el-button
            type="danger"
            @click="reject"
            :disabled="!showReject"
            size="large"
          >
            <div class="dial-button">
              <font-awesome-icon icon="phone-slash" />
              <span>{{ hangupText }}</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
    </span></span
  >
</template>

<script>
import { mapGetters } from 'vuex';
import VoiceService from 'src/services/voiceServices';
import CallerIdSelect from 'src/components/UIComponents/CallerIdSelect';

export default {
  name: 'CallControl',
  computed: {
    ...mapGetters('phoneNumbers', ['getCallerIds']),
  },
  components: {
    CallerIdSelect,
  },
  props: {
    // activeCall: {}, // accepted call that can be controlled, the twilio Call object
    agentDevice: undefined, // Agent softphone model
    // incomingCall: {}, // Call that has not been accepted
    // device: {},
    callId: Number,
  },
  data() {
    return {
      requireCallerId: false,
      digits: '',
      answerText: 'Call',
      hangupText: 'End',
      showAccept: true,
      showReject: true,
      numbers: [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        ['*', 0, '#'],
      ],
    };
  },
  mounted() {
    this.requireCallerId = this.getCallerIds.length > 1;
  },
  watch: {
    // Don't use arrow, need correct binding
    incomingCall: function (call) {
      // Active takes precedence
      this.showAccept = true;
      this.showReject = true;
      this.setTextState();
    },
    agentDevice: function (device) {
      // TODO: we need to watch the sub property now.
      console.log('we have a device change', device);
      this.setTextState();
    },
  },
  methods: {
    setCallerId(callerId) {
      this.callerId = callerId;
    },
    keyUp(key) {
      // TODO: This does not seem to fire for the enter key
      if (key.keyCode === 13) {
        this.accept();
      }
    },
    updateDigits() {
      this.digits = this.digits.replace(/[^0-9]*/g, '');
      this.setTextState();
    },
    dial(digit) {
      if (digit == undefined) {
        return;
      }
      // guard condition for dialing while an incoming call happens
      if (this.incomingCall) {
        return;
      }

      if (this.agentDevice && this.agentDevice.activeCall) {
        if (this.agentDevice.provider == 'twilio') {
          this.agentDevice.activeCall.sendDigits(digit.toString());
        }
        if (this.agentDevice.provider == 'bandwidth') {
          this.agentDevice.device.sendDtmf(digit.toString());
        }
      } else {
        this.digits += digit;
      }
      this.setTextState();
    },
    reject() {
      if (this.agentDevice && this.agentDevice.provider == 'twilio') {
        if (this.incomingCall) {
          this.incomingCall.reject();
        }
      }
      if (this.agentDevice && this.agentDevice.activeCall) {
        if (this.agentDevice.provider == 'twilio') {
          this.agentDevice.activeCall.disconnect();
        }
      }
      this.digits = '';
      this.setTextState();
    },
    async accept() {
      // Special case for using this control in transfer
      // We should move the call control and the dial into different components
      if (this.callId) {
        this.$emit('dial', this.digits);
        this.$emit('accept');
        return false;
      }

      // Pickup a live call
      if (this.incomingCall) {
        this.incomingCall.accept();
      }
      // send digits if on a live call, softphone only
      else if (this.agentDevice && this.activeCall) {
        if (this.agentDevice.provider == 'twilio') {
          this.agentDevice.activeCall.sendDigits(this.digits.toString());
        }
        if (this.agentDevice.provider == 'bandwidth') {
          this.agentDevice.sendDtmp(this.digits.toString());
        }
      } else {
        // Otherwise dial a number using the BE.
        if (this.requireCallerId && !this.callerId) {
          this.$message({
            message: 'Error Starting Call: Caller Id Number required',
            type: 'error',
            showClose: true,
          });
          return false;
        }
        let result;
        try {
          result = await VoiceService.dialNumber(this.digits, this.callerId);
        } catch (err) {
          if (err.response) {
            this.$message({
              message: 'Error Starting Call: ' + err.response.data,
              type: 'error',
              showClose: true,
            });
          } else {
            // Unknown errors IE network errors or such
            this.$message({
              message: 'Error Starting Call: ',
              err,
              type: 'error',
              showClose: true,
            });
          }
          console.log(err);
          return;
        }
      }

      this.digits = '';
      this.setTextState();
      this.$emit('accept');
    },
    setTextState() {
      // Active always takes precedence
      if (this.agentDevice && this.agentDevice.activeCall) {
        this.answerText = 'Call';
        this.hangupText = 'End';
        return;
      }
      if (this.incomingCall) {
        this.answerText = 'Accept';
        this.hangupText = 'Reject';
        return;
      }
      if (this.digits) {
        this.hangupText = 'Clear';
        return;
      }
      this.answerText = 'Call';
      this.hangupText = 'End';
    },

    getNumberClass(index) {
      switch (index) {
        case 0:
          return 'text-right';
        case 1:
          return 'text-center';
        case 2:
          return 'text-left';
      }
    },
  },
};
</script>
<style lang="scss">
.call-control-container {
  position: relative;
  .call-control {
    z-index: 1000;
    position: absolute;
    top: 55px;
    left: -105px;
    /*height: 55px;*/
    min-width: 162px;
    justify-content: left;
    align-items: center;
    background-color: #ddd;
    padding: 1em;

    border: 2px #3333333a solid;
    border-radius: 2px;

    box-shadow: 5px 5px 0px 0px #00201d3a, 10px 10px 0px 0px #0037313a;

    /* box-shadow: 5px 5px 0px 0px #00201d3a, 10px 10px 0px 0px #0037313a,
      15px 15px 0px 0px #0b50493a, 20px 20px 0px 0px #1f68603a,
      25px 25px 0px 0px #3b827a3a, 5px 5px 15px 5px rgba(0, 0, 0, 0); */

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ddd;
      z-index: 5001;
    }
    &::after {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 14px;
      height: 14px;
      background-color: #ddd;
      border: 2px solid rgba(51, 51, 51, 0.227);
      border-bottom: none;
      border-right: none;
      z-index: 0;
    }
  }
  .number-row {
    height: 35px;
    border: 1px solid #999;
    padding: 0.3em;
    margin: 0.3em;
  }
  .dial-button {
    // height: 90px;
    margin-top: 5px;
    margin-bottom: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      display: block;
    }
  }
}
</style>
