const OwnerDash = () =>
  import(
    /* webpackChunkName: "Owners" */ 'src/components/Dashboard/Views/Owners/OwnersDashboard.vue'
  );

export const ownersRoutes = [
  {
    path: 'owners/dashboard',
    name: 'Owner Scouting Dashboard',
    component: OwnerDash,
  },
];
