<template>
  <div>
    <el-select
      v-if="editable"
      v-model="selectedId"
      @change="do_change"
      :placeholder="placeholder"
      :size="size"
    >
      <el-option
        v-for="option in getLeadSources"
        :value="option.id"
        :key="option.id"
        :label="option.label"
      >
      </el-option>
    </el-select>
    <span v-if="!editable">
      {{ getSelectedLabel() }}
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'leadSourceSelect',
  computed: {
    ...mapGetters('leadSource', ['getLeadSources']),
  },
  props: {
    value: {
      type: [Number, String],
    },
    initialId: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      default: () => {
        return 'small';
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return 'Select a Lead Source';
      },
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedId: undefined,
    };
  },
  watch: {
    initialId(id) {
      this.selectedId = id;
    },
    value(n) {
      this.selectedId = parseInt(n);
    },
  },
  async created() {
    if (this.initialId) {
      this.selectedId = this.initialId;
    }
    if (this.value != undefined) {
      this.selectedId = parseInt(this.value);
    }
  },
  methods: {
    do_change() {
      this.$emit(
        'update:leadSource',
        this.getLeadSources.find((s) => s.id == this.selectedId)
      );
      this.$emit('update:modelValue', this.selectedId);
      this.$emit('change', this.selectedId);
    },
    getSelectedLabel() {
      const ls = this.getLeadSources.find((s) => s.id == this.selectedId);
      return ls ? ls.label : 'None';
    },
  },
};
</script>
