
/* Process the various errors from our BE. If it comes from
 * TSOA validation it can be an object of details.
 * If it comes from  TSOA error return details will be a string
 * If it comes from our error classes data will be a string
 * If it comes from raw error it will be a string only
 */
export function processError(errRes) {
  let details;
  if (errRes && errRes.data) {
    if (errRes.data.details) {
      if (typeof errRes.data.details != 'string') {
        details = '';
        for (const [k, v] of Object.entries(errRes.data.details)) {
          details += "'" + k + "':" + v.message + ', ';
        }
      }
      if (typeof errRes.data.details == 'string') {
        details = errRes.data.details;
      }
    }
    else if (errRes.data.message) {
      // Sometimes we dont have details and only a message
      details = errRes.data.message;
    }
    // Not a tsoa validation error just our internal error
    // with just string message but make sure its set
    else if (errRes.data) {
      details = errRes.data;
    }
    else {
      // Not sure this can happen
      console.log('unintended error case', errRes);
      details = errRes;
    }
  }
  // No data is other network or such errors
  // Just use raw string message
  else if (errRes && !errRes.data) {
    details = errRes
  }
  else {
    // not sure what this would be
    console.log('Unexpected Error type', errRes);
    details = 'Unexpected Error';
  }
  return details;
}

