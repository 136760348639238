import Sidebar from './SideBar.vue';
import SidebarItem from './SidebarItem.vue';

const SidebarStore = {
  hideSideBar: false,
  showSidebar: false,
  sidebarLinks: [],
  isMinimized: true,
  sidebarHidden: false,
  displaySidebar(value) {
    this.showSidebar = value;
  },
  toggleMinimize(direction = null) {
    document.body.classList.toggle('sidebar-mini');
    // we simulate the window Resize so the charts will get updated in real time.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
    if (!direction) {
      this.isMinimized = !this.isMinimized;
    }
    // this.isMinimized = direction ? true : false;
  },
  hideSideBar(hide) {
    if (hide && this.sidebarHidden === false) {
      this.sidebarHidden = true;
      document.body.classList.toggle('hide-sidebar');
      return;
    }
    if (!hide && this.sidebarHidden === true) {
      this.sidebarHidden = false;
      document.body.classList.remove('hide-sidebar');
    }
  }
};

const SidebarPlugin = {
  install(app, options) {
    if (options && options.sidebarLinks) {
      SidebarStore.sidebarLinks = options.sidebarLinks;
    }

    // Vue.provide('$sidebar', SidebarStore);
    app.config.globalProperties.$sidebar = SidebarStore;

    app.mixin({
      data() {
        return {
          sidebarStore: SidebarStore
        };
      }
    });
    /*
    Vue.prototype.$sidebar = SidebarStore;
    Object.defineProperty(Vue.prototype, '$sidebar', {
      get() {
        return this.$root.sidebarStore;
      }
    });
    */
    app.component('side-bar', Sidebar);
    app.component('sidebar-item', SidebarItem);
  }
};

export default SidebarPlugin;
