<template>
  <div>
    <!-- to allow for debuging in the live system -->
    <div v-if="callDebug">
      <!-- updating to debug -->
      <agent-status-list></agent-status-list>
      <br />
      All the calls <br />
      <call-status-list></call-status-list>
      My dispositions: {{ getMyDispositions(userId) }}
      <br />
      All the segments<br />
      <segment-status-list></segment-status-list>
    </div>

    <!-- 
      Updating the navigation bar to use element-plus 
      dropping bootstrap elements for navigation
     -->
    <el-container class="navbar-container">
      <el-header>
        <el-row
          justify="space-between"
          class="header-row-alignment"
          align="middle"
        >
          <!-- Logo / Brand Name / Call bar -->
          <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="12">
            <div
              class="navbar-brand"
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;
              "
            >
              <!-- START BUTTONS Emulation / Automation / Editor -->
              <div
                v-if="!editorMode && !automationMode && getEmulateMode"
                class="navbar-minimize"
              >
                <button
                  id="exitEmulation"
                  class="base-btn btn-icon btn-purple"
                  @click="exitEmulation()"
                >
                  <font-awesome-icon :icon="['fas', 'times']" />
                </button>
              </div>
              <!-- Editor Close Button -->
              <div
                v-if="
                  editorMode &&
                  (getCurrentWorkflow === undefined ||
                    getCurrentWorkflow.active === undefined ||
                    getCurrentWorkflow.active === false)
                "
                class="navbar-minimize"
              >
                <button
                  id="closeEditor"
                  class="base-btn btn-icon"
                  @click="closeEditor"
                >
                  <font-awesome-icon :icon="['fas', 'times']" />
                </button>
              </div>
              <!-- Automation Close Buttons -->
              <div v-if="automationMode" class="navbar-minimize">
                <button
                  id="closeAutomation"
                  class="base-btn btn-icon"
                  @click="closeAutomation"
                >
                  <font-awesome-icon :icon="['fas', 'times']" />
                </button>
              </div>
              <!-- END BUTTONS Emulation / Automation / Editor -->

              <!-- START of second swatch of information [Company name/Agency list] -->
              <span v-if="!getMyAnsweredSegments(userId).length">
                <el-dropdown
                  v-if="getAuthCompanies && getAuthCompanies?.length > 1"
                  @command="handleAgencySelection"
                  class="navbar-brand"
                  placement="bottom-start"
                >
                  <span class="el-dropdown-link">
                    {{ getCompanyName }}
                    <font-awesome-icon icon="arrow-down" />
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="account in getAuthCompanies"
                        v-bind:key="account.id"
                        :command="account.id"
                        >{{ account.name }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <span
                  v-if="getAuthCompanies && getAuthCompanies.length == 1"
                  class="navbar-brand"
                >
                  <el-text class="mx-1" size="large">{{
                    getCompanyName
                  }}</el-text>
                </span>

                <span v-if="isAgencyMode">
                  {{ $route.name }}
                </span>
              </span>

              <!-- TopBar Names -->
              <top-nav-bar-names
                v-if="!getMyAnsweredSegments(userId).length"
                :editor-mode="editorMode"
                :automation-mode="automationMode"
                :get-editor-type="getEditorType"
              ></top-nav-bar-names>
              <!-- Looks, like this will tile over and over again;
              perhaps the best approach is to have a drop down list...
              showing parked calls.  -->
              <span
                v-if="
                  primaryWebsocket && getMyAnsweredSegments(userId).length == 1
                "
              >
                <active-call
                  v-for="seg in getMyAnsweredSegments(userId)"
                  :key="seg.segment_id"
                  :call-id="seg.call_id"
                  :contact-id="getCallContact(seg.call_id)"
                  :caller-name="getCallName(seg.call_id)"
                  :phone-number="getCallNumber(seg.call_id)"
                  :agent-device="agentDevice"
                ></active-call>
              </span>
              <!-- END of second swatch of information -->

              <!-- Lets break this up a hint, just as before -->
              <el-divider direction="vertical"></el-divider>

              <!-- wrap up selector -->
              <active-wrap-ups
                v-if="getMyDispositions(userId).length"
              ></active-wrap-ups>

              <!-- lets drop a divider if we have wrapsups and brands -->
              <el-divider
                v-if="
                  getMyDispositions(userId).length &&
                  getBrands &&
                  getBrands.length > 0
                "
                direction="vertical"
              />

              <!-- Brand selector -->
              <global-brand-select
                v-if="
                  getBrands &&
                  getBrands.length > 0 &&
                  getMyAnsweredSegments(userId).length == 0
                "
                :style="'width:140px;'"
                :size="'small'"
              />
            </div>
          </el-col>

          <!-- Right Aligned components (email/automation/icons ) -->
          <!--  -->
          <el-col
            v-if="editorMode"
            :xs="10"
            :sm="10"
            :md="10"
            :lg="10"
            :xl="12"
            align="right"
          >
            <!--  #navbar-menu -->

            <!-- Revision Control -->
            <!-- FIXME: Move to component -->
            <editor-preview
              v-if="$route.name == 'Email Editor'"
            ></editor-preview>
            <el-divider
              v-if="$route.name == 'Email Editor'"
              direction="vertical"
            ></el-divider>
            <el-button-group v-if="$route.name == 'Email Editor'">
              <button id="undoButton" class="el-button el-button--small">
                <font-awesome-icon icon="undo"></font-awesome-icon>
              </button>
              <button id="codeEditor" class="el-button el-button--small">
                <font-awesome-icon icon="code"></font-awesome-icon>
              </button>
              <button id="redoButton" class="el-button el-button--small">
                <font-awesome-icon icon="redo"></font-awesome-icon>
              </button>
            </el-button-group>

            <!-- Test Email -->
            <!-- :disabled="editorName !== undefined && editorName.length === 0" -->
            <el-button
              v-if="
                isSaved &&
                getEditorType === 'email' &&
                $route.name != 'Email Editor'
              "
              size="small"
              @click="showSendDialog++"
              :icon="Promotion"
              >Send Test</el-button
            >

            <span
              v-if="
                (getEditorType === 'email' || getEditorType === 'sms') &&
                getCurrentCampaign.active !== true &&
                getCurrentWorkflow.active !== true &&
                isSaved
              "
              style="margin-top: 8px; margin-right: 5px"
            >
              <el-divider direction="vertical"></el-divider>
            </span>
            <span
              v-if="
                getCurrentCampaign.active === true ||
                getCurrentWorkflow.active === true
              "
              style="margin-top: 8px; margin-left: 4px"
            >
              <el-divider direction="vertical"></el-divider>
            </span>
            <el-form
              v-if="
                $route.name != 'Email Editor' &&
                getCurrentCampaign.active !== true &&
                getCurrentWorkflow.active !== true
              "
              :inline="true"
              style="display: inline-block"
            >
              <el-input
                type="text"
                size="small"
                v-model="editorName"
                :placeholder="getPlaceHolderName"
              ></el-input>
            </el-form>
            <span
              v-if="
                $route.name != 'Email Editor' &&
                getCurrentCampaign.active !== true &&
                getCurrentWorkflow.active !== true
              "
            >
              <span style="margin-left: 3px">
                <el-dropdown
                  size="small"
                  type="primary"
                  :disabled="
                    editorName !== undefined && editorName.length === 0
                  "
                  split-button
                  @click="saveTemplateButton"
                  @command="saveTemplateButton"
                >
                  Save
                  <template #dropdown>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="new"
                        >Save as New
                      </el-dropdown-item>
                      <el-dropdown-item command="send-now"
                        >Send Now</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </span>
            </span>
            <span
              v-if="
                $route.name != 'Email Editor' &&
                (getCurrentCampaign.active === true ||
                  getCurrentWorkflow.active === true)
              "
            >
              <span style="margin-left: 3px">
                <el-button
                  size="small"
                  type="primary"
                  @click="sendTemplateButton"
                  >Next</el-button
                >
              </span>
            </span>
            <!-- ENDOF: Old Email/Popup Editor Save Button -->
          </el-col>

          <!-- automation edit -->
          <el-col
            v-else-if="automationMode"
            :xs="10"
            :sm="10"
            :md="10"
            :lg="10"
            :xl="12"
            align="right"
          >
          </el-col>
          <!-- Below is the icons for realtime comms -->
          <el-col
            v-else
            :xs="10"
            :sm="10"
            :md="10"
            :lg="10"
            :xl="12"
            align="right"
          >
            <span v-if="getMyAgentStatus(userId)">
              <el-divider direction="vertical"></el-divider>
            </span>
            <font-awesome-icon
              v-if="getHaveNewSms"
              icon="comment"
              size="2x"
              class="notificationBadgeGreen"
              @click="goToInbox()"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="getHaveNewMessage"
              icon="envelope"
              size="2x"
              class="notificationBadgeGreen"
              @click="goToInbox()"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="getHaveNewVoicemail"
              icon="voicemail"
              size="2x"
              class="notificationBadgeGreen"
              @click="goToVoicemail()"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="getHaveNewLead"
              icon="briefcase"
              size="2x"
              class="notificationBadgeGreen"
              @click="goToLead()"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="getHaveNewTask"
              icon="list-alt"
              size="2x"
              class="notificationBadgeGreen"
              @click="goToTask(userId)"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="getHaveNewWhatsapp"
              :icon="['fab', 'whatsapp']"
              size="2x"
              class="notificationBadgeGreen"
              @click="goToInbox()"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="getMyAgentStatus(userId)"
              icon="phone"
              size="2x"
              :class="{
                'phone-icon': primaryWebsocket,
                'phone-icon-disabled': !primaryWebsocket,
              }"
              @click="
                primaryWebsocket
                  ? (showCallControl = !showCallControl)
                  : (showCallControl = false)
              "
              :disabled="!primaryWebsocket"
            ></font-awesome-icon>
            <call-control
              v-if="showCallControl && getMyAgentStatus(userId)"
              v-on:accept="showCallControl = !showCallControl"
              :agent-device="agentDevice"
            ></call-control>
            <font-awesome-icon
              icon="user-plus"
              size="2x"
              class="spotlight-search-icon"
              @click="$router.push('/admin/contacts/add')"
            ></font-awesome-icon>
            <!-- Search Window -->
            <font-awesome-icon
              icon="search"
              size="2x"
              class="spotlight-search-icon"
              @click="toggleSearch = !toggleSearch"
            ></font-awesome-icon>
            <span style="margin-right: 5px">
              <el-divider direction="vertical"></el-divider>
            </span>
            <s2-spotlight :toggle-open="toggleSearch"></s2-spotlight>
            <!-- Agent Status -->
            <agent-status
              v-if="getMyAgentStatus(userId)"
              :user-id="userId"
            ></agent-status>
            <agent-destination
              v-if="
                getMyAgentStatus(userId) &&
                getDialDestinations &&
                getDialDestinations.length
              "
              @newToken="authDevice"
            ></agent-destination>
            <!-- Profile Display. -->
            <profile-drop-down
              @agent-login="agentLogin"
              :agent-logout-function="agentLogout"
              :agent-status="getMyAgentStatus(userId)"
              :ws-connected="!!websocket"
            ></profile-drop-down>
            <!-- </el-row> -->
          </el-col>
        </el-row>
      </el-header>
    </el-container>

    <!-- Incoming call dialogs & popovers -->
    <span v-if="primaryWebsocket && getMyUnansweredSegments(userId).length">
      <incoming-call
        v-for="seg in getMyUnansweredSegments(userId)"
        :key="seg.segment_id"
        :play-ring="
          agentDevice != undefined && agentDevice.provider == 'bandwidth'
        "
        :allow-answer="!!agentDevice"
        :call-data="getCallData(seg.call_id)"
        :callId="seg.call_id"
        :queueId="getCallById(seg.call_id)?.queue_id"
        :auto-reject="
          agentDevice != undefined && agentDevice.provider == 'bandwidth'
        "
        @answer="agentAnswer"
        @reject="agentReject"
      ></incoming-call>
    </span>
    <el-popover
      popper-class="dialingAlert"
      v-if="voiceProvider == 'bandwidth'"
      placement="top-start"
      :width="300"
      :visible="
        userId &&
        voiceProvider == 'bandwidth' &&
        getMyUnansweredBandwidthCalls(userId)
      "
    >
      <p>
        <font-awesome-icon icon="phone" size="1x"></font-awesome-icon>
        Dialing contact. Please wait while we connect....
      </p>
    </el-popover>
    <send-message-dialog
      :toggle-dialog="showSendDialog"
      :get-editor-type="'email'"
      @update:valid="onTestEmail"
      @update:dirty="onTestEmail"
    ></send-message-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { markRaw } from 'vue';

import { Promotion } from '@element-plus/icons-vue';

import { Device } from '@twilio/voice-sdk';
import BandwidthRtc, { RtcStream } from '@bandwidth/webrtc-browser';

import authService from 'src/services/authServices';
import agencyService from 'src/services/agencyServices';
import AgentService from 'src/services/agentService';
import WebsocketService from 'src/services/websocketService';
import configService from 'src/services/configService';
import campaignService from 'src/services/campaignServices';

// Components
import EditorTitle from 'src/components/UIComponents/Editor/EditorTitle';
import EditorSubject from 'src/components/UIComponents/Editor/EditorSubject';
import AgentStatus from 'src/components/UIComponents/AgentStatus';
import AgentDestination from 'src/components/UIComponents/AgentDestination';
import TemplateSettingsDialog from './TopNavBarDialogs/TemplateSettingsDialog';
import SendMessageDialog from './TopNavBarDialogs/SendMessageDialog';

import TopNavBarNames from './TopNavComponents/TopNavBarNames';
import GlobalBrandSelect from 'src/components/Dashboard/Layout/TopNavComponents/GlobalBrandSelect';
import ProfileDropDown from './TopNavComponents/ProfileDropDown';
import EditorPreview from 'src/components/Dashboard/Views/Templates/Editor/EditorPreview';

// For debug - when we're ready we can remove these.
import AgentStatusList from 'src/components/UIComponents/AgentStatusList';
import CallStatusList from 'src/components/UIComponents/CallStatusList';
import SegmentStatusList from 'src/components/UIComponents/SegmentStatusList';
import CallControl from 'src/components/UIComponents/CallControl';

export default {
  components: {
    EditorTitle,
    EditorSubject,
    GlobalBrandSelect,
    SendMessageDialog,
    TemplateSettingsDialog,
    TopNavBarNames,
    ProfileDropDown,
    AgentStatus,
    AgentDestination,
    // For debug - when we're ready, we can remove these
    AgentStatusList,
    CallStatusList,
    SegmentStatusList,
    CallControl,
    EditorPreview,
    // Icons
    Promotion: markRaw(Promotion),
  },
  data() {
    return {
      callDebug: false, // Use this for debug. Remove eventually

      toggleSearch: false,
      toggleControlPane: true,
      toggleNameAttribute: false,
      activeNotifications: false,
      showNavbar: false,
      editorMode: false,
      automationMode: false,
      showCallControl: false,

      editorName: undefined,

      accountType: undefined,
      contactsExpunged: false,
      subscriptionExist: true,

      // send dialog
      showSendDialog: 0,
      showSettingsDialog: 0,

      // Agency
      agencyCounter: 0,

      // Agent SoftLine Controls
      agentDevice: undefined, // Twilio or Bandwidth SoftPhone device
      voiceProvider: undefined,

      // Top level websocket
      // Make this into a top level module, bus
      // Or move this to a store if you want to get crazy
      websocket: undefined,
      // broadcastChannel: undefined, Not using this yet.
      primaryWebsocket: false, // Is the primary tab. Where calls will be routed
      //Icons
      Promotion,
    };
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    useLongToken: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    $route(to, from) {
      // skip hiding the side
      if (+this.$route.query.side === 1) {
        return;
      }

      this.transitionSideBar(to.name);
      if (to.name === 'Template Editor') {
        // || input === 'Email Editor'
        this.editorName = this.getEditorName;
      }
    },
    editorName(n, o) {
      this.setEditorMeta({ name: n });
    },
    toggleControlPane(n, o) {
      this.showControlPane(n);
    },
    useLongToken(n) {
      if (n == true) {
        // just tear down the websocket if any
        this.beforeUnmount();
      }
    },
  },
  computed: {
    ...mapGetters('appConfig', ['getAppConfig']),
    ...mapGetters('brands', ['getBrands']),
    ...mapGetters('realtime', [
      'getMyAgentStatus',
      'getMySegments',
      'getMyAnsweredSegments',
      'getMyUnansweredSegments',
      'getMyUnansweredBandwidthCalls',
      'getMyDispositions',
      'getCallData',
      'getCallById',
      'getHaveNewSms',
      'getHaveNewMessage',
      'getHaveNewVoicemail',
      'getHaveNewLead',
      'getHaveNewTask',
      'getHaveNewWhatsapp',
    ]),
    ...mapGetters('workflows', ['getCurrentWorkflow']),
    ...mapGetters('campaigns', ['getCurrentCampaign']),
    ...mapGetters('auth', [
      'getEmulateMode',
      'getAuthCompanies',
      'getAgencyMode',
      'getLoginAccount',
      'getUserData',
      'getCompanyName',
    ]),
    ...mapGetters('dialDestinations', ['getDialDestinations']),
    ...mapGetters('editor', ['getEditorName']),
    isEditorMode() {
      if (this.editorMode) {
        return this.getEditorType;
      }
      return false;
    },
    isAgencyMode() {
      if (
        this.isEditorMode === false &&
        this.automationMode === false &&
        this.getAgencyMode
      ) {
        return true;
      }
      return false;
    },
    getEditorType() {
      return this.$route.params.type || 'email';
    },
    getPlaceHolderName() {
      switch (this.getEditorType) {
        case 'sms':
          return 'SMS Name...';
        case 'popup':
          return 'Popup Name...';
        case 'email':
          return 'Email Name...';
        case 'landing':
          return 'Page Name...';
      }
    },
    showUpdateButton() {
      if (!this.subscriptionExist) return true;
      return false;
    },
    isSaved() {
      if (+this.$route.params.id > 0) return true;
      return false;
    },
    sideBarState() {
      return this.$sidebar.isMinimized;
    },
  },
  async created() {
    this.transitionSideBar(this.$route.name);
    this.editorName = this.getEditorName;
  },
  async beforeMount() {
    // Register a listener for refresh of browser on primary to tear down WS
    window.addEventListener('beforeunload', (e) => {
      if (this.primaryWebsocket && this.sharedWorker) {
        // unload is going to end the window before the promise can resolve most likely
        // So force the socket closed to tear down the other tabs
        // Its destructive and most likely will break state on the other shared tabs
        // But you are refreshing the primary page, dont do that!
        this.agentLogout();
        e.preventDefault();
        if (this.sharedWorker) {
          this.sharedWorker.port.postMessage({
            source: 'wsClose',
          });
        }
        return (e.returnValue = '');
      }
    });
  },
  async beforeUnmount() {
    // Happens on a clean logout or if the app is still controlling the window.
    // Wont happen if you change URL or refresh the browser
    // Check if we have not logged out normally before we take a new route.
    if (this.primaryWebsocket && this.sharedWorker) {
      // In this case we can assume the app will control the flow so promises
      // will work as expected
      try {
        await this.agentLogout();
      } catch (e) {
        // Assume we might already be logged out if this is from a forced redirect
      }
      if (this.sharedWorker) {
        this.sharedWorker.port.postMessage({
          source: 'wsClose',
        });
      }
    }
  },
  methods: {
    ...mapActions('campaigns', ['saveCurrentCampaign']),
    ...mapActions('workflows', ['saveCurrentWorkflow', 'fetchTriggerConfig']),
    // odd naming schemes here, emulate and simulate
    ...mapActions('auth', ['emulate', 'setAgency', 'logout']),
    ...mapActions('editor', [
      'showControlPane',
      'saveTemplate',
      'setEditorMeta',
      'showNameField',
      'addTestEmail',
      'addTestPhoneNumber',
      'removeTestMessageContact',
      'changeTestMessageStatus',
      'changeTemplateId',
    ]),

    ...mapActions('realtime', [
      'fetchActiveCalls',
      'fetchActiveSegments',
      'fetchActiveAgents',
      'modifyAgent',
      'modifyDisposition',
      'modifyHaveNewSms',
      'modifyHaveNewMessage',
      'modifyHaveNewVoicemail',
      'modifyHaveNewLead',
      'modifyHaveNewWhatsapp',
    ]),
    getCallNumber(callId) {
      const call = this.getCallById(callId);
      if (call == undefined) {
        return undefined;
      }
      return call.direction == 'inbound' ? call.from_number : call.to_number;
    },
    getCallName(callId) {
      const call = this.getCallById(callId);
      return call ? call.caller_name : undefined;
    },
    getCallContact(callId) {
      const call = this.getCallById(callId);
      // Timing error where the call might not be set when the segment is
      // THe segment might outlive the call object
      return call ? call.contact_id : undefined;
    },

    async listenWebSocket() {
      // This will set the status if we had an existing gone
      // TODO: Make this a config item somewhere in the agent
      const topics = [
        'airbnb',
        'sms',
        'agentStatus',
        'voice',
        'callData',
        'disposition',
        'voiceSegment',
        'notification',
        'voicemail',
        'lead',
        'email',
        'task',
        'whatsapp',
      ];

      // Register the shared worked and if not connected then create a new socket
      this.sharedWorker = new SharedWorker('/sharedWorker.js');
      // Start the worker. If we are the first we will be asked to get connect info
      const worker = this.sharedWorker.port.start();
      /* For now we are just iterationg all the ports.
       move to a broadCastChannel in time
      this.broadcastChannel = new BroadcastChannel('sendsquared');
      this.broadcastChannel.onmessage = async (m) => {
        const message = m.data;
        switch (message.source) {
          case 'ws':
            console.log('we have a broadcast ws message from shared worker', message);
            this.websocket = message.connected;
            // Will primary every change in the middle of a run?
            break;
          case 'payload':
            WebsocketService.processMessage(message.data);
            break;
          default:
            console.error('Unexpected Shared worker braodcast source', message);
        }
      }
      */

      this.sharedWorker.port.onmessage = async (m) => {
        // console.log('we have a message', m.data);
        const message = m.data;
        if (!message.source) {
          console.error('Unexpected Shared worker message', m);
          return;
        }

        switch (message.source) {
          // Message fires the first time the sharedWorker is connected to this tab
          case 'init':
            if (message.primary) {
              // Indicates that you are the first to connect so get the WS info
              const result = await WebsocketService.connect(
                this.userId,
                topics
              );
              if (result.status != 200) {
                console.log('error establishing WS connection', result.data);
                return;
              }
              // The worker will start the actual WS once it gets this message
              this.sharedWorker.port.postMessage({
                source: 'wsConnect',
                url: result.data.url,
                topics: topics,
                // userId: userId,
              });
            }
            // We know right away if the socket is primary but we have to wait for it to connect
            this.websocket = message.connected;
            this.primaryWebsocket = message.primary;

            break;
          case 'workerClose':
            if (message.error) {
              this.$message({
                message: 'Connection Error',
                type: 'error',
                showClose: true,
              });
            }
            this.websocket = undefined;
            this.sharedWorker = undefined;
            break;
          case 'ws':
            // This should be done after the worker is connected as only the first
            // client to the shared worked will setup audio
            // Other tabs that 'login' will just listen on the existing websocket
            if (this.primaryWebsocket && !this.websocket && message.connected) {
              await this.processAgentLogin();
            }

            this.websocket = message.connected;
            // Will primary every change in the middle of a run?

            break;
          case 'payload':
            WebsocketService.processMessage(message.data);
            break;
          default:
            console.error('Unexpected Shared worker source', message);
        }
      };
    },
    // Used to auth softphone device when a new token is provided
    async authDevice(result) {
      if (!result) {
        // remove device is none set
        this.agentDevice = undefined;
        return;
      }

      if (result.key) {
        if (result.provider == 'twilio') {
          this.setupTwilio(result.key);
        }
        if (result.provider == 'bandwidth') {
          await this.setupBandwidth(result.key);
        }
      }
    },

    /**
     * Handle the agency selection
     */
    async handleAgencySelection(command) {
      this.$loading({
        lock: true,
        text: 'Loading session',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)',
      });
      const id = +command;

      // add in lazy loader
      // await configService.unloadConfig();
      const r = await agencyService.simulateClient(id);
      this.setAgency(r.data);
      // TODO: dont load all this stuff if we are leaving the page anyway
      // await configService.loadConfig();
      // this.$router.push('/admin/campaigns/list');
      // this.$loading = false;
      location.href = '/admin/campaigns/list';
    },

    transitionSideBar(input) {
      if (input === 'Template Editor' || input === 'Email Editor') {
        this.editorMode = true;
        this.$sidebar.hideSideBar(true);
        return;
      }

      if (input === 'Automation Editor') {
        this.editorMode = false;
        this.automationMode = true;
        this.$sidebar.hideSideBar(true);
        return;
      }
      this.automationMode = false;
      this.editorMode = false;
      this.$sidebar.hideSideBar(false);
    },

    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },

    /**
     * This is just for saving as a standard template
     */
    async saveTemplateButton(command) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
      switch (command) {
        case 'new':
          await this.changeTemplateId(0);
          break;
        case 'send-now':
          // Still somewhat hacky.  But less so.
          this.sendTemplateButton();
          loading.close();
          return;
      }

      const type = this.$route.params.type;
      const id = await this.saveTemplate({ type: type, system: false });
      // close
      loading.close();
      this.$message({
        message: 'Template saved',
        type: 'success',
        showClose: true,
      });
      if (parseInt(this.$route.params.id, 10) === 0) {
        setTimeout(() => {
          this.$router.push({
            path: '/admin/templates/edit/' + type + '/' + id,
            query: { side: 1 },
          });
        }, 500);
      }
    },

    /**
     * This will save as a system template
     */
    async sendTemplateButton() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });

      const type = this.$route.params.type;
      const templateId = await this.saveTemplate({ type: type, system: true });
      // close
      loading.close();
      // Save back to campaign

      // Re-route to campaign send
      if (this.getCurrentCampaign.active) {
        // Update the template id
        const current = {
          active: true,
          templateSelection: +templateId,
          campaignId: this.getCurrentCampaign.campaignId,
          campaignType: this.getCurrentCampaign.campaignType,
        };
        await this.saveCurrentCampaign(current);
        this.$router.push(
          `/admin/campaigns/send/${this.getCurrentCampaign.campaignType}/${this.getCurrentCampaign.campaignId}`
        );
        return;
      }

      // Re-route to Automation Node
      if (this.getCurrentWorkflow.active) {
        const current = {
          active: true,
          templateSelection: +templateId,
          workflow: this.getCurrentWorkflow.workflow,
          selectedNode: this.getCurrentWorkflow.selectedNode,
        };
        // Save Workflow
        await this.saveCurrentWorkflow(current);
        this.$router.push(
          '/admin/automations/edit/' + this.getCurrentWorkflow.workflow
        );
        return;
      }
    },
    closeEditor() {
      // todo look for the type
      if (this.getCurrentCampaign.active) {
        // Prompt if the user wants to save, exit or cancel
        if (this.$route.name == 'Email Editor') {
          // For now, we can only exist the editor.  This needs to be moved to the 
          // EditorSave.vue file.  But this will hold us over for the remaining of the week.
          this.$confirm(
            'You have unsaved changes that you will loose if you exit',
            'Exit editor',
            {
              confirmButtonText: 'Discard and Exit',
            }
          ).then(async () => {
            this.$router.push(`/admin/campaigns/list`);
          });
        } else {
          this.$confirm(
            'You have unsaved changes, save and exit?',
            'Save and exit',
            {
              distinguishCancelAndClose: true,
              confirmButtonText: 'Save',
              cancelButtonText: 'Discard Changes',
            }
          )
            .then(async () => {
              const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)',
              });

              const templateId = await this.saveTemplate({
                type: 'email',
                system: true,
              });
              const currentCampaign = (
                await campaignService.fetchCampaign(
                  this.getCurrentCampaign.campaignId
                )
              ).data;

              currentCampaign.action.type_value = templateId;
              const _updateCampaign = (
                await campaignService.patchCampaign(
                  this.getCurrentCampaign.campaignId,
                  { action: currentCampaign.action }
                )
              ).data;
              loading.close();
              this.$message({
                message: 'Campaign template saved',
                type: 'success',
                showClose: true,
              });
              // Send back to the campaign listing page.
              this.$router.push(`/admin/campaigns/list`);
            })
            .catch((action) => {
              switch (action) {
                case 'cancel':
                  // Send back to the campaign listing page.
                  this.$router.push(`/admin/campaigns/list`);
                  break;
              }
            });
        }
        return;
      }
      if (this.getCurrentWorkflow.active) {
        this.$router.push(
          '/admin/automations/edit/' + this.getCurrentWorkflow.workflow
        );
        return;
      }

      this.$router.push('/admin/templates/list-emails');
    },
    closeAutomation() {
      this.$router.push('/admin/automations/list');
    },
    async exitEmulation() {
      this.$loading({
        lock: true,
        text: 'Loading session',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)',
      });
      const r = await authService.stopEmulate();
      this.emulate(r.data);
      // await configService.loadConfig();
      location.href = '/admin/accounts';
      /*
      setTimeout(() => {
        location.href = '/admin/accounts';
      }, 500);
      */
    },

    /**
     * Agent Login
     */
    async agentLogin() {
      // Enable notifications
      // We assume a modern browser
      if (!('Notification' in window)) {
        this.$message({
          message: 'Browser does not support notifications',
          type: 'warning',
          showClose: true,
        });
      } else {
        // Assume promise based works, looking at you Safari
        Notification.requestPermission().then((permission) => {
          // Eventually make this a global config so users can turn them
          // off if they really want. From within the app.
        });
      }

      await this.listenWebSocket();
      // Techinically the socket might not be live by the time this fires
      // but it will update eventaully

      // These need to be loaded in order
      // MyCalls relies on agents,
      // CallSegment relies on segments
      // This is a bit of a race condition as we might update a live status with
      // what is pulled from the DB. Slim but probable
      await this.fetchActiveAgents();
      await this.fetchActiveSegments();
      await this.fetchActiveCalls();
    },
    async processAgentLogin() {
      // Wait for the connection before doing a login so you don't miss the event
      const result = await AgentService.login();
      this.voiceProvider = result.provider;
      // User was logged in and in a state that cant be reset.
      // Rebuild the existing state as much as you can, wrapup, etc
      if (result.existing) {
        // Do something here
        // Route to the wrapup or somethings
        // TODO: find existing wrap up... route them to that page.
        // TODO: set them up in disposition
      }
      for (const d of result.dispositions) {
        this.modifyDisposition(d);
      }

      if (result.key) {
        if (result.key.provider == 'twilio') {
          this.setupTwilio(result.key.key);
        }
        if (result.key.provider == 'bandwidth') {
          await this.setupBandwidth(result.key.key);
        }
      }

      // Result of 0 means no SMS, message, VM, etc
      // This should be refreshed on the agent login but its done on the user login
      // We need to make user login more common. As agent login is.
      // Or it should at least be refreshed on token refresh these once we get there.
      if (
        result.latest.sms_id &&
        this.getUserData.lastSmsSeen < result.latest.sms_id
      ) {
        this.modifyHaveNewSms(true);
      }
      if (
        result.latest.message_id &&
        this.getUserData.lastMessageSeen < result.latest.message_id
      ) {
        this.modifyHaveNewMessage(true);
      }
      // We turn on the VM and SMS badge if you have an assigned uncompleted message
      // If you want vm assign messages.
      // Otherwise we turn it on if there is a new one we have not seen if
      // They have the VM new notify.
      // Or we do nothing if you have none of these configs set
      if (
        result.latest.voicemail_id &&
        this.getUserData.lastVoicemailSeen < result.latest.voicemail_id &&
        this.getAppConfig.callCenter.vmNotifyNew
      ) {
        this.modifyHaveNewVoicemail(true);
      }
      if (
        result.latest.assigned_vm &&
        this.getAppConfig.callCenter.vmNotifyAssign
      ) {
        this.modifyHaveNewVoicemail(true);
      }

      if (
        result.latest.sms_id &&
        this.getUserData.lastSmsSeen < result.latest.sms_id &&
        this.getAppConfig.callCenter.smsNotifyNew
      ) {
        this.modifyHaveNewVoicemail(true);
      }
      if (
        result.latest.assigned_sms &&
        this.getAppConfig.callCenter.smsNotifyAssign
      ) {
        this.modifyHaveNewSms(true);
      }
      if (
        result.latest.assigned_whatsapp &&
        this.getAppConfig.callCenter.whatsappNotifyAssign
      ) {
        this.modifyHaveNewWhatsapp(true);
      }

      // Whatsapp
      if (
        result.latest.whatsapp_id &&
        this.getUserData.lastWhatsappSeen < result.latest.whatsapp_id &&
        this.getAppConfig.callCenter.whatsappNotifyNew
      ) {
        this.modifyHaveNewWhatsapp(true);
      }
      if (
        result.latest.assigned_whatsapp &&
        this.getAppConfig.callCenter.whatsappNotifyAssign
      ) {
        this.modifyHaveNewWhatsapp(true);
      }

      if (result.latest.lead_id) {
        this.modifyHaveNewLead(true);
      }

      this.$message({
        type: 'success',
        message: 'You are now logged in 🙌',
        showClose: true,
      });
    },
    // Agent logout
    async agentLogout() {
      // if (this.primaryWebsocket) {
      try {
        await AgentService.logout();
      } catch (e) {
        if (e && e.response && e.response.data) {
          this.$message({
            type: 'error',
            message: e.response.data,
            showClose: true,
          });
        }
        throw e;
      }
      // We need to decide if we want all tabs to close or just this one
      // For now if you log out on any tab it kills socket for all
      if (this.sharedWorker) {
        this.sharedWorker.port.postMessage({
          source: 'wsClose',
        });
        this.sharedWorker = undefined;
      } else {
        // We need to push the fake agent in here to flush
        // the realtime data since the socket is closed
        this.modifyAgent({ user_id: this.userId, status: 'logout' });
      }

      this.$message({
        type: 'success',
        message: 'You are now logged out 🙌',
        showClose: true,
      });
    },
    setupTwilio(token) {
      console.log('we are setting up twilio, we are a remote client');
      // TODO: We need to make this a config somewhere
      const options = {
        allowIncomingWhileBusy: true,
        codecPreferences: ['opus', 'pcmu'],
      };
      // This set ring for incoming but outgoing uses the wait-url audio
      if (this.getAppConfig?.callCenter?.ringAudio != null) {
        options['sounds'] = {
          incoming: this.getAppConfig.callCenter.ringAudio,
        };
      }

      this.agentDevice = markRaw({
        provider: 'twilio',
        device: new Device(token),
        activeCall: undefined, // Will store the active call, provider dependent
      });
      this.agentDevice.device.updateOptions(options);
      // Nested functions
      const doEndCall = (call) => {
        // this.agentDevice.activeCall = undefined;
        this.agentDevice.activeCall = this.agentDevice.device.calls.pop();
        if (this.agentDevice.activeCall) {
          console.log('We had a pending call that was assigned to primary.');
        }
      };
      const doCallError = (error) => {
        console.error('Err, we got a call error', error);
        this.agentDevice.activeCall = undefined;
        this.agentDevice.activeCall = this.agentDevice.device.calls.pop();
        // This can happen on oncoming or while connected;
        // TODO: msg to user;
      };
      const doCallWarning = (warningName, warningData) => {
        console.error('Twilio warning crossed', warningName, warningData);
        switch (warningName) {
          case 'high-rtt':
          case 'low-mos':
          case 'high-jitter':
          case 'high-packet-loss':
          case 'ice-connectivity-lost':
            this.$message({
              message:
                ' High latency detected. Calls will have degraded quality',
              type: 'warning',
              showClose: true,
            });
            break;
          case 'constant-audio-input-level':
            // This fires on initial start of call. Odd
            /*
          this.$message({
            message: ' Audio input issue detected. Is the mic working?',
            type: 'warning',
            showClose: true,
          });
          */
            break;
          default:
            this.$message({
              message:
                ' General network issues detected. Calls will have degraded quality',
              type: 'warning',
              showClose: true,
            });
        }
      };

      const doIncomingCall = (call) => {
        if (this.agentDevice.device.calls.length > 1) {
          // Just record when this happens for now. We can address this later
          console.log(
            'We got an incoming call while on an active call, queueing this one'
          );
        }
        if (this.agentDevice.activeCall == undefined) {
          this.agentDevice.activeCall = call;
        }

        call.on('cancel', doEndCall);
        call.on('disconnect', doEndCall);
        call.on('reject', doEndCall);
        call.on('warning', doCallWarning);
        call.on('warning-cleared', (warningName) => {
          console.log('warning-cleared', warningName);
        });
        call.on('error', doCallError);
      };

      // Register all the listeners.
      // We can pass events from the components too but for now just use the listeners
      // Might change with land lines as we will need events to know whats going on...
      // but for now this will work and is simple
      this.agentDevice.device.on('registered', () => {
        // Pass this to the call control.
        // Buttons should be disabled if this is not live.
      });
      this.agentDevice.device.on('unregistered', () => {
        // TODO: we need to adjust state and call status otherwise we can end up in
        // some odd situations that you cant get out of if the device goes away
        // And now you cant complete the call or such.
        console.error(
          'Error the Device was unregistered and now you cant make calls'
        );
        this.$message({
          message: 'Connection error to twilio. You will need to log in again',
          type: 'error',
          showClose: true,
        });

        this.agentDevice.device.destroy();
        this.agentDevice = undefined;
        this.agentLogout();
      });
      this.agentDevice.device.on('error', (error) => {
        console.log('Ahh snap, stuff broke: ' + error.message);
      });
      // For the important ones make a real function, the one offs above are fine for debugging
      // register callback an incoming Call
      this.agentDevice.device.on('incoming', doIncomingCall);

      // Register after setting up listeners to avoid timing errors
      this.agentDevice.device.register();
    },

    async setupBandwidth(token) {
      console.log('we are setting up bandwidth, we are a remote client');

      const bandwidthRtc = new BandwidthRtc();
      // Connect to Bandwidth WebRTC
      // TODO: make this configurable so we can turn it on only as needed
      // bandwidthRtc.setLogLevel('info');
      const result = await bandwidthRtc.connect({
        deviceToken: token,
      });
      // Enable the device but don't start streaming until we have a stream

      // Self loop for testing audio input
      /*
      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      }).then((c) => {
          console.log('play audio', c);
          const audio = document.createElement('audio');
          audio.controls = true;
          audio.autoplay = true;
          // window.stream = stream;
          audio.srcObject = c;

          // this.agentDevice.device.setMicEnabled(false);
      }).catch((e) => { console.log('error', e)});
      */

      // Register the device for the session
      this.agentDevice = {
        provider: 'bandwidth',
        device: bandwidthRtc,
        activeCall: undefined,
        activeStreams: 0, // track the streams so we know when to unpublish
      };
      // await this.agentDevice.device.setMicEnabled(false);

      bandwidthRtc.onStreamAvailable(async (event) => {
        console.log(
          `A stream is available with endpointId=${event.endpointId}, its media types are ${event.mediaTypes} and the stream itself is ${event.mediaStream}`
        );
        this.agentDevice.activeStreams++;
        // Only publish one stream.
        // But we need to  play them all
        if (!this.agentDevice.activeCall) {
          // console.log('stats', await bandwidthRtc.delegate.publishingPeerConnection.getStats());
          // Just keep the same interface as Twilio. so empty object to show its set
          // But it does not have any control functions like twilio does
          this.agentDevice.activeCall = {};
          // Only publish if not in ghost mode
          const mySegs = this.getMySegments(this.userId);
          if (mySegs.length && mySegs[0].is_ghost != true) {
            console.log('publishing agent stream now', event.endpointId);
            const stream = await bandwidthRtc.publish({
              audio: {
                // advanced: [{
                autoGainControl: true,
                channelCount: 1,
                deviceId: 'default',
                echoCancellation: true,
                // latency: 0.01,
                noiseSuppression: true,
                // TODO: Make this adjustable on the fly to help lower bandwidth users
                sampleRate: 48000,
                // sampleRate: 200,
                sampleSize: 32,
                // }],
              },
              video: false,
            });
          }
        }
        // Turn mic on regardless. Hopefully this is idempotent
        await this.agentDevice.device.setMicEnabled(true);

        // TODO: Check this every 10 seconds or so on a call and automatically alert the user or something
        // var audio = new Audio();
        const audio = document.createElement('audio');
        audio.setAttribute('id', event.endpointId);
        audio.autoplay = true;
        audio.controls = true;
        audio.srcObject = event.mediaStream;
        audio.play();
      });

      bandwidthRtc.onStreamUnavailable(async (event) => {
        console.log(
          `A stream is UNAVAILABLE with endpointId=${event.endpointId}, its media types are ${event.mediaTypes} and the stream itself is ${event.mediaStream} and the event is ${event}`
        );
        this.agentDevice.activeStreams--;
        if (event) {
          const audio = document.getElementById(event);
          if (audio) {
            audio.remove();
          }
        }

        if (this.agentDevice.activeStreams == 0) {
          console.log('Turning stream and the mic off');
          try {
            await this.agentDevice.device.unpublish();
          } catch (e) {
            // Expect unpublish errors when a ghost but try it anyway to be safe
            console.error(e);
          }
          // await this.agentDevice.device.setMicEnabled(false);
          this.agentDevice.activeCall = undefined;
        }
      });
    },

    async agentAnswer(callId) {
      // Can only answer on softphone.  Ignore others
      if (
        this.agentDevice.provider == 'twilio' &&
        this.agentDevice.activeCall
      ) {
        // Is a twilio call object
        this.agentDevice.activeCall.accept();
      }
      if (
        this.agentDevice.provider == 'bandwidth'
        // && this.agentDevice.activeCall
      ) {
        // WE could publish here to make connecting a little faster
        await this.agentDevice.device.setMicEnabled(true);
        await AgentService.answerCall(callId, undefined, true);
        //this.agentDevice.activeCall.<somnething>
      }
    },
    agentReject(callId) {
      if (this.agentDevice == undefined) {
        AgentService.rejectCall(callId);
      }
      // Guard for invalid timing events when call is not live yet
      else if (
        this.agentDevice.provider == 'twilio' &&
        this.agentDevice.activeCall
      ) {
        // For now we only accept one call.
        // ONce we have segments this will be simpler.
        this.agentDevice.activeCall.reject();
        this.agentDevice.activeCall = undefined;
      } else if (this.agentDevice.provider == 'bandwidth') {
        // Bandwidth does not set active call until answered
        // just a normal api call
        AgentService.rejectCall(callId);
      }
    },
    agentHangup(callId) {
      if (
        this.agentDevice.provider == 'twilio' &&
        this.agentDevice.activeCall
      ) {
        // Make work with multi eventually
        this.agentDevice.activeCall.disconnect();
        this.agentDevice.activeCall = undefined;
      } else if (
        this.agentDevice.provider == 'bandwidth' &&
        this.agentDevice.activeCall
      ) {
        // do something. just api call for now.
        AgentService.hangUp(callId);
      } else {
        AgentService.hangUp(callId);
      }
    },
    goToInbox() {
      if (this.$router.currentRoute.path !== '/admin/inbox/') {
        this.$router.push('/admin/inbox/');
      }
    },
    goToVoicemail() {
      if (this.$router.currentRoute.path !== '/admin/voicemail/') {
        this.$router.push('/admin/voicemail/');
      }
    },
    goToLead() {
      if (this.$router.currentRoute.path !== '/admin/call-center/leads') {
        this.$router.push('/admin/call-center/leads/?followup=true');
      }
    },
    goToTask(userId) {
      if (this.$router.currentRoute.path !== '/admin/tasks/view') {
        this.$router.push('/admin/tasks/view?user_id=' + userId);
      }
    },
    onTestEmail({ message: message, messageType: messageType }) {
      this.$message({
        message: message,
        type: messageType,
        showClose: true,
      });
    },
  },
};
</script>
<style lang="scss">
/* element-plus header adjustments */
.header-row-alignment {
  margin-top: 6px;
}
#closeAutomation {
  // not happy with this approach- but will find it in the parent class soon enough.
  position: relative;
  top: -6px;
  left: -6px;
}
.navbar-container {
  border-bottom: 1px rgb(220, 223, 230) solid;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .link {
      font-size: 12px;
      color: #b4b4b4;
    }
  }
}
.spotlight-search-icon {
  margin-right: 10px;
  margin-top: 6px;
  color: #8b8b8b;
  cursor: pointer;
}
.phone-icon {
  margin-right: 10px;
  margin-top: 6px;
  color: #8b8b8b;
  cursor: pointer;
}
.phone-icon-disabled {
  margin-right: 10px;
  margin-top: 6px;
  color: #aa0000;
  cursor: not-allowed;
}
.notificationBadge {
  margin-right: 10px;
  margin-top: 6px;
  color: #8b8b8b;
}
.notificationBadgeGreen {
  margin-right: 10px;
  margin-top: 6px;
  color: green;
}
.dialingAlert {
  min-width: 360px !important;
  height: 50px !important;
  background-color: #e6f8f6 !important;
  color: #06baa5 !important;
  position: absolute;
  top: 15% !important;
  left: 40% !important;
}
</style>
