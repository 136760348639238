
import { authedAxios } from '../shared/axiosWrapper';
import { AD_BASE_SURVEY_PATH } from '../constants';
import { AD_BASE_CONTACT_SURVEY_PATH } from '../constants';

export default {
  fetchAll(params) {
    return authedAxios.get(AD_BASE_SURVEY_PATH, { params: params });
  },

  fetchById(id) {
    return authedAxios.get(AD_BASE_SURVEY_PATH + id, {});
  },

  updateSurvey(id, payload) {
    return authedAxios.put(AD_BASE_SURVEY_PATH + id, payload);
  },

  createSurvey(payload) {
    return authedAxios.post(AD_BASE_SURVEY_PATH, payload);
  },


  // Specific contact surveys
  fetchContactSurveys(afterId, limit, filters, sort) {
    const params = {
      limit: limit ? limit : 100,
      filter: filters,
      sort: sort,
    };
    if (afterId) {
      params.after_id = afterId;
    }

    return authedAxios.get(AD_BASE_CONTACT_SURVEY_PATH, { params: params });
  },

  runReport(id) {
    return authedAxios.get(AD_BASE_SURVEY_PATH + id + '/report/');
  },
};
