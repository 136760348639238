<template>
  <el-select
    v-model="selectedId"
    @change="doChange"
    :placeholder="callPlaceholder"
    :size="size"
  >
    <el-option v-if="allowAll" :value="0"
      :label="getAppConfig?.callCenter?.useCallResult === true ? 'All Call Results' : 'All Call Types'"
      ></el-option>
    <el-option
      v-for="option in getCallResults"
      :value="returnTypeString ? String(option.id) : +option.id"
      :key="option.id"
      :label="option.label"
    >
      <!-- TODO add label color -->
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'callResultSelect',
  computed: {
    ...mapGetters('callResults', ['getCallResults']),
  },
  emits: ['change'],
  props: {
    initialId: {
      type: Number,
      default: () => undefined,
    },
    size: {
      type: String,
      default: () => 'small',
    },
    placeholder: {
      type: String,
      required: false,
    },
    returnTypeString: {
      type: Boolean,
      default: () => false,
    },
    allowAll: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      selectedId: undefined,
      useCallResult: false,
      callPlaceholder: 'Select a Call Type',
    };
  },
  watch: {
    value(n) {
      this.selectedIndex = parseInt(n);
    },
    initialId(n) {
      this.selectedId = parseInt(n);
    },
  },
  async created() {
    if (this.placeholder != undefined) {
      this.callPlaceholder = this.placeholder;
    } else {
      // This will be removed in time, plan for it to be gone
      this.callPlaceholder = this.getAppConfig()?.callCenter?.useCallResult === true ? 'Select a Call Result' : 'Select a Call Type';
    }
    await this.fetchCallResults();
    if (this.initialId) {
      this.selectedId = parseInt(this.initialId);
    }
  },
  methods: {
    ...mapActions('callResults', ['fetchCallResults']),
    ...mapGetters('appConfig', ['getAppConfig']),
    doChange() {
      /*
      this.$emit(
        'input',
        this.getCallResults.find((cr) => cr.id == this.selectedId)
      );
      */
      this.$emit(
        'change',
        this.getCallResults.find((cr) => cr.id == this.selectedId)
      );
    },
  },
};
</script>

