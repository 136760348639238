<template>
  <span>
    <h2
      class="incoming-call-name"
      :style="topPane ? 'font-size:14px;' : ''"
      @click="viewContact()"
    >
      {{ callerName }}
    </h2>
    <p class="incoming-phone-number" @click="viewContact()">
      {{ formatPhoneNumber(phoneNumber) }}
    </p>
  </span>
</template>
<script>
export default {
  props: {
    topPane: {
      type: Boolean,
      default: () => false,
    },
    contactId: {
      type: Number,
      default: () => 0,
    },
    callerName: {
      type: String,
      default: 'Unknown',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  methods: {
    viewContact() {
      if (this.contactId === 0) return;
      // TODO: support company lookup
      this.$router.push('/admin/contacts/view/' + this.contactId);
    },
  },
};
</script>
<style>
.incoming-call-name {
  font-family: 'open sans';
  margin-bottom: 0;
  margin-top: 0.3em;
  font-weight: 600;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
}
.incoming-phone-number {
  display: block !important;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}
</style>
