<template>
  <div
    class="sidebar"
    :data-active-color="activeColor"
    :data-color="backgroundColor"
  >
    <div class="logo">
      <router-link
        class="simple-text logo-mini"
        aria-label="sidebar mini logo"
        to="/admin/overview"
      >
        <div class="logo-img">
          <img src="~img/logo/ss-logo-8-12-2021.svg" alt="SendSquared" />
        </div>
        <div class="logo-img-mini">
          <!-- <img src="~img/logo/white-s-10-19-2021.svg" alt="SendSquared" /> -->
          <img src="~img/logo/white-brandmark-send-squared-4-24-24.svg" alt="SendSquared" />
        </div>
      </router-link>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <ul class="nav">
        <slot name="links">
          <template v-for="(link, index) in sidebar">
            <sidebar-item
              v-if="link && hasPermission(link) && hasConfig(link)"
              :key="link.name + index"
              :link="link"
            >
              <template v-if="link.children">
                <template v-for="(subLink, index) in link.children">
                  <sidebar-item
                    v-if="
                      subLink && hasPermission(subLink) && hasConfig(subLink)
                    "
                    :key="subLink.name + index"
                    :link="subLink"
                  ></sidebar-item>
                </template>
              </template>
            </sidebar-item>
          </template>
        </slot>
      </ul>
      <div class="sidebar-bottom-item">
        <button
          type="button"
          class="btn btn-icon btn-neutral"
          style="margin-right: 15px"
          @click="minimizeSidebar()"
        >
          <i
            v-if="$sidebar.isMinimized"
            class="nc-icon nc-minimal-right text-center"
          ></i>
          <i
            v-if="!$sidebar.isMinimized"
            class="nc-icon nc-minimal-left text-center"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import 'perfect-scrollbar/dist/css/perfect-scrollbar.css';

export default {
  props: {
    title: {
      type: String,
      default: 'SendSquared',
      description: 'Sidebar title',
    },
    backgroundColor: {
      type: String,
      default: 'sendsquared-gray',
      validator: (value) => {
        let acceptedValues = [
          'white',
          'brown',
          'black',
          'adbase-gurple',
          'sendnesis-blue',
          'sendsquared-gray',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (white|brown|black|adbase-gurple|sendnesis-blue|sendsquared-gray)',
    },
    activeColor: {
      type: String,
      default: 'sendsquared-gray',
      validator: (value) => {
        let acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
          'purple',
          'light-purple',
          'sendnesis-blue',
          'sendsquared-gray',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar active text color (primary|info|success|warning|danger|purple|light-purple|sendsquared-gray)',
    },
    logo: {
      type: String,
      default: '~img/logo/SendSquaredLogo-off-white.svg',
      description: 'SendSquared',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        'Sidebar links. Can be also provided as children components (sidebar-item)',
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sidebar: {},
    };
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    async initScrollBarAsync() {
      let isWindows = false;
      if (navigator.userAgentData) {
        try {
          const platformInfo =
            await navigator.userAgentData.getHighEntropyValues(['platform']);
          isWindows = platformInfo.platform === 'Windows';
        } catch (e) {
          console.error('Error determining platform:', e);
        }
      } else {
        isWindows = navigator.userAgent.includes('Win');
      }

      if (!isWindows) {
        return;
      }
      const PerfectScroll = await import('perfect-scrollbar');
      PerfectScroll.initialize(this.$refs.sidebarScrollArea);
    },
    hasPermission(item) {
      if (!item) {
        return true;
      }
      if (item.permission != undefined) {
        return this.getAcls(item.permission);
      }
      return true;
    },
    hasConfig(item) {
      if (!item) {
        return true;
      }
      if (item.appConfig != undefined) {
        switch (item.appConfig) {
          case 'owner:displaySidebar':
            return this.getAppConfig?.owner?.displaySidebar ? true : false;
        }
        // always default to hide those that need to be turned on by app config
        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapGetters('auth', ['getLoginAccount', 'getAcls']),
    ...mapGetters('appConfig', ['getAppConfig']),
  },
  created() {
    this.initScrollBarAsync();
    // Company ID
    const isAdminAccount = this.getLoginAccount?.companyId === 1;
    const adminLink = {
      icon: 'cog',
      name: 'Account Admin',
      path: '/admin/accounts',
    };
    this.sidebar = isAdminAccount
      ? [adminLink, ...this.sidebarLinks]
      : this.sidebarLinks;
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
.sidebar-wrapper .nav {
  padding-left: 0;
}
</style>
