<template>
 <div>
    <el-table :data="activeSegments" size="small" :row-class-name="segmentRowStatus">
      <el-table-column prop="segment_id" label="Segment" width="90"></el-table-column>
      <el-table-column prop="call_id" label="Call" width="90"></el-table-column>
      <el-table-column prop="segment_type" label="Type" width="90"></el-table-column>
      <el-table-column prop="created_at" label="Created" :formatter="cleanDate"></el-table-column>
      <el-table-column prop="segment_status" label="Status"></el-table-column>
      <el-table-column prop="duration" label="Duration" width="100"></el-table-column>
      <el-table-column prop="user_id" label="User"></el-table-column>
      <el-table-column prop="direction" label="Direction" align="right"></el-table-column>
      <el-table-column prop="is_muted" label="Muted" align="right"
        :formatter="formatMuted"
        ></el-table-column>
      <el-table-column prop="is_hold" label="On Hold" align="right"
        :formatter="formatHold"
        ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SegmentStatusList',
  computed: {
    ...mapGetters('realtime', ['getActiveSegments']),
  },
  props: {
  },
  data() {
    return {
      activeSegments: [],
      segmentStatuses: [],
      timer: undefined,
    };
  },
  watch: {
    // Copy the store values in so we are not updating the store
    // each second and that will cause lots of UI refreshes.
    getActiveSegments: {
      immediate: true,
      handler(newVals, oldvals) {
        this.activeSegments = [...newVals];
      },
    },
  },
  async created() {
    this.updateTime();
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    segmentRowStatus({ row, rowIndex }) {
      return row.segment_status;
    },
    updateTime() {
      const now = new Date();

      this.activeSegments.forEach((c) => {
        const duration = Math.round(now - new Date(c.modified_at));
        c.duration = new Date(duration).toISOString().substr(11, 8);
      });
      this.activeSegments = this.activeSegments.slice();
    },
    formatMuted(row) {
      return (row.is_muted ? 'Yes' : 'No');
    },
    formatHold(row) {
      return (row.is_hold ? 'Yes' : 'No');
    },
  },
};
</script>

<style>
.status-frame {
  margin-top: 10px;
  margin-right: 15px;
}
.el-table .call {
  background: rgb(245, 245, 245);
}
.el-table .answered {
  background: rgb(218, 236, 218);
}
.el-table .queued {
  background: rgb(245, 232, 232);
}
</style>
