<template>
  <span>{{ displayText }}</span>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AgentFieldView',
  props: {
    agentId: {
      type: Number,
      required: false,
    },
    display: { type: Array },
  },
  setup(props) {
    const store = useStore();

    const displayColumn = (agentId, col) => {
      if (agentId) {
      const agent = store.getters['agents/getAgent'](agentId);
      return agent ? agent[col] : 'Unknown';
      }
      else {
        return 'Unknown';
      }
    };

    const displayText = computed(() => {
      return props.display
        .map((col) => displayColumn(props.agentId, col))
        .join(' ');
    });

    onMounted(async () => {
      if (
        !store.getters['agents/getAgents'] ||
        store.getters['agents/getAgents'].length === 0
      ) {
        await store.dispatch('agents/fetchAgents');
      }
    });

    return {
      displayText,
    };
  },
};
</script>
