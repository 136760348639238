import phoneNumberService from '../../services/phoneNumberService';

const state = {
  phoneNumbers: undefined,
};

const getters = {
  getPhoneNumbers: state => {
    return state.phoneNumbers;
  },
  getTextNumbers: state => {
    return state.phoneNumbers ? state.phoneNumbers.filter(p => p.is_sms || p.is_mms || p.is_short_code) : [];
  },
  getWhatsappNumbers: state => {
    return state.phoneNumbers ? state.phoneNumbers.filter(p => p.whatsapp_id != undefined ) : [];
  },
  // For now its just all available numbers.
  // Eventually user based permissions
  // and then external phone numbers
  getCallerIds: state => {
    return state.phoneNumbers ? state.phoneNumbers.filter(p => p.is_voice && p.is_caller_id) : [];
  }
};
const actions = {
  fetchPhoneNumbers({ commit }) {
    if (state.phoneNumbers == undefined) {
      return phoneNumberService.fetchPhoneNumbers()
        .then(r => {
          commit('setPhoneNumbers', r.data);
        });
    }
  },
  updatePhoneNumber({ commit }, phoneModel) {
    commit('updatePhoneNumber', phoneModel);
  },
  unloadPhoneNumbers({ commit }) {
    commit('setPhoneNumbers', undefined);
  },
};
const mutations = {
  setPhoneNumbers(state, d) {
    state.phoneNumbers = d;
  },
  updatePhoneNumber(state, data) {
    let org = state.phoneNumbers.find(p => p.id == data.id);
    if (org) {
      org = Object.assign(org, data);
    }
    else {
      state.phoneNumbers.push(data);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
