<template>
  <el-select
    v-model="selectedId"
    @change="do_change"
    :placeholder="placeholder"
    :size="size"
    filterable
  >
    <el-option v-if="allowAll" :value="0" :label="'Any Status'"></el-option>
    <el-option
      v-for="option in leadStatusesByLeadType"
      :value="option.id"
      :key="option.id"
      :label="option.label"
    >
      <!-- TODO add label color -->
    </el-option>
    <el-option
      v-if="!isSelectedIdValid && !allowAll"
      :value="selectedId"
      :label="'Unknown Status'"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'leadStatusSelect',
  computed: {
    ...mapGetters('leadStatus', ['getLeadStatuses']),
    leadStatusesByLeadType() {
      return this.getLeadStatuses.filter(
        (s) => !s.lead_type_id || s.lead_type_id === this.selectedLeadType
      ).toSorted((a, b) => a.display_order - b.display_order);
    },
    isSelectedIdValid() {
      return this.leadStatusesByLeadType.some(
        (status) => status.id === this.selectedId
      );
    },
  },
  props: {
    // Add v-model support
    value: {
      type: [Number, String],
    },
    initialId: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      default: () => {
        return 'small';
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return 'Select a Lead Status';
      },
    },
    allowAll: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    selectedLeadType: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      selectedId: undefined,
    };
  },
  watch: {
    initialId(id) {
      this.selectedId = id;
    },
    value(n) {
      this.selectedId = parseInt(n);
    },
  },
  async created() {
    if (!this.getLeadStatuses || this.getLeadStatuses.length == 0) {
      await this.fetchLeadStatuses();
    }
    if (this.value != undefined) {
      this.selectedId = parseInt(this.value);
    }
    if (this.initialId) {
      this.selectedId = this.initialId;
    }
  },
  methods: {
    ...mapActions('leadStatus', ['fetchLeadStatuses']),
    do_change() {
      const leadStatus = this.getLeadStatuses.find(
        (s) => s.id == this.selectedId
      );
      this.$emit('update:leadStatus', leadStatus);
      if (leadStatus) {
        this.$emit('update:modelValue', leadStatus.id);
      } else {
        // Because we parse from a string at times 0 instead of undefined
        this.$emit('update:modelValue', 0);
      }
    },
  },
};
</script>
