<template>
    <span v-if="!editable">{{getDirectDialName()}}</span>
    <el-dropdown trigger="click" @command="toggleValue"
        v-if="useMenu && editable" ref="menuDrop" >
      <span class="el-dropdown-link">
      </span>

      <template #dropdown>
        <el-dropdown-menu v-model="selected" slot="dropdown" >
            <el-dropdown-item
              v-for="dial in getDirectDials"
              v-bind:key="dial.id"
              :command="dial.id"
              >{{directDial.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-select label="Select Number" v-model="selected" @change="toggleValue()"
      v-if="!useMenu && editable">
        <el-option
            v-for="dial in getDirectDials"
            v-bind:key="dial.id"
          :value="dial.id"
          :label="dial.name"
        ></el-option>
    </el-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'direct-dial-select',
  props: {
    value: {
      type: String,
      default: undefined
    },
    reload: {
      type: Boolean,
      default: false,
      description: 'Reload the Direct Dial list on instantiation',
    },
    useMenu: {
      type: Boolean,
      default: false,
      description: 'Use a Menu dropdown vs a list dropdown',
    },
    editable: {
      type: Boolean,
      default: true,
      description: 'Allow user input to change value',
    },
  },
  data() {
    return {
      selected: undefined,
    };
  },
  computed: {
    ...mapGetters('voice', ['getDirectDials']),
  },
  async mounted() {
    // Force it open on show
    if (this.useMenu && this.$refs.menuDrop) {
      this.$refs.menuDrop.dropdown.show();
    }
  },
  async created() {
    if (this.reload || this.getDirectDials == undefined) {
      await this.fetchDirectDials();
    }
    if (this.value) {
      this.selected = parseInt(this.value);
    }
  },
  methods: {
    ...mapActions('voice', ['fetchDirectDials']),
    toggleValue(value) {
      // Set internal when using menu
      if (this.useMenu) {
        this.selected = value;
      }
      this.isOpen = !this.isOpen;
      this.$emit('update:value', String(this.selected));
      // update:modelValue replaces Input event in vue3.
      // this.$emit('update:modelValue',  String(this.selected));
    },
    getDirectDialName() {
      if (this.value == undefined) {
        return 'Unknown';
      }
      const dd = this.getDirectDials.find(d => d.id == this.value);
      return dd ? dd.name : 'Invalid';
    },
  },
};
</script>
