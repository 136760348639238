
const SurveyList = () =>
  import(/* webpackChunkName: "SurveyList" */ 'src/components/Dashboard/Views/Surveys/SurveyList.vue');

const SurveyEdit = () =>
  import(/* webpackChunkName: "SurveyEdit" */ 'src/components/Dashboard/Views/Surveys/SurveyEdit.vue');
const SurveyReport = () =>
  import(/* webpackChunkName: "SurveyEdit" */ 'src/components/Dashboard/Views/Surveys/SurveyReport.vue');
export const surveyRoutes = [
  {
    path: 'surveys/add/',
    name: 'Create Survey',
    component: SurveyEdit,
  },

  {
    path: 'surveys/edit/:id',
    name: 'Edit Survey',
    component: SurveyEdit,
  },
  {
    path: 'surveys/list/',
    name: 'List Surveys',
    component: SurveyList,
  },
  {
    path: 'surveys/report/:id',
    name: 'Survey Report',
    component: SurveyReport,
  }
];
