<template>
  <div class="control number">
    <div v-if="label !== undefined" class="label">{{label}}</div>
    <button
      type="button"
      class="decrement-button"
      :disabled="decrementDisabled"
      @click="decrement"
    >-</button>
    <input
      type="number"
      :disabled="inputDisabled"
      :min="min"
      :max="max"
      :step="step"
      v-model.number="currentValue"
      @blur="_updateValue(currentValue)"
      @keydown.esc="currentValue = value"
      @keydown.enter="_updateValue(currentValue)"
      @keydown.up.prevent="increment"
      @keydown.down.prevent="decrement"
    />
    <button
      type="button"
      class="increment-button"
      :disabled="incrementDisabled"
      @click="increment"
    >+</button>
  </div>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: -Infinity
    },
    value: {
      required: false,
      type: Number,
      default: 0
    },
    modelValue: {
      required: false,
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    label: {
      type: String,
      default: undefined
    }
  },
  emits: ["update:modelValue", "change"],

  data() {
    return {
      currentValue: this.value,
      decrementDisabled: false,
      incrementDisabled: false,
      inputDisabled: false
    };
  },

  watch: {
    value(val) {
      this.currentValue = val;
    },
    currentValue(val) {
      this.$emit('change', val);
      this.$emit('update:modelValue', val);
    }
  },

  methods: {
    increment() {
      if (this.disabled || this.incrementDisabled) {
        return;
      }

      let newVal = this.currentValue + 1 * this.step;
      this.decrementDisabled = false;

      this._updateValue(newVal);
    },
    decrement() {
      if (this.disabled || this.decrementDisabled) {
        return;
      }

      let newVal = this.currentValue + -1 * this.step;
      this.incrementDisabled = false;

      this._updateValue(newVal);
    },
    _updateValue(newVal) {
      const oldVal = this.currentValue;

      if (oldVal === newVal || typeof this.value !== 'number') {
        return;
      }
      if (newVal <= this.min) {
        newVal = this.min;
        this.decrementDisabled = true;
      }
      if (newVal >= this.max) {
        newVal = this.max;
        this.incrementDisabled = true;
      }
      this.currentValue = newVal;
      // this.$emit('change', this.currentValue);
    }
  },

  mounted() {
    if (this.value == this.min) {
      this.decrementDisabled = true;
    }
  }
};
</script>
<style lang="scss" scoped>
// Colors
$link-color: #0288d1;
$gray-light: #e9ecef;
$gray-medium: #adb5bd;
$gray-dark: #343d46;
$bg-color: #afccff;

.control.number {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  height: 25px;

  .label {
    text-align: center;
  }
  input {
    border: none;
    border-radius: 3px;
    font-size: 14px;
    line-height: 16px;
    height: auto;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 100%;
    vertical-align: top;
    -moz-appearance: textfield;
    background: rgb(237, 239, 242);
    border-top: 1px solid rgb(229, 232, 236);
    border-bottom: 1px solid rgb(229, 232, 236);

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // &:focus {
    //   outline: 0;
    //   box-shadow: 0 0 0 0.2rem $link-color;
    // }
  }

  button {
    color: $gray-dark;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 7px;

    text-align: center;
    width: 1.5rem;
    height: auto;
    user-select: none;
    margin: 0 auto;
    display: block;

    padding: 7px, 1px, 7px, 1px;

    &:hover,
    &:active,
    &:focus {
      border-color: $link-color;
      color: $link-color;
      outline: none;
    }

    &:hover {
      & ~ .input {
        border: 1px solid $link-color;
      }
    }
    &:active,
    &:focus {
      & ~ .input {
        border: 0;
        box-shadow: 0 0 0 0.2rem $link-color;
      }
    }
    &:disabled,
    &.is-disabled {
      color: $gray-medium;
      opacity: 1;
    }

    $border-amount: 15px;
    &:nth-child(1) {
      -webkit-border-top-left-radius: $border-amount;
      -webkit-border-bottom-left-radius: $border-amount;
      -moz-border-radius-topleft: $border-amount;
      -moz-border-radius-bottomleft: $border-amount;
      border-top-left-radius: $border-amount;
      border-bottom-left-radius: $border-amount;
      border: 1px solid rgb(229, 232, 236);
    }
    &:nth-child(3) {
      -webkit-border-top-right-radius: $border-amount;
      -webkit-border-bottom-right-radius: $border-amount;
      -moz-border-radius-topright: $border-amount;
      -moz-border-radius-bottomright: $border-amount;
      border-top-right-radius: $border-amount;
      border-bottom-right-radius: $border-amount;
      border: 1px solid rgb(229, 232, 236);
    }
  }

  .increment-button {
    border: none;
  }

  .decrement-button {
    border: none;
  }
}

p {
  font-family: Impact, sans-serif;
  font-size: 2em;
  margin-bottom: 0.5em;
  text-align: center;
}
</style>
