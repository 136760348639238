<template>
  <nav :class="classes" class="navbar navbar-expand-lg" style="align-items: start;">
    <div class="container-fluid" style="align-items: start;">
      <slot></slot>
      <button aria-controls="navigation-index"
              @click="toggleMenu"
              aria-expanded="true" aria-label="Toggle navigation"
              class="navbar-toggler" data-toggle="collapse" type="button"><span
        class="navbar-toggler-bar navbar-kebab"></span><span class="navbar-toggler-bar navbar-kebab"></span><span
        class="navbar-toggler-bar navbar-kebab"></span></button>
      <div class="collapse navbar-collapse justify-content-end show"
          :class="navbarMenuClasses"
          v-show="showNavbar"
          id="navigation">
        <ul class="navbar-nav">
          <slot name="navbar-menu"></slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>

  export default {
    name: 'navbar',
    props: {
      showNavbar: {
        type: Boolean,
      },
      navbarMenuClasses: {
        type: [String, Object],
      },
      transparent: {
        type: Boolean,
        default: true,
      },
      position: {
        type: String,
        default: 'absolute',
      },
      type: {
        type: String,
        default: 'white',
        validator(value) {
          return ['white', 'default', 'primary', 'danger', 'success', 'warning', 'info'].includes(value);
        },
      }
    },
    model: {
      prop: 'showNavbar',
      event: 'change'
    },
    emits: ['update:showNavbar'],
    computed: {
      classes() {
        let color = `bg-${this.type}`;
        let navPosition = `navbar-${this.position}`;
        return [
          { 'navbar-transparent': !this.showNavbar && this.transparent },
          { [color]: this.showNavbar || !this.transparent },
          navPosition]
      }
    },
    methods: {
      toggleMenu() {
        this.$emit('change', !this.showNavbar);
      }
    }
  }
</script>
<style scoped>
  .navbar-relative {
    position: relative;
  }
</style>
