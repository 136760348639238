<template>
  <el-dropdown class="agent-status-frame">
    <span class="el-dropdown-link">
      <span class="agent-status-icon">{{ statusIcon }}</span>
      <span class="agent-status-text">{{ statusLabel }}</span>
      <font-awesome-icon icon="chevron-down" style="margin-left: 3px" />
    </span>
    <template #dropdown>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(userStatus, index) in userStatuses"
          :key="index"
          class="dropdown-item"
          @click.native="changeStatus(userStatus)"
        >
          <span style="margin-right: 0.8rem">{{ userStatus.icon }}</span>
          {{ userStatus.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <span v-if="currentAgentStatus.pending_status">
    Pending: {{ currentAgentStatus.pending_status }}</span
  >
</template>
<script>
import { mapGetters } from 'vuex';
import AgentService from 'src/services/agentService';

const WARNING_AUDIO_URL =
  'https://adbase-static.s3.amazonaws.com/sounds/alert_warm_soft_complete_success.mp3';

export default {
  name: 'AgentStatus',
  computed: {
    ...mapGetters('agents', ['getAgentStatuses']),
    ...mapGetters('realtime', ['getMyAgentStatus']),
    ...mapGetters('appConfig', ['getAppConfig']),
    currentAgentStatus() {
      return this.getMyAgentStatus(this.userId);
    },
  },
  props: {
    userId: Number,
  },
  data() {
    return {
      userStatuses: [],
      status: undefined,
      statusLabel: 'Unknown',
      statusIcon: undefined,
      statusInterval: undefined,
      statusAudio: undefined,
    };
  },
  watch: {
    currentAgentStatus: {
      immediate: true,
      deep: true,
      handler(newStatus) {
        if (newStatus.user_id != this.userId) {
          return;
        }
        // This happens as the watch fires on any status change for any user
        // But we only want to update on actual status changes
        if (newStatus.status === this.status) {
          return;
        }
        this.updateStatus(newStatus);
      },
    },
  },
  async created() {
    this.userStatuses = this.getAgentStatuses.filter(
      (s) => s.user_assignable == true
    );
    this.statusAudio = new Audio(WARNING_AUDIO_URL);
  },
  async beforeUnmount() {
    if (this.statusInterval != undefined) {
      clearInterval(this.statusInterval);
      this.statusInterval = undefined;
      if (this.statusAudio) {
        this.statusAudio.pause();
      }
    }
  },
  methods: {
    async changeStatus(value) {
      try {
        await AgentService.setStatus(value.status);
      } catch (e) {
        if (e.response.status == 500) {
          // Assume its a status change error
          this.$message({
            type: 'error',
            message: 'Can not change your status while you are Busy',
            showClose: true,
          });
          return false;
        }
        this.$message({
          type: 'error',
          message: 'Error changing status: ' + e.response.data.message,
          showClose: true,
        });
        return false;
      }
    },
    updateStatus(newStatus) {
      const status = this.getAgentStatuses.find(
        (s) => s.status == newStatus.status
      );
      if (status) {
        this.status = status.status;
        this.statusLabel = status.name;
        this.statusIcon = status.icon;
      } else {
        this.status = undefined;
        this.statusLabel = 'Unknown';
        this.statusIcon = undefined;
      }
      this.doWarningCheck(status);
    },
    doWarningCheck(newStatus) {
      // Clear previous interval on any status change
      if (this.statusInterval != undefined) {
        clearInterval(this.statusInterval);
        this.statusInterval = undefined;
        if (this.statusAudio) {
          this.statusAudio.pause();
        }
      }

      // Set new only as needed
      if (newStatus.warning_time > 0) {
        this.statusInterval = setInterval(() => {
          this.statusAudio.currentTime = 0;
          this.statusAudio.play();
          this.$message({
            message: `You have been in ${newStatus.name} too long`,
            type: 'warning',
            showClose: true,
            onClose: () => {
              this.statusAudio.pause();
            },
          });
        }, newStatus.warning_time * 1000);
      } else {
        // console.log('clearing interval', this.status, this.statusInterval);
        // Handler might fire before created is called
        if (this.statusInterval) {
          clearInterval(this.statusInterval);
          this.statusInterval = undefined;
        }
      }
    },
  },
};
</script>
<style>
.agent-status-frame {
  margin-top: 13px;
  margin-right: 15px;
  &:focus-visible {
    outline: none;
  }
  .el-dropdown-link {
    &:focus-visible {
      outline: none;
    }
  }

  .agent-status-icon {
    margin-right: 4px;
  }
  .agent-status-text {
  }

  @media (max-width: 999px) {
    .agent-status-icon {
      margin-right: none;
    }
    .agent-status-text {
      display: none;
    }
  }
}
</style>
