<template>
  <div
    class="call-button-wrapper"
    style="display: flex; flex-direction: column"
  >
    <div style="display: flex; justify-content: center">
      <div
        class="call-button-circle"
        style="display: flex; justify-content: center; align-items: center"
      >
        <img
          v-if="buttons[icon] && buttons[icon].icon"
          :src="getSvgUrl(buttons[icon].icon)"
          @click="processClick(icon)"
        />
      </div>
    </div>
    <div
      class="call-button-text"
      style="display: flex; justify-content: center"
    >
      {{ buttons[icon].text }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'active-call-dialog-button',
  components: {},
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttons: {
        holdOn: { icon: 'pause-on.svg', text: 'Un-Hold' },
        holdOff: { icon: 'pause-off.svg', text: 'Hold' },
        muteOn: { icon: 'microphone-off.svg', text: 'un-Mute' },
        muteOff: { icon: 'microphone-on.svg', text: 'Mute' },
        transfer: { icon: 'transfer.svg', text: 'Trans' },
        park: { icon: 'park.svg', text: 'Park' },
        recordOn: { icon: 'record-on.svg', text: 'Pause' },
        recordOff: { icon: 'record-off.svg', text: 'Record' },
        keypad: { icon: 'keypad.svg', text: 'Keypad' },
        end: { icon: 'end.svg', text: 'End' },
        segmentControl: { icon: 'segment.svg', text: 'Segments' },
        callControl: { icon: 'keypad.svg', text: 'DialPad' },
      },
      showTransfer: false,
    };
  },
  methods: {
    getSvgUrl(pic) {
      return require('../../../../public/img/call-svg/' + pic);
    },
    processClick(action) {
      this.$emit('action', { type: action, value: null });
    },
  },
};
</script>
<style lang="scss" scoped>
.call-button-wrapper {
  cursor: pointer;
  .call-button-circle {
    width: 30px;
    height: 30px;
    border: #0b5048 1px solid;
    border-radius: 15px;
    cursor: pointer;
    img {
      // Adjust size?
    }
  }
  .call-button-text {
    text-align: center;
    font-size: 10px;
    cursor: pointer;
  }
}
</style>
