<template>
  <div>
    <p>
      Use the form below to set the basic &amp; global settings for this
      template.
    </p>
    <el-form :inline="false" label-width="130px" size="small">
      <el-form-item v-if="EditorType === 'landing'" label="Publish Page">
        <el-switch
          v-model="appearanceForm.background.publish"
          @change="transportProperties('pagePublish')"
        ></el-switch>
      </el-form-item>

      <el-form-item v-if="EditorType === 'landing'" label="Page Slug">
        <el-input
          type="text"
          v-model="appearanceForm.background.publishSlug"
          @change="transportProperties('pagePublish')"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="EditorType === 'landing'" label="Page Title">
        <el-input
          type="text"
          v-model="appearanceForm.background.publishTitle"
          @change="transportProperties('pagePublish')"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="EditorType === 'landing'" label="Page Description">
        <el-input
          type="textarea"
          v-model="appearanceForm.background.publishDescription"
          @change="transportProperties('pagePublish')"
        ></el-input>
      </el-form-item>

      <el-form-item label="Default Font">
        <editor-font-selection
          v-model="appearanceForm.background.font"
          :transport="'background.font'"
        ></editor-font-selection>
      </el-form-item>

      <el-form-item label="Background Color">
        <color-picker
          v-model="appearanceForm.background.color"
          @change="transportProperties('backgroundColor')"
          :frame-position="'left'"
        ></color-picker>
      </el-form-item>

      <el-form-item label="Content Color">
        <color-picker
          v-model="appearanceForm.background.contentColor"
          @change="transportProperties('backgroundContentColor')"
          :frame-position="'left'"
        ></color-picker>
      </el-form-item>

      <el-form-item v-if="EditorType === 'popup'" label="Popup Width">
        <el-input-number
          v-model="appearanceForm.background.width"
          @change="transportProperties('backgroundWidth')"
          :precision="0"
          :min="400"
          :step="20"
          :max="800"
          size="small"
        ></el-input-number>
      </el-form-item>

      <el-form-item v-if="EditorType === 'email'" label="Email Width">
        <el-input-number
          v-model="appearanceForm.background.width"
          @change="transportProperties('backgroundWidth')"
          :precision="0"
          :min="400"
          :step="20"
          :max="800"
          size="small"
        ></el-input-number>
      </el-form-item>

      <el-form-item v-if="EditorType === 'landing'" label="Page Width">
        <el-input-number
          v-model="appearanceForm.background.width"
          @change="transportProperties('backgroundWidth')"
          :precision="0"
          :min="300"
          :step="20"
          :max="1800"
          size="small"
        ></el-input-number>
      </el-form-item>

      <el-form-item v-if="EditorType === 'email'" label="Dark Mode">
        <el-switch
          v-model="appearanceForm.background.darkMode"
          @change="transportProperties('backgroundDarkMode')"
        ></el-switch>
      </el-form-item>

      <el-form-item v-if="EditorType === 'email'" label="Advanced Editor">
        <el-switch
          v-model="appearanceForm.background.advancedEditor"
          @change="transportProperties('backgroundAdvancedEditor')"
        ></el-switch>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import EditorFontSelection from 'src/components/Dashboard/Views/Templates/Editor/EditorFontSelection';
import EditorColor from 'src/components/Dashboard/Views/Templates/Editor/EditorColor';
import EditorAlignSelection from 'src/components/Dashboard/Views/Templates/Editor/EditorAlignSelection';
import NumberEditor from 'src/components/Dashboard/Views/Templates/Editor/NumberEditor';
import ColorPicker from 'src/components/Dashboard/Views/Templates/Editor/ColorPicker';

export default {
  components: {
    EditorFontSelection,
    EditorColor,
    EditorAlignSelection,
    NumberEditor,
    ColorPicker,
  },
  computed: {
    ...mapGetters('templates', ['getTemplate']),
    ...mapGetters('editor', [
      'getElementsByType',
      'getControlPaneShow',
      'getEditorName',
      'getEditorSubject',
      'getEditorPreview',
      'getSelectedBlockExists',
      'getDecodedSelectedBlock',
      'getBlockByIndexColumn',
      'getBackgroundColor',
      'getContentColor',
      'getSelectedBlockType',
    ]),
    slugChange() {
      return this.appearanceForm.background.publishSlug;
    },
  },
  props: {
    EditorType: {
      type: String,
      default: () => {
        return 'email';
      },
    },
  },
  data() {
    return {
      // send dialog
      appearanceForm: {
        // Background
        background: {
          font: 'Roboto',
          type: 'color',
          color: undefined,
          width: 600,
          darkMode: false,
          advancedEditor: false,
          contentColor: undefined,

          // Landing Publish
          publish: false,
          publishSlug: '',
          publishTitle: '',
          publishDescription: '',

          // Modal add-in
          modalWidth: 320,
          modalEnableLeft: false,
          modalEnableRight: false,
          modalWidthLeft: 0,
          modalWidthRight: 0,
          modalGroupId: 0,
          modalWaitTime: 4,
          modalFormatType: 'basic',
          modalStrategy: 'onscroll',
          interestOnComplete: false,
        },
      },
    };
  },
  created() {
    this.onLoad();
  },
  watch: {
    toggleDialog() {
      this.onLoad();
      this.showDialog = !this.showDialog;
    },
  },
  methods: {
    ...mapActions('editor', ['transportBlockSettings', 'selectBlock']),
    slugify(text) {
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
    },
    transportProperties(method) {
      let transport = {};
      switch (method) {
        case 'pagePublish':
          transport = {
            useBackground: true,
            publish: String(this.appearanceForm.background.publish),
            publishSlug: this.slugify(
              String(this.appearanceForm.background.publishSlug)
            ),
            publishTitle: String(this.appearanceForm.background.publishTitle),
            publishDescription: String(
              this.appearanceForm.background.publishDescription
            ),
          };
          break;
        case 'backgroundContentColor':
          transport = {
            useBackground: true,
            contentColor: this.appearanceForm.background.contentColor,
          };
          break;
        case 'backgroundColor':
          transport = {
            useBackground: true,
            background: this.appearanceForm.background.color,
          };
          break;
        case 'backgroundWidth':
          transport = {
            useBackground: true,
            width: this.appearanceForm.background.width,
          };
          break;
        case 'backgroundDarkMode':
          transport = {
            useBackground: true,
            mutateDarkMode: true,
            darkMode: this.appearanceForm.background.darkMode,
          };
          break;
        case 'backgroundAdvancedEditor':
          transport = {
            useBackground: true,
            mutateAdvancedEditor: true,
            advancedEditor: this.appearanceForm.background.advancedEditor,
          };
          break;
        case 'background':
          transport = {
            background: this.appearanceForm.background.color,
          };
          break;
      }
      this.transportBlockSettings({
        section: 'background',
        row: 1,
        column: 1,
        data: transport,
      });
    },
    onLoad() {
      const block = this.getBlockByIndexColumn('background', 1, 1);

      // Email settings
      if (block.font) this.appearanceForm.background.font = String(block.font);
      this.appearanceForm.background.width = block.width;
      this.appearanceForm.background.color = block.background;
      this.appearanceForm.background.contentColor = block.contentBackground;
      this.appearanceForm.background.darkMode = block.darkMode;

      // Landing Settings
      this.appearanceForm.background.publish =
        block.publish === 'true' ? true : false;
      this.appearanceForm.background.publishSlug = block.publishSlug;
      this.appearanceForm.background.publishTitle = block.publishTitle;
      this.appearanceForm.background.publishDescription =
        block.publishDescription;

      // Modal Settings
      this.appearanceForm.background.modalWidth = block.modalWidth;
      this.appearanceForm.background.modalEnableLeft = block.modalEnableLeft;
      this.appearanceForm.background.modalEnableRight = block.modalEnableRight;
      this.appearanceForm.background.modalWidthLeft = block.modalWidthLeft;
      this.appearanceForm.background.modalWidthRight = block.modalWidthRight;

      this.appearanceForm.background.modalGroupId = block.modalGroupId;
      this.appearanceForm.background.modalWaitTime = block.modalWaitTime;
      this.appearanceForm.background.modalFormatType = block.modalFormatType; // Format Type will hold
      this.appearanceForm.background.modalStrategy = block.modalStrategy;
      this.appearanceForm.background.advancedEditor = block.advancedEditor;

      this.documentProperties = true;
    },
  },
};
</script>
