<template>
  <el-select
    v-model="selectedValues"
    @change="setBrands"
    :placeholder="'Select A Brand'"
    :multiple="true"
    :style="style"
    :collapse-tags="true"
    :size="size"
    value-key="id"
  >
    <el-option :key="-1" :value="-1" :label="'No Brands'"></el-option>
    <el-option
      v-for="option in getBrands"
      :key="option.id"
      :value="option.id"
      :label="option.name"
    ></el-option>
  </el-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'GlobalBrandSelect',
  props: {
    style: {
      type: String,
      default: ()=> 'width: 240px;'
    },
    size: {
      type: String,
      default: ()=> 'default'
    },
  },
  components: {},
  computed: {
    ...mapGetters('brands', ['getBrands']),
    ...mapGetters('auth', ['getGlobalBrandIds']),
  },
  created() {
    this.selectedValues = this.getGlobalBrandIds ?? [];
  },
  // doesn't appear to be used... commenting out for now.
  /* watch: {
    selectedValues: {
      deep: true,
      handler(vals) {
        return true;
        if (vals && vals.includes(-1)) {
          vals = [-1];
        } else if (vals && vals.length == 0) {
          vals = undefined;
        }
        this.setGlobalBrandIds(vals);
      },
    },
  }, */
  data() {
    return {
      selectedValues: [],
      multi: true,
      // brandId: undefined,
      // If not set no filter. Versus set and empty which means none
      // brandIds: undefined,
    };
  },
  methods: {
    ...mapActions('auth', ['setGlobalBrandIds']),
    setBrands(vals) {
      // console.log('we hit smething', vals);
      if (vals && vals.includes(-1)) {
        // Filter all brands, show nothing.
        // We need -1 so it loads correctly on the display, but empty set is technically what we have
        vals = [-1];
      } else if (vals && vals.length == 0) {
        // Undefined means no filter, show all
        vals = undefined;
      }
      // console.log('we setting brands to this', vals);
      this.selectedValues = vals;
      this.setGlobalBrandIds(vals);
    },
  },
};
</script>
