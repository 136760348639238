<template>
  <el-select
    v-model="internalId"
    :size="size"
    :placeholder="placeholder"
    @change="updateFrom"
  >
    <el-option
      v-for="option in numbers"
      :value="option.id"
      :label="option.display_label"
      :key="option.id"
    ></el-option>
  </el-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'phoneNumberSelect',
  computed: {
    ...mapGetters('phoneNumbers', ['getPhoneNumbers']),
  },
  props: {
    fromId: { type: Number, default: () => undefined },
    brandId: { type: Number, default: () => undefined },
    placeholder: {
      type: String,
      default: () => 'Please Select a Phone Number',
    },
    size: { type: String, default: () => 'small' },
    showNumber: { type: Boolean },
    // Restricts the numbers allowed to this set
    allowedNumbers: {
      type: Array,
    },
  },
  data() {
    return {
      numbers: [],
      internalId: undefined,
    };
  },
  watch: {
    fromId(n) {
      if (n == undefined) {
        this.internalId = undefined;
        return;
      }
      // Dont mix twilio and bandwidth as they can collide
      let num = this.numbers.find((p) => p.id == n);
      this.internalId = num ? num.id : undefined;
    },
    brandId(n) {
      this.brandId = n;
      this.load();
      this.internalId = this.numbers.length ? this.numbers[0].id : undefined;
    },
    allowedNumbers(n) {
      this.load();
      this.internalId = this.numbers.length ? this.numbers[0].id : undefined;
    },
  },
  created() {
    this.load();
    if (this.fromId) {
      let num = this.numbers.find((p) => p.id == this.fromId);
      // Possible we have no numbers for a brand
      this.internalId = num ? num.id : undefined;
    }
  },
  methods: {
    ...mapActions('phoneNumbers', ['fetchPhoneNumbers']),
    async load() {
      if (!this.getPhoneNumbers) {
        await this.fetchPhoneNumbers();
      }
      this.numbers = [];
      let i = 1;
      // TODO: Why dont we filter types as a prop
      for (const p of this.getPhoneNumbers.filter(
        (p) => p.is_sms || p.is_mms || p.whatsapp_id
      )) {
        if (this.brandId && p.brand_id != this.brandId) {
          continue;
        }
        if (
          this.allowedNumbers != undefined &&
          !this.allowedNumbers.includes(p.id)
        ) {
          continue;
        }
        let displayLabel = p.label;
        if (this.showNumber) {
          displayLabel += ' ';
          displayLabel += this.formatPhoneNumber(p.phone_number);
        }
        this.numbers.push({
          display_label: displayLabel,
          // this.formatPhoneNumber(p.phone_number),
          id: p.id,
          phone: p.phone_number,
        });
        i++;
      }
    },
    updateFrom() {
      const foundNumber = this.numbers.find((s) => s.id == this.internalId);
      this.$emit('update:from', {
        fromId: foundNumber.id,
        fromName: foundNumber.display_label,
      });
    },
    // Force an update when the watch wont fire because its the same id
    setInternal(id) {
      this.internalId = id;
    },
  },
};
</script>
