<template>
  <el-card>
    <template #header>
      <div class="card-header">
        <el-row justify="space-between" align="middle">
          <el-col :span="20">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item to="/admin/settings-overview">
                Call Disposition
              </el-breadcrumb-item>
              <!-- <el-breadcrumb-item>
                Current Caller Name || Phone Number
              </el-breadcrumb-item> -->
              <el-breadcrumb-item>
                <el-link
                  @click.prevent="toggleContactChange"
                  style="cursor: pointer; margin-top: -2px"
                  >Change Contact</el-link
                >
                <el-divider direction="vertical"></el-divider>
                <el-link
                  :underline="true"
                  :type="'primary'"
                  @click.prevent="viewContact"
                  style="cursor: pointer; margin-top: -2px"
                  >Go To Contact</el-link
                >
              </el-breadcrumb-item>
            </el-breadcrumb></el-col
          >

          <el-col :span="4" align="right">
            <!-- Put something here... -->
          </el-col>
        </el-row>
      </div>
    </template>

    <div class="disposition">
      <div class="dispositionData">
        <div>
          <!-- Spotlight double loaded?  -->
          <s2-spotlight
            :toggle-open="changeContact"
            :do-redirect="false"
            v-on:update:contactId="updateContact"
          ></s2-spotlight>
          <div style="padding-bottom: 30px"></div>

          <contact-edit-card
            v-if="contactId"
            :contact-id="contactId"
          ></contact-edit-card>
          <!-- We want to show this lead form to any active calls
               or to any contacts which are in wrap up. -->
          <span v-if="useCallResult == false">
            <el-divider>Call Type</el-divider>
            <div style="display: flex; justify-content: center">
              <call-result-select
                :initial-id="callResult ? callResult.id : undefined"
                v-on:change="changeCallResult"
              ></call-result-select>
            </div>
          </span>
          <el-divider
            v-if="
              callResult &&
              (callResult.lead_presented || callResult.lead_required)
            "
            >Lead</el-divider
          >
          <lead
            v-if="
              callResult &&
              (callResult.lead_presented || callResult.lead_required)
            "
            :lead-id="leadId"
            :contact-id="contactId"
            :sourceNumber="sourceNumber"
            v-on:update:dirty="leadDirty = $event"
            v-on:update:valid="leadValid = $event"
            v-on:update:lead="updateLead"
            v-on:update:contactId="updateContact"
            :showClose="false"
            :livePatch="true"
          ></lead>
          <span v-if="useCallResult == true">
            <el-divider>Call Result</el-divider>
            <div style="display: flex; justify-content: center">
              <call-result-select
                :initial-id="callResult ? callResult.id : undefined"
                v-on:change="changeCallResult"
                style="width: 350px"
              ></call-result-select>
            </div>
          </span>
          <div style="text-align: center; margin-top: 1.5rem">
            <el-alert
              v-if="callResult && callResult.lead_required && !leadValid"
              title="This call result requires a lead before you can complete this call"
              type="error"
            />
            <el-button
              @click.prevent="completeCall()"
              size="small"
              type="primary"
              :disabled="
                !callResult ||
                (callResult.lead_required && (leadDirty || !leadValid))
              "
            >
              Complete Call
            </el-button>
          </div>
        </div>
      </div>
      <div class="otherData">
        <p v-if="!callId">Loading...</p>
        <div v-if="callId">
          <p>Caller Info</p>
          <el-row>
            <el-col :span="12"
              ><small>{{ sourceName }}:</small></el-col
            >
            <el-col :span="12" class="text-right"
              ><small>{{ formatPhoneNumber(sourceNumber) }}</small></el-col
            >
          </el-row>
          <!--
          Eventually but we need first queue and last queue potentially
        <el-row>
          <el-col :span="12"><small>Queue:</small></el-col>
          <el-col :span="12" class="text-right"><small>{{queueName}}</small></el-col>
        </el-row>
        --></div>
        <br />
        <call-data-reservation
          v-if="callId"
          :call-id="callId"
        ></call-data-reservation>
        <br />
        <el-collapse v-if="callId" @change="toggleTab" v-model="activeTabs">
          <el-collapse-item title="Sms Opt Ins" name="smsOpt">
            <div v-if="contactData" justify="center">
              <div v-for="n in getTextNumbers">
                {{ formatPhoneNumber(n.phone_number, false, false) }}:
                <div v-if="isOptIn(n.id)" style="padding-left: 10px">
                  {{
                    cleanDate(
                      contactData.smsOptIns.find((s) => s.number_id == n.id)
                        .opt_in_at
                    )
                  }}
                </div>
                <el-button
                  v-if="!isOptIn(n.id)"
                  @click="optInContact(n.id)"
                  :disabled="isOptedIn"
                  type="link"
                  >Opt In</el-button
                >
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Tags" name="tags">
            <contact-tag-card
              v-if="contactId"
              :profile-id="contactId"
            ></contact-tag-card>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </el-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CallResultSelect from 'src/components/UIComponents/CallResultSelect';
import Lead from 'src/components/UIComponents/Lead';
import ContactViewCard from 'src/components/Dashboard/Views/Contacts/Cards/ContactViewCard';
import ContactEditCard from 'src/components/Dashboard/Views/Contacts/Cards/ContactEditCard';
import ContactTagCard from 'src/components/Dashboard/Views/Contacts/Cards/ContactTagCard';
import CallDataReservation from 'src/components/UIComponents/CallDataReservation';
import PhoneNumberSelect from 'src/components/UIComponents/PhoneNumberSelect';
import VoiceService from 'src/services/voiceServices';
import DispositionService from 'src/services/dispositionService';
import ContactService from 'src/services/contactServices';
import AgentService from 'src/services/agentService';

import { processError } from 'src/util/processError.js';

export default {
  name: 'CallDisposition',
  computed: {
    ...mapGetters('appConfig', ['getAppConfig']),
    ...mapGetters('auth', ['getLoginAccount']),
    ...mapGetters('callResults', ['getCallResults']),
    ...mapGetters('phoneNumbers', ['getPhoneNumbers', 'getTextNumbers']),
    ...mapGetters('realtime', [
      'getCallById',
      'getCallData',
      'getDispositionById',
    ]),
    isOptedIn() {
      if (!this.contactData || !this.numberId) {
        return false;
      }
      const curOpt = this.contactData.smsOptIns.find(
        (o) => o.number_id == this.numberId
      );
      if (curOpt == undefined) {
        return false;
      }
      return curOpt.opt_out_at == undefined ? true : false;
    },
  },
  components: {
    CallDataReservation,
    ContactEditCard,
    ContactTagCard,
    CallResultSelect,
    Lead,
    PhoneNumberSelect,
  },
  props: {
    dispositionId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    dispositionId(id) {
      this.loadDisp(id);
    },
  },
  data() {
    return {
      callId: undefined,
      callResult: undefined,
      // queueName: undefined,
      leadDirty: true,
      leadValid: false,
      savedLead: undefined, // The saved version of the lead, required to complete call
      // TODO: make this work
      pendingNotes: [], // Notes that have not been persisted, need to save the lead first

      leadId: undefined,
      contactId: undefined,
      numberId: undefined,
      activeTabs: [],
      contactData: undefined, // actual contact obj only used for SmsOpt right now
      changeContact: false,
      sourceNumber: undefined, // Stored when inbound call is active, only applies for inbound calls
      sourceName: 'Source',
      showSmsOpt: false,
      useCallResult: false, // Use newer call type first vs call result
    };
  },
  async created() {
    // Eventually this will be removed
    this.useCallResult =
      this.getAppConfig?.callCenter?.useCallResult === true ? true : false;
    // TODO: only allow logged in agents
    if (!this.getLoginAccount.userId) {
      console.error('You must be logged in as an agent to use this');
      return false;
    }
    this.loadDisp(this.dispositionId);
  },
  methods: {
    ...mapActions('realtime', ['modifyDisposition']),
    ...mapActions('callResults', ['fetchCallResults']),
    async loadDisp(dispId) {
      let disp = this.getDispositionById(this.dispositionId);

      // The agent might have missed a realtime update. So if parts are
      // missing then lead the data from the DB
      if (!disp || !disp.number_id || !disp.contact_id || !disp.call_id) {
        try {
          const result = await DispositionService.fetchActive(dispId);
          disp = result.data;
          this.modifyDisposition(disp);
        } catch (e) {
          const errMsg = processError(e.response);
          this.$message({
            type: 'error',
            message: 'Disposition is not valid, please refresh: ' + errMsg,
            showClose: true,
          });
          return false;
        }
        // IF disp is dead here we have problems.
      }

      // Init all the values
      this.contactId = disp.contact_id;
      this.leadId = disp.lead_id;
      this.callId = disp.call_id;
      this.numberId = disp.number_id;
      this.savedLead = undefined;
      this.callResult =
        this.getCallResults.find((cr) => cr.id == disp.call_result_id) ||
        undefined;
      // Force the lead component to roll and load fresh
      if (this.callResult && this.callResult.lead_required) {
        const tmp = this.callResult;
        this.callResult = undefined;
        this.$nextTick(() => {
          this.callResult = tmp;
        });
      }

      // Store the outside number now as the call might be gone later
      // We only set this automatically for inbound calls
      const thisCall = this.getCallById(this.callId);
      if (thisCall) {
        if (thisCall.direction == 'inbound') {
          this.sourceNumber = thisCall.to_number;
          this.sourceName = 'Source';
        } else {
          // Can be used to set lead source automatically based on callerId number
          this.sourceNumber = thisCall.from_number;
          this.sourceName = 'CallerId';
        }
      }
    },
    viewContact() {
      this.$router.push('/admin/contacts/view/' + this.contactId);
    },
    toggleContactChange() {
      this.changeContact = !this.changeContact;
    },
    async toggleTab(curTab) {
      this.loading = true;
      if (curTab.includes('smsOpt') && !this.contactData && this.contactId) {
        const result = await ContactService.fetchContactById(this.contactId);
        this.contactData = result.data;
      }
      this.loading = false;
    },
    async changeCallResult(cr) {
      // Cant unset it once set, can only change it to something else
      if (cr === undefined) {
        return false;
      }
      this.callResult = cr;
      await DispositionService.updateCallResultId(
        this.dispositionId,
        this.callResult.id
      );
    },
    async updateLead(lead) {
      this.savedLead = lead;
      if (this.leadId != lead.id) {
        await DispositionService.updateLeadId(this.dispositionId, lead.id);
      }
    },
    async updateContact(contactId) {
      if (contactId == undefined) {
        console.error('this is not allowed to be empty, contactid');
        return false;
      }
      this.leadDirty = true;
      this.contactId = contactId;
      this.contactData = undefined;
      await DispositionService.updateContactId(
        this.dispositionId,
        this.contactId
      );
    },
    isOptIn(numberId) {
      if (!this.contactData) {
        return false;
      }
      const opt = this.contactData.smsOptIns.find(
        (o) => o.number_id == numberId
      );
      if (!opt) {
        return false;
      }
      return opt.is_opt_out == undefined ? true : false;
    },
    async optInContact(numberId) {
      try {
        const result = await AgentService.verbalOptin(
          this.callId,
          this.getLoginAccount.userId,
          this.contactId,
          numberId
        );
      } catch (e) {
        const errMsg = processError(e.response);
        this.$message({
          type: 'error',
          message: 'Error Opting In: ' + errMsg,
          showClose: true,
        });
        return false;
      }

      this.$message({
        message: 'Contact was verbally opted in',
        type: 'success',
        showClose: true,
      });
      this.activeTabs = [];
      this.contactData = undefined;
    },
    async completeCall() {
      if (!this.callResult) {
        return false;
      }

      // Couple the components or just make one step for each??
      // const leadSaved = $refs.leadComponent.save();
      if (this.callResult.lead_required) {
        if (this.leadDirty || this.savedLead == undefined) {
          this.$message({
            message:
              'Lead is not saved. Please be sure all lead changes are saved first',
            type: 'warning',
            showClose: true,
          });
          return;
        }
        // And other lead processing logic that needs to be evaluated
      }
      let result;
      try {
        result = await VoiceService.completeCall(
          this.callId,
          this.callResult.id,
          this.savedLead ? this.savedLead.id : undefined,
          this.dispositionId
        );
      } catch (e) {
        console.error('Error completing call', e.response);
        // we will be in an invalid agent state potentially??
        // Hopefully this is only transient network errors and retry will work
        this.$message({
          message: 'Error completing this call, Please try again',
          type: 'error',
          showClose: true,
        });
        return false;
      }

      if (this.savedLead) {
        this.$message({
          message: 'Lead stored and disposition finished',
          type: 'success',
          showClose: true,
        });
      } else {
        this.$message({
          message: 'Disposition finished',
          showClose: true,
          type: 'success',
        });
      }
    },
  },
};
</script>
<style>
.disposition {
  display: flex;
  flex-direction: row;
}
.dispositionData {
  display: flex;
  flex-direction: column;
  padding: 1em;
  flex: 0 0 80%;
}
.otherData {
  border-left: solid #ddd 1px;
  display: flex;
  flex-direction: column;
  padding: 1em;
  flex: 0 0 20%;
}
.call-data-reservation {
  background: #eee;
  border-radius: 2px;
  padding: 1.2em;
  margin-left: 1em;
  margin-right: 1em;
}
</style>
