<template>
  <span>
    <el-select
      v-if="editable"
      v-model="selectedId"
      @change="do_change"
      :placeholder="placeholder"
      :size="size"
    >
      <el-option v-if="allowAll" :value="0" :label="'Any Type'"></el-option>
      <el-option
        v-for="option in getLeadTypes"
        :value="option.id"
        :key="option.id"
        :label="option.name"
      >
      </el-option>
    </el-select>
    <span v-if="!editable">
      {{ getSelectedName() }}
    </span>
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'leadTypeSelect',
  computed: {
    ...mapGetters('leadType', ['getLeadTypes']),
  },
  props: {
    value: {
      type: Number,
    },
    initialId: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      default: () => {
        return 'small';
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return 'Select a Lead Type';
      },
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowAll: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedId: undefined,
    };
  },
  watch: {
    initialId(id) {
      this.selectedId = id;
    },
    value(n) {
      this.selectedId = parseInt(n);
    },
  },
  async created() {
    this.selectedId = this.initialId;
  },
  methods: {
    do_change() {
      this.$emit(
        'update:leadType',
        this.getLeadTypes.find((s) => s.id == this.selectedId)
      );
      this.$emit('update:modelValue', this.selectedId);
      this.$emit('change', this.selectedId);
    },
    getSelectedName() {
      const lt = this.getLeadTypes.find((s) => s.id == this.selectedId);
      return lt ? lt.name : 'Unknown';
    },
  },
};
</script>
