import taskService from 'src/services/taskServices';

const state = {
  priorities: undefined
};
const getters = {
  getTaskPriorities: state => {
    return state.priorities;
  },
};
const actions = {
  async fetchTaskPriorities({ commit }) {
    if (state.priorities == undefined) {
      return taskService.fetchTaskPriorities()
        .then(r => {
          let priorities = [];
          for (const p of r.data) {
            priorities.push(p);
          }
          commit('storePriorities', priorities);
        });
    }
  },
  unloadTaskPriorities({ commit }) {
    commit('taskPriorities', undefined);
  },
};

const mutations = {
  storePriorities(state, priorities) {
    state.priorities = Object.assign([], priorities);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
