<template>
  <div class="wrapper" v-if="getLoginAccount">
    <span v-if="longToken == false">
      <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks"></side-bar>
      <div class="main-panel">
        <top-navbar v-if="getLoginAccount.userId" :use-long-token="longToken" :user-id="getLoginAccount.userId"></top-navbar>
        <dashboard-content @click.native="toggleSidebar"></dashboard-content>
      </div>
    </span>
    <div v-if="longToken">

      <el-card v-if="agentsFirst">
        <div slot="header" class="clearfix">
          <span>Agent Status</span>
          <el-button style="float: right; padding: 3px 0" :link="true" @click="showAgents = !showAgents">{{showAgents ? 'Hide' : 'Show'}}</el-button>
        </div>
        <agent-status-list v-show="showAgents"></agent-status-list>
      </el-card>
      <br />
      <el-card>
        <div slot="header" class="clearfix">
          <span>Active Calls</span>
          <el-button style="float: right; padding: 3px 0" :link="true" @click="showCalls = !showCalls">{{showCalls ? 'Hide' : 'Show'}}</el-button>
        </div>
        <call-status-list v-show="showCalls"></call-status-list>
      </el-card>
      <br />
      <el-card v-if="!agentsFirst">
        <div slot="header" class="clearfix">
          <span>Agent Status</span>
          <el-button style="float: right; padding: 3px 0" :link="true" @click="collapseAgents()">{{showAgents ? 'Hide' : 'Show'}}</el-button>
        </div>
        <agent-status-list v-show="showAgents"></agent-status-list>
      </el-card>
          <el-button style="float: right; padding: 3px 8px" :link="true" @click="agentsFirst = !agentsFirst">Swap Panels</el-button>
          <el-button style="float: right; padding: 3px 8px" :link="true" @click="switchBoard" v-if="!longToken">Switch To Big Board</el-button>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import { mapGetters } from 'vuex';

import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';

import AgentStatusList from 'src/components/UIComponents/AgentStatusList';
import CallStatusList from 'src/components/UIComponents/CallStatusList';
import WebsocketService from 'src/services/websocketService';

export default {
  components: {
    AgentStatusList,
    CallStatusList,
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  data() {
    return {
      editorModeActive: false,
      longToken: false,
      websocketWorker: undefined,
      websocket: undefined, // only used for long token realtime
      reconnects: 0,
      reconnectDelay: 0,
      missedPings: 0,

      showCalls: true,
      showAgents: true,
      agentsFirst: true,
    };
  },
  computed: {
    ...mapGetters('editor', [
      'getControlPaneShow',
      'getEditorName',
      'getEditorSubject',
    ]),
    ...mapGetters('auth', [
      'getLongTokenMode',
      'getLoginAccount', // Used to prevent timing issues before account is loaded in memory
    ]),
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async listenWebsocket(url) {
      // Return this promise when the socket is opened
      const isConnected = new Promise((resolve, reject) => {
        this.websocket = new WebSocket(url);
        // setTimeout(() => {
        //   this.websocket = new WebSocket(result.data.url);
        // will fullfil only when open
        this.websocket.onopen = (m) => {
          this.reconnects = 0;
          /* testing with a connect delay
          setTimeout(() => {
            resolve();
          }, 1000);
          */
          resolve();
        };
        // We need to handle the case where it does not connect
        // But not just any error, only connect errors
        // Register all the callbacks
        this.websocket.onmessage = (m) => {
          if (m.data === "__pong__") {
            // Just drop the keepalive messages but reset missed pings
            this.missedPings = 0;
            return;
          }
          WebsocketService.processMessage(m.data);
        };
        this.websocket.onerror = async (e) => {
          this.$message({
            message: 'Server Connection Error. You might need to refresh',
            type: 'error',
            showClose: true,
          });
          console.log('WS error', e);
          this.websocketWorker.terminate();
          this.websocketWorker = undefined;
          this.websocket = undefined;
          if (this.reconnects < 5) {
            await this.listenWebSocket(url);
          }
        };
        this.websocket.onclose = async (m) => {
          // Cant  continue without a websocket
          console.log('WS closed', m);
          this.$message({
            message: 'Server Connection Lost. Reconnecting....',
            type: 'error',
            showClose: true,
          });
          this.websocketWorker.terminate();
          this.websocketWorker = undefined;
          this.websocket = undefined;
          if (this.reconnects < 5) {
            await this.listenWebSocket(url);
          }
        };
      });

      this.websocketWorker = new Worker('/websocketWorker.js');
      this.websocketWorker.onmessage = async (m) => {
          // console.log('Worker sent up us the message', m);
          if (this.missedPings > 10) {
            console.log('10 missed pings, terminating socket, and reconnecting');
            this.websocketWorker.terminate();
            this.websocketWorker = undefined;
            this.websocket = undefined;
            if (this.reconnects < 5) {
              await this.listenWebSocket(url);
            }
          }
          this.missedPings = this.missedPings + 1;
          this.websocket.send('__ping__');
      };
      this.reconnects += 1;
    },
    async useLongToken() {
      this.longToken = true;

      const topics = ['agentStatus', 'voice', 'voiceSegment'];
      // User 0 will be public user I guess
      const result = await WebsocketService.connect(0, topics);
      if (result.status != 200) {
        console.log('error establishing WS connection', result.data);
        return;
      }

      await this.listenWebsocket(result.data.url);
    },
  },
  async created() {
    if (this.$route.name === 'Template Editor') {
      this.editorModeActive = true;
    }
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      if (to.name === 'Template Editor') {
        this.editorModeActive = true;
      } else {
        this.editorModeActive = false;
      }
    },
    async getLongTokenMode(value) {
      if (value) {
        await this.useLongToken();
      }
    }
  },
};
</script>
