<template>
  <div>
    <el-form size="small" label-width="auto" :model="lead" :rules="rules">
      <el-form-item label="Lead Status" prop="lead_status_id">
        <lead-status-select
          v-on:update:leadStatus="setLeadStatus"
          :initialId="lead.lead_status_id"
          :selected-lead-type="lead.lead_type_id"
        ></lead-status-select>
        <lost-reason-select
          v-show="showLostReason"
          v-on:update:lostReason="setLostReason"
          :initialId="lead.lost_reason_id"
        ></lost-reason-select>
      </el-form-item>
      <el-form-item label="Lead Subject" prop="subject">
        <el-input
          :type="subjectRows > 1 ? 'textarea' : undefined"
          @change="doChange('subject')"
          v-model="lead.subject"
          placeholder="Enter the lead Subject"
          :rows="subjectRows"
          :autosize="{ minRows: 1, maxRows: subjectRows }"
        ></el-input>
      </el-form-item>
      <el-form-item label="Lead Type" prop="lead_type_id"
        v-if="getLeadTypes.length > 1"
      >
        <lead-type-select
          v-on:update:leadType="setLeadType"
          v-if="getLeadTypes.length > 1"
          :initialId="lead.lead_type_id"
          @change="doChange('lead_type_id')"
          :allow-all="true"
          style="width:50%"
        ></lead-type-select>
      </el-form-item>
      <el-form-item
        v-if="contactId === undefined || lead.reservation_id == undefined"
        label="Contact"
        prop="contact_id"
      >
        <contact-select
          :value="lead.contact_id"
          @update:value="setContact"
          style="width:50%"
        ></contact-select>
      </el-form-item>
      <el-form-item label="Source" prop="source_id">
        <lead-source-select
          :initial-id="+lead.source_id"
          @update:leadSource="updateLeadSource"
          style="width:50%"
        ></lead-source-select>
      </el-form-item>
      <el-form-item label="Arrival Date" prop="interest_start_at">
        <el-date-picker
          type="date"
          @change="changeInterestStart('interest_start_at')"
          v-model="lead.interest_start_at"
          :format="'M-DD-YYYY'"
          :background-color="'#304156'"
          :class="colorClass['interest_start_at']"
          :readonly="readOnly['interest_start_at']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="Departure Date" prop="interest_end_at">
        <el-date-picker
          type="date"
          @change="doChange('interest_end_at')"
          v-model="lead.interest_end_at"
          :format="'M-DD-YYYY'"
          background-color="#304156"
          :class="colorClass['interest_end_at']"
          :readonly="readOnly['interest_end_at']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="Agent Select" prop="user_id">
        <agent-select
          :values="[lead.user_id]"
          :size="'small'"
          style="width:50%;"
          @update:values="setAgent"
        ></agent-select>
      </el-form-item>
      <el-form-item label="Follow Up" prop="followup_at">
        <el-date-picker
          type="datetime"
          @change="doChange('followup_at')"
          v-model="lead.followup_at"
          placeholder="Select date and time"
          :default-time="defaultTime"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="Adults">
        <el-col :xs="10" :sm="8" :md="8" :lg="6" :xl="6">
          <el-input-number
            type="number"
            @change="doChange('quantity_1')"
            v-model="lead.quantity_1"
            :class="colorClass['quantity_1']"
            :precision="0"
            :min="0"
          >
          </el-input-number>
        </el-col>
        <el-col class="text-left" :xs="12" :sm="10" :md="8" :lg="6" :xl="6">
          <el-form-item label="Children">
            <el-input-number
              type="number"
              placeholder="Children"
              @change="doChange('quantity_2')"
              v-model="lead.quantity_2"
              :class="colorClass['quantity_2']"
              :precision="0"
              :min="0"
            >
            </el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="Pets">
        <el-col :xs="10" :sm="8" :md="8" :lg="6" :xl="6">
          <el-input-number
            type="number"
            @change="doChange('quantity_3')"
            v-model="lead.quantity_3"
            :class="colorClass['quantity_3']"
            :precision="0"
            :min="0"
          >
          </el-input-number>
        </el-col>
        <el-col class="text-left" :xs="12" :sm="10" :md="8" :lg="6" :xl="6">
          <el-form-item label="Estimated Value">
            <el-input
              type="number"
              @change="mutateEstimatedValue()"
              v-model="estimatedValueFloat"
              placeholder="Estimated Value"
              :class="colorClass['estimated_value']"
              :readonly="readOnly['estimated_value']"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="Unit Type">
        <el-col :span="6">
          <el-input
            type="text"
            @change="doChange('product_code')"
            v-model="lead.product_code"
            placeholder="Unit Type"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="Reservation Number" prop="bookingCode">
        <el-col :span="4" class="text-left">
          <connector-select
            v-model="connectorId"
            size="small"
            select-first-available
          ></connector-select>
        </el-col>
        <el-col :span="6" style="margin-right:1em;">
          <el-input type="text" v-model="bookingCode"></el-input>
        </el-col>
        <el-col :span="3" align="left">
          <el-button @click="confirmBooking()">Link Reservation</el-button>
        </el-col>
      </el-form-item>
      <el-form-item v-for="c in getLeadCustomFields"
        :label="c.label" :prop="'custom_fields.' + c.field_name">
          <el-input-number
            v-if="c.data_type == 'number'"
            type="number"
            :placeholder="c.label"
            @change="doChange('custom_fields')"
            v-model="lead.custom_fields[c.field_name]"
          >
          </el-input-number>
          <el-input
            v-if="c.data_type == 'string'"
            type="string"
            placeholder="c.label"
            @change="doChange('custom_fields')"
            v-model="lead.custom_fields[c.field_name]"
          >
          </el-input>

          <el-select
            v-if="c.data_type == 'select'"
            @change="doChange('custom_fields')"
            v-model="lead.custom_fields[c.field_name]"
            :size="'small'"
          >
            <el-option
              v-for="value in c.values"
              :value="value.value"
              :key="value.id"
              :label="value.label"
            >
              <!-- TODO add label color -->
            </el-option>
          </el-select>

      </el-form-item>

      <el-tag v-if="!lead.id" size="small" type="warning" style="margin-bottom: 2em;">
        Select a Source / Subject to begin saving notes.
      </el-tag>
      <notes-card
        v-if="lead.id"
        :key="`${lead.id}-notes`"
        :id="lead.id"
        :type="'lead'"
      ></notes-card>

      <!-- <el-input
        label="Source:"
        @change="doChange()"
        v-model="lead.source"
        placeholder="Enter Lead Source... eventually from a list"
      ></el-input> -->
      <el-form-item label=" ">
        <el-button
          :disabled="!valid || !dirty"
          @click="save()"
          type="primary"
          v-if="!livePatch"
          >Save Lead</el-button
        >
        <el-button v-if="!livePatch" :disabled="!dirty" @click="reset()">
          Revert Lead
        </el-button>
        <el-button v-if="showClose" @click="close()">Close</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { processError } from 'src/util/processError.js';

import AgentSelect from 'src/components/UIComponents/AgentSelect.vue';
import ContactSelect from 'src/components/UIComponents/ContactSelect.vue';
import LeadStatusSelect from 'src/components/UIComponents/LeadStatusSelect.vue';
import LeadSourceSelect from 'src/components/UIComponents/LeadSourceSelect.vue';
import LostReasonSelect from 'src/components/UIComponents/LostReasonSelect.vue';
import LeadTypeSelect from 'src/components/UIComponents/LeadTypeSelect.vue';
import NotesCard from 'src/components/Dashboard/Views/Notes/NotesListing';
import ConnectorSelect from 'src/components/UIComponents/ConnectorSelect';

import LeadService from 'src/services/leadService.js';
import ConnectorService from 'src/services/connectorService.js';
import SmsService from 'src/services/smsServices';
import ReservationService from 'src/services/reservationServices.js';

export default {
  name: 'Lead',
  components: {
    AgentSelect,
    ConnectorSelect,
    ContactSelect,
    LostReasonSelect,
    LeadSourceSelect,
    LeadStatusSelect,
    LeadTypeSelect,
    NotesCard,
  },
  props: {
    leadId: {
      // For editing
      type: Number,
      required: false,
    },
    contactId: {
      type: Number,
      default: () => undefined,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    sourceNumber: {
      // Set the source from this phone number, if any
      type: String,
      required: false,
    },
    livePatch: {
      // Save the lead as as soon as possible.
      // Once the lead is saved, all new changes will be live patched to the server
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    leadId() {
      this.loadLead();
    },
    contactId(id) {
      this.lead.contact_id = id;
      this.doChange('contact_id');
    },
    sourceNumber() {
      this.setSource();
    },
  },
  computed: {
    ...mapGetters('appConfig', ['getAppConfig']),
    ...mapGetters('auth', ['getLoginAccount']),
    ...mapGetters('leadStatus', ['getLeadStatuses']),
    ...mapGetters('leadType', ['getLeadTypes']),
    ...mapGetters('customFields', ['getLeadCustomFields']),
    rules() {
      const subRequired = (this.getAppConfig && this.getAppConfig.lead &&
        this.getAppConfig.lead.subjectRequired != undefined) ?
        this.getAppConfig.lead.subjectRequired : false;
      const rules = {
        contact_id: [{ required: true, trigger: 'change' }],
        lead_status_id: [{ required: true }],
        source_id: [{ required: true, trigger: 'change' }],
        subject: [{ required: subRequired, trigger: 'change' }],
        user_id: [{ required: true, trigger: 'change' }],
        followup_at: [{ required: this.followupRequired, trigger: 'change' }],
      };
      for (const c of this.getLeadCustomFields) {
        if (c.is_required) {
          rules['custom_fields.' + c.field_name] = [{ required: true, trigger: 'change'}];
        }
      }
      return rules;
    },
    subjectRows() {
      return this.getAppConfig && this.getAppConfig.lead && this.getAppConfig.lead.subjectRows ? this.getAppConfig.lead.subjectRows : 1;
    },
    getDefaultLeadType() {
      const lt = this.getLeadTypes.find(t => t.is_default === true);
      return lt ? lt.id : undefined;
    },
  },
  data() {
    return {
      saveProm: undefined,
      savePromResolve: undefined, // linked to saveProm above
      estimatedValueFloat: 0,
      selectedLeadStatus: undefined,
      bookingCode: undefined,
      colorClass: {
        interest_start_at: undefined,
        interest_end_at: undefined,
        estimated_value: undefined,
        quantity_1: undefined,
        quantity_2: undefined,
        quantity_3: undefined,
      },
      readOnly: {
        interest_start_at: undefined,
        interest_end_at: undefined,
        estimated_value: undefined,
      },
      lead: {
        id: undefined,
        subject: undefined,
        contact_id: undefined,
        lead_status_id: undefined,
        user_id: undefined,
        source_id: undefined,
        interest_start_at: undefined,
        interest_end_at: undefined,
        followup_at: undefined,
        quantity_1: undefined,
        quantity_2: undefined,
        quantity_3: undefined,
        estimated_value: undefined,
        product_code: undefined,
        reservation_id: undefined,
        custom_fields: {},
      },
      savedLead: {
        id: undefined,
        subject: undefined,
        contact_id: undefined,
        lead_status_id: undefined,
        user_id: undefined,
        source_id: undefined,
        interest_start_at: undefined,
        interest_end_at: undefined,
        followup_at: undefined,
        quantity_1: undefined,
        quantity_2: undefined,
        quantity_3: undefined,
        estimated_value: undefined,
        product_code: undefined,
        reservation_id: undefined,
        custom_fields: {},
      },
      dirty: false,
      valid: false,
      // Lock values after input, this needs to be an option at some point.
      lock: true,
      canPatch: false, // Prevents patch before the lead is valid or saved
      connectorId: undefined,
      showLostReason: undefined,
      followupRequired: undefined,
      defaultTime: undefined,
    };
  },
  async created() {
    const now = new Date();
    now.setHours(12);
    this.defaultTime = now;
    await this.loadLead();
    this.lead.contact_id = this.contactId;
  },
  methods: {
    async loadLead() {
      this.savedLead = {
        id: undefined,
        subject: undefined,
        contact_id: undefined,
        lead_status_id: undefined,
        lead_type_id: undefined,
        user_id: undefined,
        source_id: undefined,
        interest_start_at: undefined,
        interest_end_at: undefined,
        followup_at: undefined,
        quantity_1: undefined,
        quantity_2: undefined,
        quantity_3: undefined,
        estimated_value: undefined,
        product_code: undefined,
        reservation_id: undefined,
        lost_reason_id: undefined,
        custom_fields: {},
      };

      // Reset to nothing in case anything errors
      this.lead = {
        id: undefined,
        subject: undefined,
        contact_id: undefined,
        lead_status_id: undefined,
        lead_type_id: undefined,
        user_id: undefined,
        source_id: undefined,
        interest_start_at: undefined,
        interest_end_at: undefined,
        followup_at: undefined,
        quantity_1: undefined,
        quantity_2: undefined,
        quantity_3: undefined,
        estimated_value: undefined,
        product_code: undefined,
        reservation_id: undefined,
        lost_reason_id: undefined,
        custom_fields: {},
      };

      if (this.leadId) {
        const result = await LeadService.fetchById(this.leadId);
        if (!result) {
          console.error('Lead not found by id');
          this.valid = false;
          this.$emit('update:valid', this.valid);
          // TODO: pop this to the user
          return;
        }
        const lead = result.data;
        this.lead.id = this.leadId;
        this.lead.subject = lead.subject ? lead.subject : undefined;
        this.lead.contact_id = lead.contact_id ? lead.contact_id : undefined;
        this.lead.lead_status_id = lead.lead_status_id;
        this.lead.lead_type_id = lead.lead_type_id;
        this.lead.user_id = lead.user_id;
        this.lead.source_id = lead.source_id;
        this.lead.interest_start_at = lead.interest_start_at;
        this.lead.interest_end_at = lead.interest_end_at;
        this.lead.followup_at = lead.followup_at;
        this.lead.quantity_1 = lead.quantity_1 ? lead.quantity_1 : undefined;
        this.lead.quantity_2 = lead.quantity_2 ? lead.quantity_2 : undefined;
        this.lead.quantity_3 = lead.quantity_3 ? lead.quantity_3 : undefined;
        this.lead.estimated_value = lead.estimated_value ? lead.estimated_value : 0;
        this.estimatedValueFloat = (lead.estimated_value / 100).toFixed(2);
        this.lead.product_code = lead.product_code;
        this.lead.reservation_id = lead.reservation_id;
        this.lead.lost_reason_id = lead.lost_reason_id;
        this.lead.custom_fields = lead.custom_fields ?? {};

        // set the lost reason display
        const curStatus = this.getLeadStatuses.find(l => l.id == lead.lead_status_id);
        this.showLostReason = !curStatus ? false : curStatus.is_lost;
        this.followupRequired = curStatus ? curStatus.is_followup : false;

        // Set saved lead for reverting later
        this.savedLead.id = lead.id;
        this.savedLead.subject = lead.subject;
        this.savedLead.contact_id = lead.contact_id;
        this.savedLead.lead_status_id = lead.lead_status_id;
        this.savedLead.lead_type_id = lead.lead_type_id;
        this.savedLead.user_id = lead.user_id;
        this.savedLead.source_id = lead.source_id;
        this.savedLead.interest_start_at = lead.interest_start_at;
        this.savedLead.interest_end_at = lead.interest_end_at;
        this.savedLead.followup_at = lead.followup_at;
        this.savedLead.quantity_1 = lead.quantity_1 ? lead.quantity_1 : undefined;
        this.savedLead.quantity_2 = lead.quantity_2 ? lead.qunatity_2 : undefined;
        this.savedLead.quantity_3 = lead.quantity_3 ? lead.quantity_3 : undefined;
        this.savedLead.estimated_value = lead.estimated_value;
        this.savedLead.product_code = lead.product_code;
        this.savedLead.reservation_id = lead.reservation_id;
        // no nesting allowed
        this.savedLead.custom_fields = Object.assign({}, lead.custom_fields);

        // Try to pull reservation
        if (lead && lead.reservation_id) {
          const reservation = await ReservationService.fetchReservationsById(
            lead.reservation_id
          );
          this.bookingCode = reservation.data.reservation_number;
          // todo: could possible augment the adult/child or additional props if necessary
        }

        // Assume the data from the DB is pristine
        this.dirty = false;
        this.valid = this.isValid();
      }
      else {
        // On new leads we only set default type if they have one
        this.lead.lead_type_id =  this.getDefaultLeadType;
      }

      // Which takes precedence if contact is different from saved?
      if (this.contactId) {
        const savedContact = this.lead.contact_id;
        this.lead.contact_id = this.contactId;
        this.savedLead.contact_id = this.contactId;
        if (savedContact != this.contactId) {
          this.dirty = true;
          this.valid = false;
        }
        // This will update the dirty/valid;
        if (this.lead.id && this.livePatch && savedContact != this.contactId) {
          await this.save('contact_id');
        }
      }
      await this.setSource();
      try {
        // Populate Agent Id on Load
        if (!this.lead.user_id && this.getLoginAccount.userId) {
          const savedAgent = this.lead.user_id;
          this.lead.user_id = this.getLoginAccount.userId;
          this.savedLead.user_id = this.getLoginAccount.userId;
          // Save immediately if you can
          if (savedAgent != this.lead.user_id) {
            this.dirty = true;
            this.valid = false;
          }
          if (
            this.lead.id &&
            this.livePatch &&
            savedAgent != this.getLoginAccount.userId
          ) {
            await this.save('user_id');
          }
        }
      } catch (e) {
        console.error("Can't auto select agent id: " + e);
      }

      // Only really needed if we did not save and there are differences
      // Forces a save to get all the updates correct
      this.$emit('update:dirty', this.dirty);
      this.$emit('update:valid', this.valid);
      // this.$emit('update:lead', this.savedLead);
    },
    async setSource() {
      try {
        if (this.sourceNumber && this.sourceNumber.length > 0) {
          const number = (await SmsService.fetchByNumber(this.sourceNumber))
            .data;
          const savedSource = this.lead.source_id;
          this.lead.source_id = number.lead_source_id;
          this.savedLead.source_id = number.lead_source_id;
          // Save any discrepancy immediately, if existing lead on patch mode
          if (savedSource != number.lead_source_id) {
            this.dirty = true;
            this.valid = false;
          }
          if (
            this.lead.id &&
            this.livePatch &&
            savedSource != number.lead_source_id
          ) {
            await this.save('source_id');
          }
        }
      } catch (e) {
        console.error("Can't find lead source by call number: " + e);
      }
    },
    async save(field) {
      // Validate guard clause
      if (!this.isValid()) {
        return false;
      }
      // This needs to be a singleton to prevent incorrect state patch
      // saves.  They dont need to be serialized for patch but the entire
      // patch must be completed as a single unit of work otherwise parital
      // commits can happen overwriting or deleting other data.
      let result;
      while (this.saveProm) {
        await this.saveProm;
        // A new promise might be assigned before this one resolved.
        // So check in a loop and only proceed when no lock
      }
      this.saveProm = new Promise((resolve, reject) => {
        this.savePromResolve = resolve;
      });
      // have we saved this before or loaded an existing?
      if (this.lead && this.lead.id) {
        let patch = {};
        // On live patch we only need to save the individual field per change
        // If there are multiple patches in series they will all block at the
        // Await and then start patching after they complete.
        // Sends more over the network initially but safe in the long run.
        if (this.livePatch && this.canPatch) {
          patch[field] = this.lead[field];
          // Save contact Id because you cant 'see' that one
          patch['contact_id'] = this.lead.contact_id;
          // Only really needed if we did not save and there are differences
        } else {
          patch = {
            subject: this.lead.subject,
            contact_id: this.lead.contact_id,
            lead_status_id: this.lead.lead_status_id,
            lead_type_id: this.lead.lead_type_id,
            user_id: this.lead.user_id,
            source_id: this.lead.source_id,
            interest_start_at: this.lead.interest_start_at,
            interest_end_at: this.lead.interest_end_at,
            followup_at: this.lead.followup_at,
            quantity_1: this.lead.quantity_1,
            quantity_2: this.lead.quantity_2,
            quantity_3: this.lead.quantity_3,
            estimated_value: this.lead.estimated_value,
            product_code: this.lead.product_code,
            reservation_id: this.lead.reservation_id,
            lost_reason_id: this.lead.lost_reason_id,
            custom_fields: this.lead.custom_fields, // full set must be sent
          };
        }

        // Lead Subject is a frontend only requirement so its easily faked
        // Backend still requires a subject but empty string is valid
        if (patch.subject == undefined &&
          this.getAppConfig && this.getAppConfig.lead &&
          this.getAppConfig.lead.subjectRequired != undefined &&
          this.getAppConfig.lead.subjectRequired == false
          ) {
          patch.subject = '';  // default to empty string for now
        }

        try {
          // Wait for other pending operations
          result = await LeadService.updateLead(this.lead.id, patch);
          this.canPatch = true;  // Once it saved successfully we can patch
        } catch (e) {
          const errMsg = processError(e.response);

          this.$message({
            type: 'error',
            message: 'Error saving lead: ' + errMsg,
            showClose: true
          });
          // Errored out; release the lock
          this.saveProm = undefined;
          this.savePromResolve();
          this.savePromResolve = undefined;
          return false;
        }
      } else {
        try {
          // Lead Subject is a frontend only requirement so its easily faked
          // Backend still requires a subject but empty string is valid
          if (this.lead.subject == undefined &&
            this.getAppConfig && this.getAppConfig.lead &&
            this.getAppConfig.lead.subjectRequired != undefined &&
            this.getAppConfig.lead.subjectRequired == false
            ) {
            this.lead.subject = '';  // default to empty string for now
          }
          result = await LeadService.createLead({
            subject: this.lead.subject,
            contact_id: this.lead.contact_id,
            lead_status_id: this.lead.lead_status_id,
            lead_type_id: this.lead.lead_type_id,
            user_id: this.lead.user_id,
            source_id: this.lead.source_id,
            interest_start_at: this.lead.interest_start_at,
            interest_end_at: this.lead.interest_end_at,
            followup_at: this.lead.followup_at,
            quantity_1: this.lead.quantity_1,
            quantity_2: this.lead.quantity_2,
            quantity_3: this.lead.quantity_3,
            estimated_value: this.lead.estimated_value,
            product_code: this.lead.product_code,
            reservation_id: this.lead.reservation_id,
            lost_reason_id: this.lead.lost_reason_id,
            custom_fields: this.lead.custom_fields,
          });
          this.canPatch = true;
        } catch (e) {
          let details = e.response ? e.response.data : e;
          if (e.response && e.response.data && e.response.data.details) {
            let inside = e.response.data.details;
            if (typeof inside == 'object') {
              details = '';
              for (const [k, v] of Object.entries(inside)) {
                details += "'" + k + "':" + v.message + ', ';
              }
            }
          }
          else {
            details = e.response.data
          }

          this.$message({
            type: 'error',
            message:
              (e.response.data
                ? e.response.data.message
                : 'Error saving lead: ') +
              ' ' +
              details,
            showClose: true,
          });
          // Errored out; release the lock
          this.saveProm = undefined;
          this.savePromResolve();
          this.savePromResolve = undefined;
          return false;
        }
      }

      // let the user know
      if (!this.livePatch) {
        this.$message({
          message: 'Success saving lead',
          type: 'success',
          showClose: true,
        });
      }


      this.savedLead = Object.assign({}, this.lead);
      // No nested fields.
      this.savedLead.custom_fields = Object.assign({}, this.lead.custom_fields);
      this.savedLead.id = result.data.id;
      this.lead.id = result.data.id;
      this.dirty = false;
      this.valid = true;
      this.$emit('update:dirty', false);
      this.$emit('update:valid', true);
      this.$emit('update:lead', this.savedLead);
      this.saveProm = undefined;
      // Prevent resolve from contexting immediately to other event
      const tmp = this.savePromResolve;
      this.savePromResolve = undefined;
      tmp();
    },
    setLeadStatus(leadStatus) {
      // Not sure if I like passing the whole object back or just the id,
      // This way I can have the values associated with it like is_won ,etc without a lookup
      this.lead.lead_status_id = leadStatus.id;
      this.lead.lost_reason_id = undefined;
      this.showLostReason = leadStatus.is_lost;
      this.followupRequired = leadStatus.is_followup;
      // Set all the state before sending other changes
      this.doChange('lead_status_id');
      this.doChange('lost_reason_id');
    },
    setLostReason(lostReason) {
      this.lead.lost_reason_id = lostReason.id;
      this.doChange('lost_reason_id');
    },
    setLeadType(ld) {
      if (ld == undefined) {
        this.lead.lead_type_id = null;
      }
      else {
        this.lead.lead_type_id = ld.id;
      }
      this.doChange('lead_type_id');
    },
    mutateEstimatedValue() {
      this.lead.estimated_value = parseInt(this.estimatedValueFloat * 100, 10);
      this.doChange('estimated_value');
    },
    doChange(field) {
      // Only emit on the change, we will update later
      if (!this.dirty) {
        this.$emit('update:dirty', true);
      }
      this.dirty = true;
      const tmp = this.isValid();
      if (this.valid != tmp) {
        this.$emit('update:valid', tmp);
      }
      this.valid = tmp;
      if (this.livePatch && this.isValid()) {
        this.save(field);
      }
    },
    changeInterestStart(value) {
      if (this.lead.interest_end_at == undefined) {
        this.lead.interest_end_at = this.lead.interest_start_at;
        this.doChange('interest_end_at');
      }
      this.doChange('interest_start_at');
    },
    reset() {
      this.lead = Object.assign({}, this.savedLead);
      this.lead.custom_fields = Object.assign({}, this.savedLead.custom_fields);

      if (this.contactId) {
        this.lead.contact_id = this.contactId;
      }

      this.dirty = false;
      this.valid = this.isValid();
      this.$emit('update:dirty', false);
      this.$emit('update:valid', this.valid);
    },
    isValid() {
      if (this.showLostReason && !this.lead.lost_reason_id){
        this.canPatch = false;
        return false;
      }
      // This is a frontend only requirement so its easily faked
      // Backend still requires a subject but empty string is valid
      if (this.getAppConfig && this.getAppConfig.lead &&
        this.getAppConfig.lead.subjectRequired != undefined &&
        this.getAppConfig.lead.subjectRequired &&
        !this.lead.subject) {
        this.canPatch = false;
        return false;
      }
      // Also a FE only required at the moment
      if (this.followupRequired && !this.lead.followup_at) {
        this.canPatch = false;
        return false;
      }

      for (const f of this.getLeadCustomFields.filter(f => f.is_required)) {
        if (this.lead.custom_fields[f.field_name] == undefined ||
          this.lead.custom_fields[f.field_name] == ''
        ) {
          this.canPatch = false;
          return false;
        }
      }

      if (
        this.lead.contact_id &&
        this.lead.source_id &&
        this.lead.lead_status_id &&
        this.lead.user_id
      ) {
        return true;
      }

      // Prevent patch when not valid
      this.canPatch = false;
      return false;
    },
    setAgent(values) {
      if (values.length) {
        this.lead.user_id = values[0];
      } else {
        // TODO: we don't allow un-setting?
        this.lead.user_id = undefined;
      }
      this.doChange('user_id');
    },
    close() {
      this.$emit('close');
    },
    async confirmBooking() {
      let status = undefined;
      try {
        status = await ConnectorService.getRealtimeBooking(
          this.connectorId,
          this.lead.contact_id ?? 0,
          this.bookingCode
        );
      }
      catch (e) {
        if (e.response == undefined) {
          // Some non request error
          console.error('Unhandled error linking reservation', e);
          this.$message({
            type: 'error',
            message: 'Unexpected error processing reservation data: ' + e,
            showClose: true,
          });
          return false;
        }
        // Mostly care about existing contacts with different ids
        if (e.response.status = 409 && e.response.data.contact_id) {
          this.$confirm('Reservation is saved to another contact. Save this lead to the existing contact?', 'Reservation Conflict', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          })
          .then(async () => {
            // We cant move the phone/email as they are clearly in use on the other contact
            /*
            const result = await contactService.patchContact(
              this.contact_id,
              {'mobile_phone': undefined}
              );
            const result = await contactService.patchContact(
              this.lead.contact_id,
              {'mobile_phone': 'the lead contact email/or phone}
              );

            */
            //
            // If the reservation exists on a differnt contact, lets try
            // to merge the phone/email and move to that contact.

            // Save the lead with the new contact and then load that page.
            this.lead.contact_id = e.response.data.contact_id;
            await this.save();
            this.$message({
              type: 'success',
              message: 'Reservation assigned to exsiting contact. Loading now...',
              showClose: true,
            });
            this.$emit('update:contactId', this.lead.contact_id);
            // this.$router.push('/admin/contacts/view/' + this.lead.contact_id);
            return false;
          })
          .catch((e) => {
            // Any other error just stop for now.
            this.$message({
              type: 'error',
              message: 'Error loading reservation for this contact: Conflict with another contact',
              showClose: true,
            });
            return false;
          });
          return false;
        }

        if (e.response.status = 404) {
          // This is still an error but it might be a connector error too
          // If the connector is not found, or the connector url is wrong, etc
          this.$message({
            type: 'error',
            message: 'Error locating a reservation with that Id',
            showClose: true,
          });
          return false
        }
        console.error('uncaught exception linking reservation', e);
        return false;
      }

      const folio = status.data.folio;
      const reservationId = status.data.reservation_id;

      let messageType = 'error';
      let message = "Couldn't find Reservation";

      // These responses are yet to be normalized.  Consider how others will respond.
      if (reservationId) {
        this.lead.reservation_id = reservationId;
        messageType = 'success';
        message = 'Found Reservation [' + this.bookingCode + ']';
        try {
          if (folio.arrival_date) {
            this.lead.interest_start_at = new Date(folio.arrival_date.split('Z')[0]);
          }
          if (folio.departure_date) {
            this.lead.interest_end_at = new Date(folio.departure_date.split('Z')[0]);
          }
          this.doChange('interest_start_at');
          this.doChange('interest_end_at');
          this.colorClass.interest_start_at = 'element-input-green';
          this.colorClass.interest_end_at = 'element-input-green';
          if (this.lock) {
            this.readOnly.interest_start_at = true;
            this.readOnly.interest_end_at = true;
          }
        } catch (e) {
          console.error('unable to parse dates from connector api: ', e);
        }
        try {
          this.estimatedValueFloat = (+folio.total_revenue / 100).toFixed(2);
          this.lead.estimated_value = folio.total_revenue;
          this.doChange('estimated_value');

          this.colorClass.estimated_value = 'element-input-green';
          if (this.lock) {
            this.readOnly.estimated_value = true;
          }
        } catch (e) {
          console.error(
            'Unable to parse estimated value from connector api: ',
            e
          );
        }
        // Attempt to load adults / children & pets.  Only on RDP
        try {
          if (folio.adults) {
            this.lead.quantity_1 = folio.adults;
            this.colorClass.quantity_1 = 'element-input-green';
          }
          if (folio.children) {
            this.lead.quantity_2 = folio.children;
            this.colorClass.quantity_2 = 'element-input-green';
          }
          if (folio.pets) {
            this.lead.quantity_3 = folio.pets;
            this.colorClass.quantity_3 = 'element-input-green';
          }
        } catch (e) {
          console.error(
            'Unable to parse adult / children from connector api: ',
            e
          );
        }
      }

      this.$message({
        type: messageType,
        message: message,
        showClose: true,
      });

      // TODO this needs to be complete, but it must be optional set by the agent admin
      this.lock = true;
    },
    updateLeadSource(leadSource) {
      this.lead.source_id = leadSource.id;
      this.doChange('source_id');
    },
    setContact(contactId) {
      this.lead.contact_id = contactId
      this.doChange('contact_id');
    }
  },
};
</script>
<style lang="scss">
.element-input-green {
  input {
    background: rgb(231, 253, 231);
  }
}
</style>
