<template>
  <div>
    <p>Reservation Info:</p>
      <el-row>
        <el-col :span="8">
          <small>Guest Status:</small>
        </el-col>
        <el-col :span="16" class="text-right">
          <small>{{guestStatus}}</small>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <small>Res Id:</small>
        </el-col>
        <el-col :span="16" class="text-right">
          <small>{{reservationNumber}}</small>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <small>Value:</small>
        </el-col>
        <el-col :span="16" class="text-right">
          <small>{{toCurrency(totalRevenue, true)}}</small>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <small>Arrival Date:</small>
        </el-col>
        <el-col :span="16" class="text-right">
          <small>{{cleanDate(arrivalDate, true)}}</small>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <small>Departure Date:</small>
        </el-col>
        <el-col :span="16" class="text-right">
          <small>{{cleanDate(departureDate, true)}}</small>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CallDataReservation',
  computed: {
    ...mapGetters('realtime', ['getCallData']),
  },
  props: {
    callId: {
    },
  },
  data() {
    return {
      reservationNumber: undefined,
      arrivalDate: undefined,
      departureDate: undefined,
      unitCode: 'Unknown',
      totalRevenue: 0,
      guestStatus: 'Unknown',
    };
  },
  created() {
    if (this.callId) {
      const callData = this.getCallData(this.callId);
      if (callData == undefined) {
        return;
      }
      if(callData.reservation) {
        const now = new Date();
        this.reservationNumber = callData.reservation.reservation_number;
        this.arrivalDate = new Date(callData.reservation.arrival_date);
        this.departureDate = new Date(callData.reservation.departure_date);
        this.unitCode = callData.reservation.unit_code;
        this.totalRevenue = callData.reservation.total_revenue;

        if (this.arrivalDate && this.arrivalDate > now) {
          this.guestStatus = 'Future Guest';
        }
        else if (this.arrivalDate && this.arrivalDate < now &&
          this.departureDate && this.departureDate > now)
        {
          this.guestStatus = 'Current Guest';
        }
        else {
          this.guestStatus = 'Prior Guest';
        }
      }
      else {
        this.guestStatus = 'New Guest';
      }
    }
  },
  methods: {
  },
};
</script>

