<template>
  <div>
    <el-select
      v-if="this.getConnectors && this.getConnectors.length"
      v-model="internalValue"
      @change="doChange"
      :placeholder="placeholder"
      :size="size"
    >
      <el-option :value="0" :label="'Select One'"></el-option>
      <el-option
        v-for="option in this.getConnectors"
        :value="option.id"
        :key="option.id"
        :label="option.label"
      ></el-option>
    </el-select>
    <el-alert
      v-if="this.getConnectors && this.getConnectors.length === 0"
      title="You have zero connectors"
      type="warning"
    >
    </el-alert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ConnectorSelect',
  props: {
    modelValue: {
      type: [Number, String],
    },
    size: {
      type: String,
      default: 'small',
    },
    placeholder: {
      type: String,
    },
    selectFirstAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data: {
    internalValue: undefined,
  },
  computed: {
    ...mapGetters('connectors', ['getConnectors']),
  },
  created() {
    if (this.selectFirstAvailable && this.getConnectors.length > 0) {
      this.internalValue = this.getConnectors[0].id;
      this.doChange();
    }
  },
  methods: {
    doChange() {
      this.$emit('update:modelValue', this.internalValue);
      this.$emit('change', this.internalValue);
    },
    returnValue(option) {
      return +option.id;
    },
  },
};
</script>

