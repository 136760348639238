<template>
  <div>
    <template v-if="loading">
      <send-loading :loading="loading" :no-quote="true"></send-loading>
    </template>
    <template v-if="!loading">
      <div v-for="option in tags" :key="option.id">
        <el-tag style="margin-bottom: 0.3em" :color="option.color">
          <el-checkbox
            v-model="contactTags[option.id]"
            @change="changeTags(option.id)"
          >
            <span :style="{ color: bgColor(option.color) }">{{
              option.label
            }}</span>
          </el-checkbox>
        </el-tag>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import tagService from 'src/services/tagService.js';
import contactServices from 'src/services/contactServices';

export default {
  props: {
    profileId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      loading: true,
      tags: [],
      contactTags: {},
    };
  },
  computed: { },
  watch: {
    profileId(n) {
      this.onLoad();
    },
  },
  created() {
    this.onLoad();
  },
  methods: {
    ...mapActions('tags', ['addContactToTag', 'removeContactFromTag']),

    bgColor(bgColor) {
      if (!bgColor) {
        return '#000000';
      }
      var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    },

    changeTags(tagId) {
      if (this.contactTags[tagId] === true) {
        this.addContactToTag({ contact: this.profileId, tag: tagId }).then(
          (r) => {
            this.$emit('update:add-tag', { id: tagId, contactId: this.profileId });
            this.$message({
              message: 'Successfully added to tag',
              type: 'success',
            });
          }
        );
      } else if (this.contactTags[tagId] === false) {
        this.removeContactFromTag({
          contact: this.profileId,
          tag: tagId,
        }).then((r) => {
          this.$emit('update:remove-tag', { id: tagId, contactId: this.profileId });
          this.$message({
            message: 'Successfully removed from tag',
            type: 'success',
          });
        });
      }
    },
    async onLoad() {
      this.loading = true;
      const contactData = await contactServices.fetchContactById(
        this.profileId
      );
      const tags = await tagService.fetchTags();
      const contact = contactData.data;
      this.tags = tags.data;
      // console.log(tags.data);
      const tmp = {};
      for (const tag of tags.data) {
        tmp[+tag.id] = false;
      }
      for (const inc in contact.tags) {
        const tagId = contact.tags[inc].id;
        tmp[+tagId] = true;
      }
      this.contactTags = Object.assign({}, tmp);
      // console.log(this.contactTags);
      this.loading = false;
    },
  },
};
</script>
