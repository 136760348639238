<template>
  <el-dropdown trigger="click" @command="toggleOpen" ref="menudrop">
    <span class="el-dropdown-link"> </span>
    <template #dropdown>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item divided disabled v-if="getAgentSegments.length"
          >Guest Segment</el-dropdown-item
        >
        <el-dropdown-item
          class="segment-control"
          style="display: flex; justify-content: flex-start;"
          v-for="segment in getContactSegments"
          v-bind:key="segment.id"
        >
          <active-call-dialog-button
            v-if="!segment.is_hold"
            :icon="'holdOff'"
            v-on:action="doHold(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="segment.is_hold"
            :icon="'holdOn'"
            v-on:action="doHold(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="!segment.is_muted"
            :icon="'muteOff'"
            v-on:action="doMute(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="segment.is_muted"
            :icon="'muteOn'"
            v-on:action="doMute(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            :icon="'end'"
            v-on:action="doHangup(segment)"
          >
          </active-call-dialog-button>
        </el-dropdown-item>

        <el-dropdown-item
          divided
          disabled
          class="flex-left"
          v-if="getAgentSegments.length"
          >Agent Segments</el-dropdown-item
        >
        <el-dropdown-item
          class="segment-control"
          style="display: flex; justify-content: flex-start;"
          v-for="segment in getAgentSegments"
          v-bind:key="segment.id"
          :command="segment.id"
        >
          <agent-field-view
            v-if="segment.user_id"
            :agent-id="segment.user_id"
            :display="['first_name', 'last_name']"
          ></agent-field-view
          >:&nbsp;
          <active-call-dialog-button
            v-if="!segment.is_hold"
            :icon="'holdOff'"
            v-on:action="doHold(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="segment.is_hold"
            :icon="'holdOn'"
            v-on:action="doHold(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="!segment.is_muted"
            :icon="'muteOff'"
            v-on:action="doMute(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="segment.is_muted"
            :icon="'muteOn'"
            v-on:action="doMute(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            :icon="'end'"
            v-on:action="doHangup(segment)"
          >
          </active-call-dialog-button>
        </el-dropdown-item>

        <el-dropdown-item divided disabled v-if="getOtherSegments.length"
          >Other Segments</el-dropdown-item
        >
        <el-dropdown-item
          class="segment-control"
          style="display: flex; justify-content: flex-start;"
          v-for="segment in getOtherSegments"
          v-bind:key="segment.id"
          :command="segment.id"
          ><!-- add name eventually{{segment.user_id}}-->
          <active-call-dialog-button
            v-if="!segment.is_hold"
            :icon="'holdOff'"
            v-on:action="doHold(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="segment.is_hold"
            :icon="'holdOn'"
            v-on:action="doHold(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="!segment.is_muted"
            :icon="'muteOff'"
            v-on:action="doMute(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            v-if="segment.is_muted"
            :icon="'muteOn'"
            v-on:action="doMute(segment)"
          >
          </active-call-dialog-button>
          <active-call-dialog-button
            :icon="'end'"
            v-on:action="doHangup(segment)"
          >
          </active-call-dialog-button>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import AgentService from 'src/services/agentService';
import ActiveCallDialogButton from 'src/components/UIComponents/CallAgentPlugin/ActiveCallDialogButton';
import AgentFieldView from 'src/components/UIComponents/AgentFieldView';

export default {
  name: 'segment-control',
  components: {
    ActiveCallDialogButton,
    AgentFieldView,
  },
  props: {
    callId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      // This should be in realtime too
    };
  },
  computed: {
    ...mapGetters('realtime', ['getCallSegments']),
    ...mapGetters('voice', ['getDirectDials']),
    getContactSegments() {
      return this.getCallSegments(this.callId).filter(
        (c) => c.segment_type == 'contact'
      );
    },
    getAgentSegments() {
      return this.getCallSegments(this.callId).filter(
        (c) => c.segment_type == 'user'
      );
    },
    getOtherSegments() {
      return this.getCallSegments(this.callId).filter(
        (c) => c.segment_type != 'user' && c.segment_type != 'contact'
      );
    },
  },
  async mounted() {
    // Force it open on show
    if (this.$refs.menudrop) {
      this.$refs.menudrop.handleOpen();
    }
  },
  async created() {},
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
      this.$emit('update:isOpen', this.isOpen);
    },
    doHangup(segment) {
      AgentService.hangUp(segment.call_id, segment.segment_id);
      this.toggleOpen();
    },
    doMute(segment) {
      AgentService.muteSegment(
        segment.call_id,
        segment.segment_id,
        !segment.is_muted
      );
      this.toggleOpen();
    },
    doHold(segment) {
      AgentService.holdSegment(
        segment.call_id,
        segment.segment_id,
        !segment.is_hold
      );
      this.toggleOpen();
    },
  },
};
</script>
<style lang="scss" scoped>
.segment-control {
  // background: rgb(245, 245, 245);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  .active-call-button {
    margin-left: 10px;
  }
  .flex-left {
    margin-left: auto;
  }
}
</style>
