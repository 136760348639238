<template>
  <!-- TODO: Make this a custom hover -->
  <el-dropdown @command="handleProfileSelection" class="profile-drop-down">
    <span class="el-dropdown-link">
      <el-avatar
        :icon="UserFilled"
        fit="cover"
        :src="getProfilePhoto"
      ></el-avatar>
      <span v-if="didLogin && !wsConnected"
        style="color: red; font-size: 20px;">!</span>
    </span>
    <template #dropdown>
      <el-dropdown-menu slot="dropdown">
        <profile-card-drop-down
          v-if="getAcls('is_agent')"
          @agent-login="loginAgent()"
          @agent-logout="logoutAgent()"
        ></profile-card-drop-down>
        <template v-if="isAgentLoggedIn">
          <br />
        </template>
        <el-dropdown-item
          v-if="didLogin && !wsConnected"
          >
          <p style="color:red" >
          <font-awesome-icon icon="bolt" /> *** Connection Error ***
          </p>
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isAgentLoggedIn"
          command="/admin/call-center/agent-dashboard"
          :icon="Headset"
        >
          Call-Center Dashboard
        </el-dropdown-item>

        <el-dropdown-item
          command="/admin/settings-overview"
          :icon="Setting"
          divided
        >
          Global Settings
        </el-dropdown-item>
        <el-dropdown-item command="logout" :icon="Close" divided>
          Logout
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { markRaw } from "vue";

import { Setting, Close, UserFilled, Headset } from '@element-plus/icons-vue'

import ProfileCardDropDown from './ProfileCardDropDown';
export default {
  components: {
    ProfileCardDropDown,
    // Icons
    Setting: markRaw(Setting),
    Close: markRaw(Close),
    UserFilled: markRaw(UserFilled),
    Headset: markRaw(Headset),
  },
  props: {
    isAgencyMode: {
      type: Boolean,
      default: () => false,
    },
    agentStatus: {
      type: Object,
      default: () => {
        return { state: 'away', status: 'pending' };
      },
    },
    wsConnected: {
      type: Boolean,
      default: () => false,
    },
    agentLogoutFunction: {
      type: Function,
    },
  },
  watch: {
    wsConnected(newval, oldval) {
      if (this.didLogin && newval == false) {
          this.$message({
            message: 'Connection Error. You will will need to log out and reconnect',
            type: 'error',
            showClose: true,
          });
      }
    },
  },
  data() {
    return {
      didLogin: false,
      UserFilled,
      Headset,
      Close,
      Setting,
    };
  },
  computed: {
    ...mapGetters('realtime', ['getMyAgentStatus']),
    ...mapGetters('auth', ['getAcls', 'getProfilePhoto', 'getLoginAccount']),
    isAgentLoggedIn() {
      if (this.getLoginAccount == undefined) {
        return false;
      }
      return this.getMyAgentStatus(this.getLoginAccount.userId);
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    loginAgent() {
      this.$emit('agent-login', {});
      // Wait a couple seconds for the connection to come up
      setTimeout(() => {
        this.didLogin = true;
      }, 2500);
    },
    async logoutAgent() {
      // This is fired from the drop down card event
      await this.agentLogoutFunction();
      this.didLogin = false;
    },
    logoutUser() {
      this.logout();
      localStorage.clear();
      // We have to reload the window otherwise the state will still be in memory and
      // will be reinjected as soon as the module is fired up on the next request
      location.reload()
      this.didLogin = false;
      //this.$router.push('/login');
    },
    created() { },
    async handleProfileSelection(command) {
      switch (command) {
        case 'ignore':
          return;
        case 'logout':
          if (this.getLoginAccount && this.getMyAgentStatus(this.getLoginAccount.userId)) {
            try {
              // this must be completed prior to user logout
              const result = await this.agentLogoutFunction();
              this.didLogin = false;
            }
            catch (e) {
              // the above function will throw a message to the user on success
              // So just skip logging out
              return false;
            }
          }
          this.logoutUser();
          break;
        default:
          if (this.$router.currentRoute.path !== command) {
            this.$router.push({
              path: command
            });
          }
          break;
      }
    },
  },
};
</script>
<style lang="scss">
.profile-drop-down {

  &:focus-visible {
    outline: none;
  }
  .el-dropdown-link {
    cursor: pointer;
    &:focus-visible {
      outline: none;
    }
  }

}
</style>
