<template>
  <span> {{ queueName }} </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'queue-display',
  computed: {
    ...mapGetters('queues', ['getQueues']),
  },
  props: {
    queueId: {
      type: Number,
    },
  },
  data() {
    return {
      queueName: undefined,
    };
  },
  created() {
      const queue = this.getQueues.find(q => q.id == this.queueId);
      this.queueName = queue ? queue.name : 'Unknown';

  },
  watch: {
    queueId(n) {
      const queue = this.getQueues.find(q => q.id == n);
      this.queueName = queue ? queue.name : 'Unknown';
    },
  },
};
</script>

