<template>
  <span>
    <el-autocomplete
      v-model="selectedValueAuto"
      v-if="autocomplete && !multi"
      :fetch-suggestions="suggest"
      clearable
      placeholder="Type Agent Name"
      @select="doChangeAuto"
      @clear="doClear"
      :size="size"
      :teleported="teleported"
    >
      <template #default="{ item }">
        <div>{{ item.first_name }} {{ item.last_name }}</div>
      </template>
    </el-autocomplete>
    <el-select
      v-if="!autocomplete"
      v-model="selectedValues"
      @change="doChange"
      @clear="doClear"
      :placeholder="'Select An Agent'"
      :multiple="multi"
      :size="size"
      :teleported="teleported"
    >
      <el-option
        v-if="allowAll"
        :value="0"
        :label="allLabel ? allLabel : 'All Agents'"
      ></el-option>
      <el-option
        v-for="(option, index) in getAgents"
        :key="index"
        :value="option.id"
        :label="option.first_name + ' ' + option.last_name"
      ></el-option>
    </el-select>
  </span>
</template>

<script>
import { isProxy, toRaw } from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'agentSelect',
  computed: {
    ...mapGetters('agents', ['getAgent', 'getAgents']),
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    multi: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    allowAll: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    allLabel: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: () => {
        return 'small';
      },
    },
    // autocomplete only works for a single agent
    autocomplete: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    // the teleported tag has the dropdown replicate
    // functionality from element-ui
    teleported: { type: Boolean, default: true },
  },
  emits: ['update:values', 'change'],
  data() {
    return {
      selectedValues: [],
      selectedValueAuto: undefined, // Only for typeahead
    };
  },
  async created() {
    if (!this.multi) {
      this.selectedValues = undefined;
    }
    if (!this.getAgents || this.getAgents.length == 0) {
      await this.fetchAgents(0);
    }
    if (this.values) {
      if (!this.multi) {
        this.selectedValues = this.values.length ? this.values[0] : undefined;
      } else {
        this.selectedValues = this.values.slice();
      }
    }
  },
  watch: {
    values: {
      immediate: true,
      handler(n) {
        // Convert to/from array as needed
        if (!this.multi) {
          if (isProxy(n)) {
            // Vue 3 proxy converts arrays to objects which break comparisons
            // As its a nested array of arrays in the proxy
            const vals = toRaw(n);
            this.selectedValues = vals.length ? vals[0] : undefined;
          } else {
            this.selectedValues = n.length ? n[0] : undefined;
            const name = toRaw(this.getAgents).find(({ id }) => id == n[0]);
            if (name) {
              this.selectedValueAuto = `${name.first_name} ${name.last_name}`;
            }
          }
        } else {
          this.selectedValues = n;
        }
      },
    },
  },
  methods: {
    ...mapActions('agents', ['fetchAgents']),
    doClear() {
      this.selectedValues = undefined;
      this.doChange();
    },
    doChange() {
      if (!this.multi) {
        this.$emit('update:values', [this.selectedValues]);
        this.$emit('change', [this.selectedValues]);
      } else {
        this.$emit('update:values', this.selectedValues);
        this.$emit('change', this.selectedValues);
      }
    },
    doChangeAuto(newVal) {
      if (newVal) {
        this.selectedValueAuto = newVal.first_name + ' ' + newVal.last_name;
        // Keep the same response structure as single
        this.$emit('update:values', [newVal.id]);
        this.$emit('change', [newVal.id]);
      } else {
        this.selectedValueAuto = undefined;
        this.$emit('update:values', []);
        this.$emit('change', []);
      }
      return false;
    },
    suggest(input, callback) {
      if (!input) {
        return callback(this.getAgents);
      }
      const values = this.getAgents.filter((x) =>
        x.first_name.toLowerCase().includes(input.toLowerCase())
      );
      callback(values);
    },
  },
};
</script>
