<template>
  <div>
    <el-select
      v-model="internalValue"
      @change="doChange"
      filterable
      remote
      :remote-method="filterContacts"
      :loading="loading"
      :placeholder="placeholder"
      :size="size"
    >
      <el-option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        :label="option.first_name + ' ' + option.last_name"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContactService from 'src/services/contactServices.js';

export default {
  name: 'contactSelect',
  computed: {
  },
  props: {
    value: {
      type: [Number, String],
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return 'Select a contact by name';
      },
    },
    size: {
      type: String,
      required: false,
      default: () => {
        return 'small';
      },
    },
    requireEmail: {
      type: Boolean,
      required: false,
      default: () => {
        return undefined;
      },
    },
  },
  data() {
    return {
      search: undefined,
      options: [],
      loading: false,
      internalValue: undefined,
    };
  },
  mounted() {
    if (this.value) {
      this.loading = true;
      this.fetchContact(this.value).then(c => {
        this.options.push(c);
        this.internalValue = c.id;
        this.loading = false;
      });
    }
  },
  watch: {
    value: {
      handler(id) {
        this.internalValue = id;
        if (id == undefined) {
          return;
        }
        if (!this.options.find((c) => c.id == id)) {
          this.fetchContact(id).then(c => {
            this.options.push(c);
          });
          // this.internalValue = c.id;
        }
      },
    },
  },
  methods: {
    ...mapActions('contacts', ['fetchContact']),
    doChange(event) {
      this.$emit('update:value', this.internalValue);
      // this.$emit('update:modelValue', this.internalValue);
      // this.options = [],
      this.search = undefined;
      this.internalValue =undefined;
    },
    async filterContacts(search) {
      if (!search) {
        this.loading = false;
        return;
      }

      let filters = [];
      let tokens = search.split(' ');
      let email = undefined;
      // Remove an email token if there is one
      let emailToken = tokens.find((t) => t.includes('@'));
      if (emailToken) {
        tokens = tokens.filter((t) => t != emailToken);
        email = emailToken;
      }
      // If we have 2 tokens left after email assume its names in order
      if (tokens.length >= 2) {
        filters.push(`first_name:like:${tokens[0]}:and`);
        filters.push(`last_name:like:${tokens[1]}:and`);
      } else if (tokens.length == 1) {
        filters.push(`first_name:like:${tokens[0]}:or`);
        filters.push(`last_name:like:${tokens[0]}:or`);
      }

      const result = await ContactService.getContacts(
        0,
        filters,
        undefined,
        10,
        undefined,
        undefined,
        email,
        this.requireEmail,
        undefined
      );
      this.options = result.data.data;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input__inner {
  color: #606266 !important;
}
</style>
