<template>
  <div class="row mb-3">
    <div class="col-md-6">
      <el-form label-width="120px" size="small">
        <el-form-item label="First Name">
          <el-input v-model="contact.first_name"
            @change="patchContact('first_name')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Last Name">
          <el-input v-model="contact.last_name"
            @change="patchContact('last_name')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Email">
          <el-input v-model="contact.email"
            @change="patchContact('email')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Mobile Phone" >
          <el-input v-model="contact.mobile_phone"
            @change="patchContact('mobile_phone')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Home Phone">
          <el-input v-model="contact.home_phone"
            @change="patchContact('home_phone')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="col-md-6">
      <el-form label-width="120px" size="small">
        <el-form-item label="Address 1">
          <el-input v-model="contact.address_1"
            @change="patchContact('address_1')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Address 2">
          <el-input v-model="contact.address_2"
            @change="patchContact('address_2')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Locality / City">
          <el-input v-model="contact.locality"
            @change="patchContact('locality')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Region / State">
          <el-input v-model="contact.region"
            @change="patchContact('region')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Postal Code">
          <el-input v-model="contact.postal"
            @change="patchContact('postal')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="col-md-6">
      <el-form label-width="120px" size="small">
        <el-form-item label="Company Name">
          <el-input v-model="contact.company_name"
            @change="patchContact('company_name')"
            :disabled=contact.is_locked
            ></el-input>
        </el-form-item>
        <el-form-item label="Birthday">
          <el-date-picker
            type="date"
            :format="'M-DD-YYYY'"
            placeholder="Birthday"
            v-model="contact.birthday"
            @change="patchContact('birthday')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Anniversary">
          <el-date-picker
            type="date"
            :format="'M-DD-YYYY'"
            placeholder="Anniversary"
            v-model="contact.anniversary"
            @change="patchContact('anniversary')"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import contactServices from 'src/services/contactServices';

export default {
  props: {
    contactId:{
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      contact: {
        id: null,
        display_name: '',
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        locality: '',
        region: '',
        postal: '',
        country: 'US',
        home_phone: undefined,
        mobile_phone: undefined,
        email: undefined,
        locale: 'en',
        timezone: '',
        company_name: undefined,

        unsubscribe_token: '',

        // Validation
        email_validated: undefined,
        email_validation_error: undefined,

        last_open_email: undefined,
        last_open_sms: undefined,
        last_sent_email: undefined,
        last_sent_sms: undefined,

        custom_fields: {},
        is_locked: false,
      },
    };
  },
  watch: {
    contactId(n) {
      if (n) {
        this.loadContact();
      }
    },
  },
  async created() {
    await this.loadContact();
  },
  methods: {
    ...mapActions('contacts', ['deleteContactCache']),
    async loadContact() {
      this.loading = true;
      const contactData = await contactServices.fetchContactById(this.contactId);
      // Update the primary email
      contactData.data.email = contactData.data.primaryEmail ? contactData.data.primaryEmail.email_address : undefined;
      this.contact = contactData.data;
      // This will be a ne profile type card
        // Fetch Custom Fields
      this.loading = false;
    },
    async patchContact(field) {
      if (this.contact.is_locked) {
        return;
      }
      const patch = {};
      if (field == 'birthday' || field == 'anniversary') {
        patch[field] = this.contact[field].toISOString();
      }
      if (this.contact[field] === '') {
        this.contact[field] = null;
      }
      patch[field] = this.contact[field];
      // TODO: This will need to be reworked to support more than one email
      // Patch email
      if (patch.email) {
        patch.primary_email = patch.email;
        delete patch.email;
      }
      try {
        await contactServices.patchContact(this.contactId, patch);
        this.deleteContactCache(+this.id);
      }
      catch (e) {
        this.$message({
          type: 'error',
          message: 'Error updating contact: ' + e.response.data,
        });
      }
    },
  }
}

</script>
