<template>
  <div :class="addRightSideNavigation?'content right-navigation':'content'">
    <router-view v-show="!getMyActiveDispositionId"></router-view>
      <transition name="fade" mode="out-in">
        <call-disposition
          v-if="getMyActiveDispositionId"
          :disposition-id="getMyActiveDispositionId"></call-disposition>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CallDisposition from 'src/components/UIComponents/CallDisposition';
export default {
  components: {
    CallDisposition,
  },
  created() {
    if (this.$route.name === 'Template Editor') {
      this.addRightSideNavigation = true;
    }
  },
  computed: {
    ...mapGetters('realtime', [
      'getMyAgentStatus',
      'getMyDispositions',
      'getActiveDisposition',
    ]),
    ...mapGetters('auth', [
      'getLoginAccount',
      ]),
    ...mapActions('realtime', ['modifyActiveDisposition']),
    getMyActiveDispositionId() {
      if (this.getActiveDisposition == undefined) {
        return undefined;
      }
      // If the disposition is unassigned we will remove it from the filter
      const disp = this.getMyDispositions(this.getLoginAccount.userId)
        .find(x => x.id == this.getActiveDisposition);
      // This can be a race condition with it being 'completed'
      // Let this be handled in the store for now and pull it out
      // later if we have timing issues
      /*
      if (disp == undefined && this.activeDisposition) {
        // Unset the active if we no longer have the disp assigned
        // this.modifyActiveDisposition(undefined);
      }
      */
      return disp?.id;
    },
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      // console.log(to);
      if (to.name === 'Template Editor') {
        this.addRightSideNavigation = true;
      } else {
        this.addRightSideNavigation = false;
      }
    }
  },
  data() {
    return {
      addRightSideNavigation: false
    };
  },
};
</script>
<style>
.fade-enter-from-active,
.fade-leave-from-active {
  transition: opacity 0.15s;
}

.fade-enter-from,
  .fade-leave-from-to
    /* .fade-leave-from-active in <2.1.8 */

 {
  opacity: 0;
}
.right-navigation {
  /* margin-top: 64px !important; */
  padding: 0 !important;
  /* padding: 0 16px !important; */
}
</style>
