<template>
  <el-dialog :title="'Owner Selection'" v-model="isVisible">
    <template v-if="loading">
      <send-loading :loading="loading"></send-loading>
    </template>
    <el-form v-if="!loading" label-width="120px">
      <el-form-item label="Users:">
        <owner-select
          v-if="!loading"
          v-model="ownerId"
          :size="'default'"
          :use-fg="false"
          :label="'Select a Owner'"
          :existing-ids="ownerIds"
          :placeholder="''"
        ></owner-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isVisible = false">Cancel</el-button>
      <el-button type="primary" @click="postChanges()">
        {{ edit ? 'Update' : 'Add' }}
        Owner
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import contactServices from 'src/services/contactServices';
import OwnerSelect from 'src/components/UIComponents/OwnerSelect';

export default {
  components: {
    OwnerSelect,
  },
  props: {
    edit: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    contactId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    showDialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    owners: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      id: 0,
      loading: true,
      isVisible: false,
      ownerId: 0,

      contact: {},
      ownerIds: [],
    };
  },
  watch: {
    showDialog(n) {
      this.isVisible = !this.isVisible;
      this.load();
    },
    contactId() {
      this.load();
      this.loading = false;
    },
  },
  created() {
    this.load();
    this.loading = false;
  },
  methods: {
    postChanges() {
      // Add Owner.
      this.$emit('set-owner', {
        ownerId: this.ownerId,
      });
      // Change Owner
    },
    async load() {
      this.ownerId = 0;
      if (this.contactId) {
        this.id = +this.contactId;
        this.loading = true;
        this.ownerIds = this.owners.map((o) => o.id);
        this.loading = false;
      }
    },
  },
};
</script>
