<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications />
    <!--
    <notifications transition-name="notification-list" transition-mode="out-in"></notifications>
    -->
    <router-view name="header">
    </router-view>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <router-view name="footer">
    </router-view>
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import * as compare from 'node-version-compare';
import authServices from 'src/services/authServices';
import ConfigService from 'src/services/configService';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      value: undefined,
      options: [
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
        },
        {
          value: 'Option3',
          label: 'Option3',
        },
      ],


      heartbeatInterval: undefined,
      currentVersion: process.env.PACKAGE_VERSION || '0',
    };
  },
  methods: {
    ...mapActions('auth', ['setLoggedIn']),
    async loadData() {
      let version;
      try {
        version = await authServices.fetchVersion();
      } catch (err) {
        // TODO: if we get here things are broken.
        console.log('we have an error getting version on load', err);
        throw err;
      }
      // console.log(version.data.version, this.currentVersion);
      const r = compare(version.data.version, this.currentVersion) || 0;
      // console.log(r);
      if (r > 0) {
        location.reload();
      }
    },
    sendToLogin() {
      console.log('redirecting to login');
      if (!window.location.href.includes('login')) {
        location.href = "/login";
      }
    }
  },
  async created() {

    // Verify we have a token first on created only
    const accessToken = localStorage.getItem('s2-access-token');
    if (accessToken == undefined) {
      // Need to be able to access public pages
      // We should sub class the routes to lock this down
      // this.sendToLogin();
      return;
    }

    // Manually parse the expiration on the token
    var raw = accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    var jwt = JSON.parse(decodeURIComponent(atob(raw).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('')));

    if (Date.now() >= jwt.exp * 1000) {
      // Token is expired just make them log in again
      this.sendToLogin();
      return;
    }

    // If we cant load then the token or something is bad.
    try {
      await this.loadData();
    }
    catch (err) {
      console.log('we have an error while loading', err);
      // Dont load all the stuffs
      this.sendToLogin();
      return;
    }

    this.heartbeatInterval = setInterval(
      function () {
        this.loadData();
      }.bind(this),
      15 * 60 * 1000 // 15 minutes
    );


    // These 2 items are stored in localStorage to survive refreshes
    let authAccountRaw = localStorage.getItem('s2-auth-account');
    let authAclsRaw = localStorage.getItem('s2-auth-acls');
    let authBrandsRaw = localStorage.getItem('s2-auth-brands');
    let authAccount = { companyId: 0 };
    let authAcls = [];
    let brands = [];
    if (authAccountRaw != undefined) {
      try {
        authAccount = JSON.parse(authAccountRaw);
      }
      catch(e) {
        console.error("Invalid auth data in localStorage:" + e);
        // Default to a basic object to prevent attr errors
      }
      try {
        authAcls = JSON.parse(authAclsRaw);
      }
      catch(e) {
        console.error("Invalid acl data in localStorage:" + e);
      }
      try {
        brands = JSON.parse(authBrandsRaw);
      }
      catch(e) {
        console.error("Invalid brands data in localStorage:" + e);
      }

      if (!authAccount) {
        // Something is wrong
        this.sendToLogin();
        return;
      }

      if (authAccount.companyId != 0) {
        // Actions can only take a single param so make it an object
        this.setLoggedIn({
          authAccount: authAccount,
          authAcls: authAcls,
          authBrands: brands,
        });
        await ConfigService.loadConfig();
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.heartbeatInterval);
  },
};
</script>
<style lang="scss">
</style>
