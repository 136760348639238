<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : undefined"
    :class="{ active: isActive }"
  >
    <a
      v-if="isMenu"
      data-toggle="collapse"
      href="#"
      @click.prevent="collapseMenu"
    >
      <font-awesome-icon
        v-if="link.icon"
        :icon="link.icon"
        size="1x"
      ></font-awesome-icon>
      <p class="adbase-sidebar-nav-link">
        {{ link.name }}
        <b class="caret" :class="{ rotated: !collapsed }"></b>
      </p>
    </a>
    <!-- TODO: add transition if we want to mimic the aboslute way it use to work -->
    <div
      v-if="$slots.default || this.isMenu"
      v-show="!collapsed"
      class="collapse-menu"
    >
      <ul class="nav">
        <slot></slot>
      </ul>
    </div>
    <slot name="title" v-if="(children.length === 0 && link.path) || !children">
      <component
        :to="link.path"
        :is="elementType(link, false)"
        :class="{ active: link.active }"
        :target="link.target"
        @click.native.stop="onItemClick"
        :href="link.path"
      >
        <template v-if="addLink">
          <span class="sidebar-mini-icon">{{
            link.letter || link.name.substring(0, 1)
          }}</span>
          <font-awesome-icon
            v-if="link.icon"
            :icon="link.icon"
            size="1x"
          ></font-awesome-icon>
          <span class="sidebar-normal">{{ link.name }}</span>
        </template>
        <template v-else>
          <font-awesome-icon
            v-if="link.icon"
            :icon="link.icon"
            size="1x"
          ></font-awesome-icon>
          <p class="adbase-sidebar-nav-link">{{ link.name }}</p>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>

export default {
  name: 'side-bar-item',
  props: {
    menu: {
      type: Boolean,
      default: false,
      description: 'Whether item is a menu containing multiple items',
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: [],
        };
      },
      description: 'Link object',
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: { default: false },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  computed: {
    baseComponent() {
      // I don't think there is much use for the top meta component
      // going to test it without this in a bit.
      return this.isMenu || this.link.isRoute ? 'li' : 'li'; //'router-link';
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      // This could still use some refinement
      // it still feels choppy
      if (this.$route) {
        let matchingRoute = this.children.find((c) => {
          const minLength = this.$route.path.length;
          if (c.link.path.length < minLength) {
            return undefined;
          }
          return this.$route.path.startsWith(c.link.path);
        });

        if (matchingRoute !== undefined) {
          return true;
        }

        // console.log(this.$route.path, this.link.path);
        if (this.$route.path.startsWith(this.link.path)) {
          return true;
        }
      }
      return false;
    },

    isLine() {
      // TODO: finish
    },
  },
  methods: {
    addChild(item) {
      this.children.push(item);
    },
    removeChild(item) {
      this.children == this.children.filter((c) => c != item);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? 'li' : 'a';
      } else {
        return 'router-link';
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
    onItemClick() {
      if (this.autoClose) {
        this.$sidebar.showSidebar = false;
      }
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  beforeUnmount() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>
<style scoped>
.caret.rotated {
  transform: rotate(180deg);
}
</style>
