<template>
  <el-select
    label="Select Menu"
    v-model="selected"
    @change="toggleValue()"
    v-if="editable"
  >
    <el-option
      v-for="menu in getMenus"
      v-bind:key="menu.id"
      :value="menu.id"
      :label="menu.name"
    ></el-option>
  </el-select>
  <span v-if="!editable">{{ getMenuName }}</span>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'menu-select',
  props: {
    reload: {
      type: Boolean,
      default: false,
      description: 'Reload the menu on instantiation',
    },
    value: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: true,
      description: 'Allow user to change value',
    },
  },
  data() {
    return {
      selected: undefined,
    };
  },
  computed: {
    ...mapGetters('voice', ['getMenus']),
    getMenuName() {
      const sel = this.getMenus.find((m) => m.id == this.selected);
      return sel ? sel.name : 'None';
    },
  },
  async created() {
    if (this.reload || !this.getMenus.length) {
      await this.fetchMenus();
    }
    if (this.value) {
      this.selected = parseInt(this.value);
    }
  },
  methods: {
    ...mapActions('voice', ['fetchMenus']),
    toggleValue() {
      this.$emit('update:value', String(this.selected));
      // This replaced Input event
      // this.$emit('update:modelValue', String(this.selected));
    },
  },
};
</script>
