<template>
  <el-select
    v-model="internalValue"
    @change="doChange"
    :placeholder="placeholder"
    :size="size"
  >
    <el-option v-if="showAll" :value="0" :label="'* All Users'"> </el-option>
    <el-option v-if="allowNone" :value="0" :label="'* None'">
    </el-option>
    <template v-for="option in getTeam">
      <el-option
        v-if="existingIds.indexOf(option.id) === -1"
        :key="returnValue(option)"
        :value="returnValue(option)"
        :label="option.first_name + ' ' + option.last_name"
      ></el-option>
    </template>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ownerSelect',
  computed: {
    ...mapGetters('team', ['getTeam']),
    ...mapGetters('auth', {
      getLoginAccount: 'getLoginAccount',
      getProfilePhotoURL: 'getProfilePhotoURL',
      getAccountCompany: 'getAccountCompany',
    }),
  },
  props: {
    value: {
      type: [Number, String],
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return '';
      },
    },
    showAll: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    size: {
      type: String,
      required: false,
      default: () => {
        return 'small';
      },
    },
    existingIds: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    allowNone: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      internalValue: undefined,
    };
  },
  async created() {
    await this.fetchTeam();
    if (this.value) {
      this.internalValue = +this.value;
    }
  },
  watch: {
    value(n) {
      this.internalValue = +n;
    },
  },
  methods: {
    ...mapActions('team', ['fetchTeam']),
    doChange() {
      this.$emit('update:modelValue', this.internalValue);
      this.$emit('change', this.internalValue);
    },
    returnValue(option) {
      return +option.id;
    },
  },
};
</script>

