<template>
  <el-dialog :title="'Others Near By'" v-model="isVisible">
    <template v-if="loading">
      <send-loading :loading="loading"></send-loading>
    </template>
    <el-table :data="nearByPlaces">
      <el-table-column label="Photos" width="100">
        <template #default="scope">
          <el-image
            v-if="scope.row.photos && scope.row.photos.length > 0"
            style="width: 100px; height: 100px"
            :src="
              'https://maps.googleapis.com/maps/api/place/photo?maxwidth=1200&photoreference=' +
              scope.row.photos[0].photo_reference +
              '&key=' +
              getGoogleApiKey
            "
            :fit="'cover'"
          ></el-image>
          <!-- <el-carousel
            v-if="scope.row.photos"
            :indicator-position="'none'"
            height="100px"
          >
            <el-carousel-item
              v-for="(item, index) in scope.row.photos"
              :key="scope.row.place_id + '_' + index"
              :style="
                'background-repeat: no-repeat; background-position: center center; background-size: contain;' +
                'background-image: url(' +
                'https://maps.googleapis.com/maps/api/place/photo?maxwidth=1200&photoreference=' +
                item.photo_reference +
                '&key=' +
                getGoogleApiKey +
                ')'
              "
            >
            </el-carousel-item>
          </el-carousel> -->
        </template>
      </el-table-column>
      <el-table-column label="Company">
        <template #default="scope">
          <b>{{ scope.row.name }}</b>
          <p>{{ scope.row.vicinity }}</p>
          <el-rate
            v-model="scope.row.rating"
            disabled
            show-score
            text-color="#ff9900"
            :score-template="
              '{value} rating, by ' + scope.row.user_ratings_total + ' people'
            "
          ></el-rate>
          <i>{{ scope.row.business_status }}</i>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="120">
        <template #default="scope">
          <el-button
            :disabled="isAdding(scope.row.place_id)"
            @click="addCompanyByPlaceId(scope.row.place_id)"
          >
            {{ isAdded(scope.row.place_id) ? 'Added' : 'Add' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import { GOOGLE_MAPS_API_KEY } from 'src/constants';

import googleMapsServices from 'src/services/googleMapsServices';
import contactServices from 'src/services/contactServices';

export default {
  props: {
    companyId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    placeId: {
      type: String,
      default: () => {
        return '';
      },
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.load();
  },
  watch: {
    showDialog(n) {
      this.isVisible = !this.isVisible;
    },
    placeId() {
      this.load();
    },
  },
  computed: {
    getGoogleApiKey() {
      return GOOGLE_MAPS_API_KEY;
    },
  },
  data() {
    return {
      isVisible: false,
      loading: true,

      nearByPlaces: [],
      adding: [],
      added: [],
    };
  },
  methods: {
    isAdding(placeId) {
      return this.adding.indexOf(placeId) > -1 ? true : false;
    },
    isAdded(placeId) {
      return this.added.indexOf(placeId) > -1 ? true : false;
    },
    async load() {
      if (this.placeId) {
        this.loading = true;
        const places = await googleMapsServices.placesNearby(this.placeId);
        // console.log(places);
        // console.log(places.data);
        this.nearByPlaces = places.data;
        this.loading = false;
      }
    },
    async addCompanyByPlaceId(placeId) {
      this.adding.push(placeId);
      const newCompany = await contactServices.addByPlaceId(placeId);
      this.$message({
        message: `Congrats, You've just added [${newCompany.data.display_name}]`,
        type: 'success',
      });
      this.added.push(placeId);
    },
  },
};
</script>
