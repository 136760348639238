<template>
  <span class="active-call-dialog">
    <active-call-name
      :top-pane="true"
      :contact-id="contactId"
      :caller-name="callerName"
      :phone-number="phoneNumber"
    ></active-call-name>
    <!-- Each button -->
    <active-call-dialog-button
      v-for="action in actions"
      :icon="getIcon(action)"
      v-on:action="handleAction"
      :key="action"
      class="active-call-button"
    ></active-call-dialog-button>
    <transfer-select
      v-if="showTransferSelect"
      :callId="callId"
      @update:value="doTransfer"
      :useMenu="true"
    ></transfer-select>
    <call-control
      v-if="showCallControl"
      :callId="callId"
      :agent-device="agentDevice"
    ></call-control>
    <segment-control
      v-if="showSegmentControl && callId"
      :callId="callId"
      @update:isOpen="showSegmentControl = $event"
    ></segment-control>
  </span>
</template>
<script>
// TODO: Link this to the other in IncomingCall
const RING_URL =
  'https://adbase-static.s3.amazonaws.com/sounds/telephone_digital_ring.mp3';

import AgentService from 'src/services/agentService';

import ActiveCallName from './ActiveCallName';
import ActiveCallDialogButton from './ActiveCallDialogButton';
import SegmentControl from 'src/components/UIComponents/SegmentControl';
import TransferSelect from 'src/components/UIComponents/Inputs/TransferSelect';
import CallControl from 'src/components/UIComponents/CallControl';

import { mapGetters } from 'vuex';

export default {
  name: 'ActiveCallDialog',
  components: {
    ActiveCallName,
    ActiveCallDialogButton,
    CallControl,
    SegmentControl,
    TransferSelect,
  },
  computed: {
    ...mapGetters('appConfig', ['getAppConfig']),
    ...mapGetters('auth', ['getLoginAccount']),
    ...mapGetters('realtime', ['getCallSegments', 'getActiveCalls']),
    ...mapGetters('voice', ['getDirectDials']),
    agentSegment() {
      return this.getCallSegments(this.callId).find(
        (s) => s.segment_type == 'user' && s.user_id == this.userId
      );
    },
    contactSegment() {
      // Just take first for now, not guaranteed forever to only have one
      return this.getCallSegments(this.callId).find(
        (s) => s.segment_type == 'contact'
      );
    },
    activeCall() {
      const c = this.getActiveCalls.find((c) => c.call_id == this.callId);
      if (!c) {
        return undefined;
      }

      if (c.recording === false) {
        const i = this.actions.indexOf('recordOn');
        if (i) {
          this.actions[i] = 'recordOff';
          // Vue.set(this.actions, i, 'recordOff');
        }
      }
      if (c.recording === true) {
        const i = this.actions.indexOf('recordOff');
        if (i) {
          this.actions[i] = 'recordOn';
          // Vue.set(this.actions, i, 'recordOn');
        }
      }
      return c;
    },
  },
  props: {
    contactId: {
      type: Number,
      required: true,
      // default: () => 0,
    },
    callId: {
      type: Number,
      required: true,
    },
    phoneNumber: String,
    callerName: String,
    agentDevice: Object, // call device soft phone if we have one
    /* This will have the form of {
      activeCall: twilio only,
      provider: twilio | bandwidth,
      device: Object
    */
  },
  watch: {
    activeCall(c) {
      if (!c) {
        return;
      }
    },
    contactSegment(seg) {
      // Dial ring is done client side since bandwidth cant stop play in a conference
      if (!this.agentDevice || this.agentDevice.provider != 'bandwidth') {
        return;
      }
      if (
        seg &&
        seg.direction == 'outbound' &&
        seg.segment_status == 'answered'
      ) {
        this.ringAudio.pause();
        clearInterval(this.ringInterval);
        this.ringInterval = undefined;
        return;
      }

      if (
        seg &&
        seg.direction == 'outbound' &&
        seg.segment_status == 'ringing'
      ) {
        // Play ring right away too
        this.ringAudio.play();
        if (!this.ringInterval) {
          this.ringInterval = setInterval(() => {
            // Ring until the contact segment is answered on outbound calls'
            if (
              this.contactSegment == undefined ||
              this.contactSegment.segment_status == 'answered'
            ) {
              this.ringAudio.pause();
              clearInterval(this.ringInterval);
              this.ringInterval = undefined;
              return;
            }
            this.ringAudio.play();
          }, 2000);
        }
        return;
      }
    },
  },
  data() {
    return {
      userId: undefined,
      actions: [],
      // This will have to be dynamic when we don't record by default
      // recordPaused: false,
      recordTimout: undefined, // re-enable recording timeout
      showSegmentControl: false,
      showCallControl: false,
      showTransferSelect: false,
      ringInterval: undefined,
    };
  },
  methods: {
    getIcon(command) {
      switch (command) {
        case 'hold':
          if (!this.contactSegment) {
            return 'holdOff';
          }
          return this.contactSegment.is_hold ? 'holdOn' : 'holdOff';
        case 'mute':
          if (!this.agentSegment) {
            return 'muteOff';
          }
          return this.agentSegment.is_muted ? 'muteOn' : 'muteOff';
        default:
          return command;
      }
    },
    async handleAction(command) {
      // This is actually the 'icon' that comes back not the action/command.
      // A bit of odd coupling again
      switch (command.type) {
        case 'holdOn':
        case 'holdOff':
          const result = await AgentService.holdSegment(
            this.callId,
            // Assume this controls the top contact segments
            this.contactSegment.segment_id,
            !this.contactSegment.is_hold
          );
          break;
        case 'muteOn':
          // NOTE: This seems to be backwards for MuteOn vs MuteOff
          if (
            this.agentDevice != undefined &&
            this.agentDevice.provider == 'bandwidth'
          ) {
            this.agentDevice.device.setMicEnabled(true);
            // this.agentDevice.device.unpublish({audio: true});
          }
          const r1 = await AgentService.muteSegment(
            this.callId,
            this.agentSegment.segment_id,
            !this.agentSegment.is_muted
          );
          break;
        case 'muteOff':
          if (
            this.agentDevice != undefined &&
            this.agentDevice.provider == 'bandwidth'
          ) {
            this.agentDevice.device.setMicEnabled(false);
            // this.agentDevice.device.unpublish({audio: false});
          }
          const r2 = await AgentService.muteSegment(
            this.callId,
            this.agentSegment.segment_id,
            !this.agentSegment.is_muted
          );
          break;
        case 'transfer':
          this.showTransferSelect = !this.showTransferSelect;
          // Expects a bool for the lower components;
          return false;
          break;
        case 'recordOn':
        case 'recordOff':
          // record call
          // If we pressed the recordOff button then we want to turn it back on
          // If we pressed the recordOn button we want to turn it off
          if (this.recordTimeout) {
            clearTimeout(this.recordTimeout);
            this.recordTimeout = undefined;
          }
          const r = await AgentService.pauseRecording(
            this.callId,
            this.activeCall.recording
          );
          if (
            command.type == 'recordOn' &&
            this.getAppConfig &&
            this.getAppConfig.callCenter &&
            this.getAppConfig.callCenter.enableRecordingAfter
          ) {
            this.recordTimeout = setTimeout(async () => {
              if (
                !this.activeCall ||
                !this.agentSegment ||
                this.activeCall.recording === true
              ) {
                this.recordTimeout = undefined;
                return;
              }
              // Unpause recording
              this.$message({
                message: 'Recording is being enabled automatically',
                type: 'warning',
                showClose: true,
              });

              // Just turn it back on
              const r = await AgentService.pauseRecording(this.callId, false);
              this.recordTimeout = undefined;
            }, this.getAppConfig.callCenter.enableRecordingAfter * 1000);
          }
          break;
        case 'end':
        case 'hangup':
          // TODO: make this emit so its the same code in the top layer
          if (
            this.agentDevice != undefined &&
            this.agentDevice.provider == 'twilio' &&
            this.agentDevice.activeCall
          ) {
            this.agentDevice.activeCall.disconnect();
          } else {
            AgentService.hangUp(this.callId, this.agentSegment.segment_id);
          }
          break;
        case 'segmentControl':
          this.showSegmentControl = !this.showSegmentControl;
          break;
        case 'callControl':
          this.showCallControl = !this.showCallControl;
          break;
      }
    },
    async doTransfer(value) {
      const vals = value.split(':');
      const toType = vals[0];
      const toValue = vals[1];
      try {
        switch (toType) {
          case 'directDial':
            const directDial = this.getDirectDials.find((d) => d.id == toValue);
            if (directDial.warm_transfer === true) {
              this.$message({
                message:
                  'Inititated Dial, please wait well we connect the call',
                type: 'info',
              });
            }
            await AgentService.transferCall(
              this.callId,
              toType,
              toValue,
              undefined,
              directDial.warm_transfer,
              undefined
            );
            break;
          case 'dial':
            this.$message({
              message: 'Inititated Dial, please wait well we connect the call',
              type: 'info',
            });
            // All direct dial are warm transfers, you could enter a bad number
            await AgentService.transferCall(
              this.callId,
              toType,
              undefined,
              toValue,
              true,
              undefined
            );
            break;
          default:
            // Queue, and others should just work but extend as needed
            await AgentService.transferCall(
              this.callId,
              toType,
              toValue,
              undefined,
              false,
              undefined
            );
            break;
        }
      } catch (e) {
        // sometimes we get details, but it seems to be from 422 inputs badness only
        let details = '';
        if (e.response.data.details) {
          for (const [k, v] of Object.entries(e.response.data.details)) {
            details += "'" + k + "':" + v.message + ', ';
          }
        }
        this.$message({
          message:
            'Error Transferring Call: ' + (details ? details : e.response.data),
          type: 'error',
        });
      }
      this.showTransferSelect = false;
      return false;
    },
  },
  created() {
    const ringUrl = this.getAppConfig?.callCenter?.ringAudio ?? RING_URL;
    this.ringAudio = new Audio(ringUrl);
    this.userId = this.getLoginAccount.userId;
    this.actions = ['hold', 'mute', 'transfer', this.activeCall?.recording ? 'recordOn' : 'recordOff', 'end', 'segmentControl'];

    if (this.agentDevice) {
      this.actions.push('callControl');
    }
  },
};
</script>
<style lang="scss" scoped>
.active-call-dialog {
  // background: rgb(245, 245, 245);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: flex-start;
  .active-call-button {
    margin-left: 10px;
  }
}
</style>
