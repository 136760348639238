<template>
  <el-select
    size="small"
    placeholder="Please select an email"
    @change="doChange"
    v-model="internalValue"
  >
    <el-option
      v-for="option in getVerifiedEmails"
      :value="option.id"
      :label="option.value"
      :key="option.id"
    />
  </el-select>
</template>
<script>
import companyServices from 'src/services/companyServices';

export default {
  name: 'emailFromSelect',
  props: {
    value: {
      type: Number,
    },
  },
  computed: {
    getVerifiedEmails() {
      return this.domains.filter((d) => d.type == 'email' && d.verified);
    },
  },
  data() {
    return {
      loading: true,
      internalValue: undefined,
      domains: [],
    };
  },
  watch: {
    value(n) {
      this.internalValue = parseInt(n);
    },
  },
  async created() {
    await this.loadDomains();
    if (this.value) {
      this.internalValue = parseInt(this.value);
    }
  },
  methods: {
    async loadDomains() {
      await companyServices.checkDomains();
      const domains = await companyServices.listDomains('email');
      this.domains = domains.data;
    },
    doChange() {
      this.$emit('update:modelValue', this.internalValue);
      this.$emit('change', this.internalValue);
    },
  },
};
</script>
