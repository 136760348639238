<template>
  <el-select
    v-model="selectedId"
    @change="doChange"
    :placeholder="placeholder"
    :size="size"
  >
  <!--
    <el-option v-if="allowAll" :value="0" :label="'All Caller Results'"></el-option>
  -->
    <el-option
      v-for="option in getCallerIds"
      :value="option.id"
      :key="option.id"
      :label="option.label"
    >
      <!-- TODO add label color -->
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'callerIdSelect',
  computed: {
    // ...mapGetters('callerIds', ['getCallerIds']),
    // Eventually caller ids
    ...mapGetters('phoneNumbers', ['getCallerIds']),
  },
  props: {
    initialId: {
      type: Number,
      default: () => undefined,
    },
    size: {
      type: String,
      default: () => 'small',
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Select a Caller Id',
    },
    allowAll: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      selectedId: undefined,
    };
  },
  watch: {
    value(n) {
      this.selectedIndex = parseInt(n);
    },
    initialId(n) {
      this.selectedId = parseInt(n);
    },
  },
  async created() {
    // await this.fetchCallerIds();
    if (this.initialId) {
      this.selectedId = parseInt(this.initialId);
    }
  },
  methods: {
    //...mapActions('callerIds', ['fetchCallerIds']),
    doChange() {
      const num = this.getCallerIds.find((c) => c.id == this.selectedId);
      this.$emit('update:modelValue', num ? num.id : undefined);
      this.$emit('change', num ? num.id : undefined);
    },
  },
};
</script>

