<template>
  <div>
    <template v-if="loading">
      <send-loading :loading="loading"></send-loading>
    </template>
    <template v-if="!loading">
      <div style="display: flex; justify-content: space-between">
        <div>
          <h4
            style="
              margin-bottom: 0.5em;
              margin-top: 0;
              padding: 0;
              display: block-inline;
            "
          >
            <template v-if="showContactName && !contact.display_name">
              {{ contact.first_name }} {{ contact.last_name }}
            </template>
            <template v-if="!showContactName && contact.display_name">
              {{ contact.display_name }}
            </template>
            <template v-if="!showContactName && !contact.display_name">
              Viewing Contact
            </template>
          </h4>
        </div>
        <el-row justify="end" align="middle">
          <span>
            <template v-if="isAdmin">
              <el-checkbox
                v-model="contact.is_locked"
                @change="lockContact()"
                label="Locked"
              >
              </el-checkbox>
              <el-divider direction="vertical"></el-divider>
            </template>
            <template
              v-if="contact.primaryEmail && contact.primaryEmail.validated_at"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="'Validated on ' + contact.primaryEmail.validated_at"
                placement="bottom"
              >
                <el-tag type="success" effect="plain"> Validated </el-tag>
              </el-tooltip>
            </template>
            <template
              v-if="!contact.primaryEmail || !contact.primaryEmail.validated_at"
            >
              <el-tooltip
                v-if="
                  contact.primaryEmail && !contact.primaryEmail.validated_at
                "
                type="error"
                effect="dark"
                :content="
                  contact.primaryEmail.validation_error || 'Not Validated'
                "
              >
                <el-tag type="error" effect="plain"> Not Validated </el-tag>
              </el-tooltip>
            </template>
          </span>
          <el-divider direction="vertical"></el-divider>
          <el-button v-if="contact.place_id" @click="showOthers()" :size="'small'">
            Find others near by
          </el-button>
          <el-dropdown
            v-if="
              !activeContactCall &&
              canMakeCalls &&
              (contact.mobile_phone || contact.home_phone)
            "
            :size="'small'"
            split-button
            @click="makeCall(id, contact.mobile_phone)"
          >
              <font-awesome-icon icon="phone" />
            Call {{ getDefaultCall }}

            <template #dropdown>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="contact.home_phone"
                  @click="makeCall(id, contact.home_phone)"
                  :icon="OfficeBuilding"
                  >Call Office</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="contact.mobile_phone"
                  @click="makeCall(id, contact.mobile_phone)"
                  :icon="Cellphone"
                  >Call Mobile</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-divider direction="vertical"></el-divider>
          <el-button
            :size="'small'"
            :disabled="contact && contact.is_locked"
            @click="
              $router.push(
                '/admin/' + $route.params.contactType + '/edit/' + id
              )
            "
          >
            {{ contact && contact.is_locked ? 'Locked' : 'Edit' }}
          </el-button>
        </el-row>
      </div>

      <el-row>
        <el-col :span="12">
          <el-row v-if="showContactName" class="row">
            <el-col :span="8">Name:</el-col>
            <el-col :span="16">
              {{ contact.first_name }} {{ contact.last_name }}
            </el-col>
          </el-row>
          <el-row v-if="contact.primaryEmail" class="row">
            <el-col :span="8">Email:</el-col>
            <el-col :span="16">{{ contact.primaryEmail.email_address }}</el-col>
          </el-row>

          <el-row v-if="contact.mobile_phone" style="margin-top:15px;">
            <el-col :span="8">Mobile:</el-col>
            <el-col :span="16">
              {{ formatPhoneNumber(contact.mobile_phone) }}
            </el-col>
          </el-row>
          <el-row v-if="contact.home_phone">
            <el-col :span="8">Office:</el-col>
            <el-col :span="16">
              {{ formatPhoneNumber(contact.home_phone) }}
            </el-col>
          </el-row>

          <el-row v-if="showAddress">
            <el-col :span="8">Address:</el-col>
            <el-col :span="16">
              {{ contact.address_1 }}
              {{ contact.address_2 }}
              <br />
              {{ contact.locality }}, {{ contact.region }} {{ contact.postal }}
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">Country:</el-col>
            <el-col :span="16">{{ contact.country }}</el-col>
          </el-row>

          <!-- Websites -->
          <template v-if="contact.websites">
            <el-row
              v-for="website in contact.websites"
              :key="contact.id + '-website-' + website.id"
            >
              <el-col :span="8">Website:</el-col>
              <el-col :span="16">
                <el-link :href="website.website" target="_blank"
                  >{{ website.website }}
                </el-link>
              </el-col>
            </el-row>
          </template>

          <el-row v-if="contact.company_name">
            <el-col :span="8">Company Name:</el-col>
            <el-col :span="16">{{ contact.company_name }}</el-col>
          </el-row>
          <el-row v-if="contact.birthday">
            <el-col :span="8">Birthday:</el-col>
            <el-col :span="16">{{ cleanDate(contact.birthday, true, true) }}</el-col>
          </el-row>
          <el-row v-if="contact.anniversary">
            <el-col :span="8">Anniversary:</el-col>
            <el-col :span="16">{{ cleanDate(contact.anniversary, true, true) }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8">Language:</el-col>
            <el-col :span="16">{{ contact.locale }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8">Timezone:</el-col>
            <el-col :span="16">{{ getTimezone(contact.timezone) }}</el-col>
          </el-row>

          <!-- Contacts -->
          <template v-if="relations.length > 0">
            <el-divider content-position="left">Contacts</el-divider>
            <el-row
              v-for="eContact in relations"
              :key="contact.id + '-contact-' + eContact.related_id"
              class="row"
            >
              <el-col :span="8">Contact:</el-col>
              <el-col :span="16">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div>
                    <el-link
                      @click="
                        $router.push(
                          '/admin/contacts/view/' + eContact.related_id
                        )
                      "
                    >
                      {{ eContact.first_name }} {{ eContact.last_name }}
                    </el-link>
                    <span v-if="eContact.mobile_phone">
                      <br />{{ formatPhoneNumber(eContact.mobile_phone) }}
                      <el-button
                        :size="'small'"
                        :link="true"
                        :icon="Cellphone"
                        @click="makeCall(eContact.id, eContact.mobile_phone)"
                      >
                        Call</el-button
                      >
                    </span>
                    <span v-if="eContact.home_phone">
                      <br />{{ formatPhoneNumber(eContact.home_phone) }}
                      <el-button
                        :size="'small'"
                        :link="true"
                        :icon="PhoneFilled"
                        @click="makeCall(eContact.id, eContact.home_phone)"
                      >
                        Call</el-button
                      >
                    </span>
                  </div>
                  <el-button :size="'small'" @click="deleteRelationship(eContact.id)">
                    <font-awesome-icon icon="trash" />
                  </el-button>
                </div>
            </el-col>
            </el-row>
          </template>

          <!-- Owners -->
          <template v-if="contact.owners && contact.owners.length > 0">
            <el-divider content-position="left">Owners</el-divider>
            <el-row
              v-for="owner in contact.owners"
              :key="contact.id + '-owners-' + owner.id">
              <div class="col-md-4">Owner:</div>
              <el-col :span="16">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <el-link>
                    {{ owner.first_name }} {{ owner.last_name }}
                  </el-link>
                  <el-button :size="'small'" @click="deleteOwner(owner.id)">
                    <font-awesome-icon icon="trash" />
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </template>

          <el-divider content-position="left">Public Links</el-divider>

          <!-- Public Profiles -->
          <div>
            <el-link
              :href="
                'https://app-api.sendsquared.com/v1/pub/manage/' +
                getCompanyUuid +
                '/' +
                profileId +
                '/' +
                contact.unsubscribe_token
              "
              target="_blank"
            >
              Public Profile
            </el-link>
          </div>
          <!-- Referral pages -->
          <div v-for="referral in referrals" :key="referral.id + 'referral'">
            <el-link
              :href="
                'https://app-api.sendsquared.com/v1/pub/referral/' +
                getCompanyUuid +
                '/' +
                referral.uuid +
                '/' +
                contact.unsubscribe_token
              "
              target="_blank"
            >
              Loyalty {{ referral.name }} Dashboard
            </el-link>
          </div>

          <el-divider content-position="left">Relationship</el-divider>
          <div style="display: flex; justify-content: space-around; margin-bottom:20px;">
            <el-button :size="'small'" @click="addAssociation('contact')">
              Add Contact
            </el-button>
            <el-button :size="'small'" @click="addAssociation('company')">
              Add Company
            </el-button>
            <el-button :size="'small'" @click="addOwner()"> Add Owner </el-button>
          </div>
        </el-col>
        <el-col :span="12">
          <!-- Photos -->
          <el-carousel
            v-if="contact.photos && contact.photos.length > 0"
            :indicator-position="'none'"
            height="200px"
          >
            <el-carousel-item
              v-for="(item, index) in contact.photos[0].photos"
              :key="contact.id + '-photos-' + item.id + index"
              :style="
                'background-repeat: no-repeat; background-position: center center; background-size: contain;' +
                'background-image: url(' +
                'https://maps.googleapis.com/maps/api/place/photo?maxwidth=1200&photoreference=' +
                item.photo_reference +
                '&key=' +
                getGoogleApiKey +
                ')'
              "
            >
            </el-carousel-item>
          </el-carousel>

          <!-- Custom Fields -->
          <template
            v-if="
              contact.custom_fields &&
              Object.keys(contact.custom_fields).length > 0
            "
          >
            <el-link @click="showCustom = !showCustom">Show Custom</el-link>
            <el-row
              v-for="custom in getContactCustomFields"
              :key="contact.id + '-custom-' + custom.id"
            >
              <template
                v-if="
                  showCustom === true &&
                  contact.custom_fields &&
                  custom.field_name &&
                  custom.field_name.indexOf('digest') !== 0 &&
                  contact.custom_fields[custom.field_name]
                "
              >
                <el-col :span="12">
                  {{ fixCustomFieldName(custom.field_name) }}:
                </el-col>
                <el-col :span="12">
                  {{ contact.custom_fields[custom.field_name] }}
                </el-col>
              </template>
            </el-row>
          </template>
        </el-col>
      </el-row>
    </template>
    <others-near-card
      :show-dialog="showOthersNearbyForm"
      :place-id="contact.place_id"
    >
    </others-near-card>
    <contact-company-associate-card
      v-if="contact.id"
      :show-dialog="showAssociateCard"
      :relationship-type="relationshipType"
      :contact-id-from="contact.id"
      @set-association="setContactAssociation"
    ></contact-company-associate-card>
    <contact-owners-card
      :show-dialog="showOwnerCard"
      :contact-id="contact.id"
      :owners="contact.owners"
      @set-owner="setOwner"
    ></contact-owners-card>

    <el-dialog :title="'Caller Id Selection'" v-model="showCallerId">
      <caller-id-select
        v-if="requireCallerId"
        @change="setCallerId"
      ></caller-id-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCallerId = false">Cancel</el-button>
        <el-button type="primary" :disabled="!callerId" @click="startCall()">
          Select
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { markRaw } from 'vue';

import {
  Cellphone,
  OfficeBuilding,
  PhoneFilled,
} from '@element-plus/icons-vue';

import { GOOGLE_MAPS_API_KEY } from 'src/constants';

import isoLangs from 'src/assets/data/iso_639-1.json';
import isoCountries from 'src/assets/data/iso_3166-1.json';
import isoTimezones from 'src/assets/data/iso_8601-2.json';

import * as moment from 'moment';

import contactServices from 'src/services/contactServices';
import customFieldServices from 'src/services/customFieldServices';
import referralServices from 'src/services/referralServices.js';

import ContactCompanyAssociateCard from 'src/components/Dashboard/Views/Contacts/Cards/ContactCompanyAssociateCard';
import ContactOwnersCard from 'src/components/Dashboard/Views/Contacts/Cards/ContactOwnerSelectCard';
import ContactEditCard from 'src/components/Dashboard/Views/Contacts/Cards/ContactEditCard';

import CallerIdSelect from 'src/components/UIComponents/CallerIdSelect';
import OthersNearCard from './OthersNearCard';

export default {
  components: {
    CallerIdSelect,
    OthersNearCard,
    ContactCompanyAssociateCard,
    ContactOwnersCard,
    ContactEditCard,
    // Icons
    Cellphone: markRaw(Cellphone),
    OfficeBuilding: markRaw(OfficeBuilding),
    PhoneFilled: markRaw(PhoneFilled),
  },
  props: {
    profileId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      loading: true,

      // Active Contact Call
      //  false - Standard View
      //  true - Active Call & Current Contact
      activeContactCall: false,
      callerId: undefined,
      toNumber: undefined,
      showCallerId: false,
      requireCallerId: false,
      //candEdit: false,

      // show
      showAssociateCard: false,
      showCustom: false,
      showOthersNearbyForm: false,
      showOwnerCard: false,

      // Relationship type
      relationshipType: 'contact',

      isoLangs: isoLangs,
      isoCountries: isoCountries,
      isoTimezones: isoTimezones,
      id: 0,
      company: {},
      referrals: [],
      contact: {
        id: null,
        display_name: '',
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        locality: '',
        region: '',
        postal: '',
        country: 'US',
        home_phone: undefined,
        mobile_phone: undefined,
        email: undefined,
        locale: 'en',
        timezone: '',

        unsubscribe_token: '',

        // Validation
        email_validated: undefined,
        email_validation_error: undefined,

        last_open_email: undefined,
        last_open_sms: undefined,
        last_sent_email: undefined,
        last_sent_sms: undefined,

        custom_fields: {},
      },
      relations: [],
      // icons
      OfficeBuilding,
      Cellphone,
      PhoneFilled,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'getAcls',
      'getLoginAccountName',
      'getLoginAccount',
      'getCompanyUuid',
    ]),
    ...mapGetters('realtime', ['getActiveCalls']),
    ...mapGetters('phoneNumbers', ['getPhoneNumbers', 'getCallerIds']),
    ...mapGetters('customFields', ['getContactCustomFields']),
    isAdmin() {
      return this.getAcls('is_agent_admin');
    },
    showAddress() {
      const check = ['address_1', 'address_2', 'locality', 'region', 'postal'];
      return this.checkContactFieldExists(check);
    },
    showContactName() {
      const check = ['first_name', 'last_name'];
      return this.checkContactFieldExists(check);
    },
    canMakeCalls() {
      return this.getPhoneNumbers.length;
    },
    getDefaultCall() {
      if (this.contact.home_phone) return 'office';
      if (this.contact.mobile_phone) return 'mobile';
    },
    getGoogleApiKey() {
      return GOOGLE_MAPS_API_KEY;
    },
  },
  watch: {
    profileId(n) {
      if (n) {
        this.loadContact();
      }
    },
  },
  async mounted() {
    this.requireCallerId = this.getCallerIds.length > 1;
    await this.loadContact();
  },
  methods: {
    ...mapActions('contacts', ['deleteContactCache']),
    setCallerId(callerId) {
      this.callerId = callerId;
    },
    async startCall() {
      if (this.requireCallerId && !this.callerId) {
        this.$message({
          type: 'error',
          message: 'Must select a Caller Id Number',
          showClose: true,
        });
        console.error('no caller id selected');
        return false;
      }
      if (!this.toNumber) {
        this.$message({
          type: 'error',
          message: 'No To number set',
          showClose: true,
        });
        console.error('no to Number set');
        return false;
      }

      this.showCallerId = false;

      await this.callContact(this.id, this.toNumber, this.callerId);
      // reset after call
      this.callerId = undefined;
      this.toNumber = undefined;
    },

    async makeCall(id, toNumber) {
      this.callerId = undefined;
      this.toNumber = toNumber;
      if (this.requireCallerId) {
        this.showCallerId = true;
        // Call will be made after modal close
      } else {
        // Gut direct call, without a caller id
        await this.startCall();
      }
    },
    async lockContact() {
      // Technically on error/fail this could get out of sync but eh, just refresh
      // Its updated by the v-model before we get in here
      try {
        await contactServices.lockContact(
          this.contact.id,
          this.contact.is_locked
        );
      } catch (e) {
        this.$message({
          type: 'error',
          message:
            'Error locking contact: ' +
            (e.response ? e.response.data : 'Unknown'),
          showClose: true,
        });
        // Log trace or just throw again to force the trace?
        // console.trace(e);
        throw e;
      }
    },
    async loadContact() {
      this.loading = true;
      const offset = -(new Date().getTimezoneOffset() / 60);
      for (const inc in isoTimezones) {
        if (isoTimezones[inc].offset === offset) {
          this.contact.timezone = isoTimezones[inc].key;
          break;
        }
      }
      if (this.profileId) {
        this.id = +this.profileId;
        const contactData = await contactServices.fetchContactById(this.id);
        this.contact = contactData.data;
      }

      // Referral
      const referrals = await referralServices.fetchReferrals();
      this.referrals = referrals.data;

      // Active Disposition

      if (
        this.id &&
        this.getActiveCalls[0] &&
        this.getActiveCalls[0].contact_id === this.id
      ) {
        this.activeContactCall = true;
      }
      await this.loadContactRelations();
      this.loading = false;
    },
    async loadContactRelations() {
      const relations = await contactServices.fetchRelations(this.id);
      // Map the 'related_id' to the contact regardless of the owning side
      // Eventually we will brand these differently, ie Parent of Vs Child of
      // But we need to know the mapping labels based on the relationship definitions
      relations.data.map((r) => {
        r.related_id =
          r.contact_id_to == this.id ? r.contact_id_from : r.contact_id_to;
      });
      this.relations = relations.data;
    },
    addAssociation(associationType) {
      this.relationshipType =
        associationType === 'contact' ? 'contact' : 'company';
      this.showAssociateCard = !this.showAssociateCard;
    },

    async patchContact(field) {
      if (this.contact.is_locked) {
        this.$message({
          type: 'error',
          message: 'Contact is locked and can not be updated',
          showClose: true,
        });
        return false;
      }
      const patch = {};
      if (this.contact[field] === '') {
        this.contact[field] = null;
      }
      patch[field] = this.contact[field];
      try {
        await contactServices.patchContact(this.id, patch);
        this.deleteContactCache(+this.id);
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'Error updating contact: ' + e.response.data,
          showClose: true,
        });
      }
    },

    async setContactAssociation(assocObj) {
      if (!assocObj) {
        this.$message({
          type: 'error',
          message: "Couldn't add Relationship",
          showClose: true,
        });
        return false;
      }
      this.$message({
        type: 'info',
        message: 'Relationship added',
        showClose: true,
      });
      await this.loadContactRelations();
      this.showAssociateCard = !this.showAssociateCard;
    },
    getTimezone(id) {
      for (const inc in isoTimezones) {
        if (isoTimezones[inc].key === id) {
          return isoTimezones[inc].text;
        }
      }
    },
    editContact() {
      this.$router.push(
        '/admin/' + this.$route.params.contactType + '/edit/' + this.id
      );
    },
    preferredTime(inputTime) {
      const dateStr = moment.utc().toISOString().split('T').shift();
      const stillUtc = moment.utc(dateStr + ' ' + inputTime).toDate();
      const local = moment(stillUtc).local().format('hh:mm:ss a Z');
      const localCode = moment(stillUtc).local().zoneAbbr();
      return local + ' ' + localCode;
    },
    checkContactFieldExists(check) {
      for (const checkField of check) {
        if (
          this.contact[checkField] != undefined &&
          this.contact[checkField].length > 0
        )
          return true;
      }
      return false;
    },
    fixCustomFieldName(i) {
      if (i !== null) return i.replace(/_/gi, ' ');
    },

    showOthers() {
      this.showOthersNearbyForm = !this.showOthersNearbyForm;
    },

    async deleteRelationship(relationId) {
      this.$confirm('This will delete the relationship.', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(async () => {
          // Remove the id from the listed contact.
          await contactServices.deleteRelation(relationId);
          this.$message({
            type: 'success',
            message: 'Relationship Deleted',
            showClose: true,
          });
          await this.loadContactRelations();
        })
        .catch((e) => {
          this.$message({
            type: 'info',
            message: 'Relationship Deleted canceled',
            showClose: true,
          });
        });
    },

    /**
     * Show Owner form
     */
    addOwner() {
      this.showOwnerCard = !this.showOwnerCard;
    },
    async setOwner(source) {
      // Service call back to
      const owners = Object.assign([], this.contact.owners);
      owners.push({ id: source.ownerId });
      await contactServices.patchContact(this.id, { owners: owners });
      this.showOwnerCard = false;
      this.loadContactRelations();
    },
    /**
     * Remove the id from the listed Owners.
     */
    async deleteOwner(id) {
      const owners = this.contact.owners.filter((owner) => {
        if (+owner.id !== +id) return owner;
      });
      await contactServices.patchContact(this.id, { owners: owners });
    },
  },
};
</script>
