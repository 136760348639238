<template>
  <el-dialog
    title="Sending a test message"
    v-model="showDialog"
    :modalAppendToBody="false"
    id="test1"
  >
    <p>
      Use the form below to add
      {{ getEditorType === 'sms' ? 'phone numbers' : 'emails' }} to the pool.
      Quick send to the right of each
      {{ getEditorType === 'sms' ? 'phone number' : 'email' }}, or send to the
      entire group.
    </p>

    <el-form label-width="90px" v-if="getEditorType === 'email'" :inline="true">
      <el-form-item label="To Email:">
        <el-input
          v-model="testEmail"
          type="email"
          autocomplete="off"
          size="small"
          placeholder="add email..."
          :style="validEmail ? 'color:lightgreen !important;' : ''"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          :disabled="!validEmail"
          @click="addEmailToList()"
          >Add to Test List Below</el-button
        >
      </el-form-item>
    </el-form>

    <!-- From Address -->
    <el-form label-width="90px" v-if="getEditorType === 'email'" :inline="true">
      <el-form-item label="From Email:">
        <email-from-select
          v-model="fromAddressId"
          :select-default="true"
          style="width: 300px"
        ></email-from-select>
      </el-form-item>
    </el-form>

    <el-form v-if="getEditorType == 'email'" label-width="90px" size="small">
      <el-form-item label="Subject:">
        <el-input
          v-model="testEmailSubject"
          autocomplete="off"
          placeholder="add subject..."
          style="width: 80% !important"
          size="small"
        ></el-input>
      </el-form-item>
      <!-- We have issues storing the preview line here.  Because it's set through an API call to  -->
      <el-form-item v-if="!this.emailTemplate" label="Preview line:">
        <el-input
          v-model="testPreviewLine"
          type="textarea"
          autocomplete="off"
          placeholder=".. preview line"
          style="width: 80% !important"
          size="small"
        ></el-input>
      </el-form-item>
    </el-form>

    <div v-if="getEditorType === 'email'">Email List:</div>
    <el-form v-if="getEditorType === 'sms'" :inline="true">
      <el-form-item label="Phone Number">
        <el-input
          v-model="testText"
          type="text"
          autocomplete="off"
          placeholder="add phone number..."
          :style="validPhone ? 'color:lightgreen !important;' : ''"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button :disabled="!validPhone" @click="addPhoneToList()"
          >Add to List</el-button
        >
      </el-form-item>
    </el-form>
    <!--  -->
    <div
      v-if="
        getEditorType === 'email' &&
        testEmailSubject.length < 3 &&
        emailTemplate.subject.length < 3
      "
    >
      <el-alert
        title="warning you are trying to send this template with a very short, or no subject line at all."
        type="warning"
        show-icon
      ></el-alert>
    </div>

    <el-table v-if="getEditorType === 'email'" :data="getTestEmails">
      <el-table-column property="send" label="Send" align="left" width="80">
        <template #default="scope">
          <el-checkbox
            :checked="scope.row.send === true"
            @change="changeSendStatus(scope.row.email)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column property="email" label="Email"></el-table-column>
      <el-table-column align="right">
        <template #default="scope">
          <el-button-group>
            <el-button size="small" @click="sendTestMessage(scope.row.email)"
              >Quick Send</el-button
            >
            <el-button
              size="small"
              :icon="Delete"
              @click.once.native="removeTestMessage(scope.row.email)"
            ></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <div v-if="getEditorType === 'sms'">Phone List:</div>
    <el-table
      v-if="getEditorType === 'sms' && getTestPhoneNumbers.length > 0"
      :data="getTestPhoneNumbers"
    >
      <el-table-column property="send" label="Send" align="left" width="80">
        <template #default="scope">
          <el-checkbox
            :checked="scope.row.send === true"
            @change="changeSendStatus(scope.row.phone)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column property="phone" label="Phone Numbers"></el-table-column>
      <el-table-column align="right">
        <template #default="scope">
          <el-button-group>
            <el-button size="small" @click="sendTestMessage(scope.row.phone)"
              >Quick Send</el-button
            >
            <el-button
              size="small"
              :icon="Delete"
              @click.once.native="removeTestMessage(scope.row.phone)"
            ></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button
          :disabled="disableSendButton"
          type="primary"
          @click="sendTestMessage()"
          >Send to {{ countTestMessageChecked }} above</el-button
        >
      </div></template
    >
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { markRaw } from 'vue';

import { Delete } from '@element-plus/icons-vue';

import { validateEmail } from 'src/shared/validateEmail';
import { validatePhone } from 'src/shared/validatePhone';
import templateService from 'src/services/templateServices';
import companyServices from 'src/services/companyServices';
import EmailFromSelect from 'src/components/UIComponents/EmailFromSelect';

export default {
  components: {
    EmailFromSelect,
    // Icons
    Delete: markRaw(Delete),
  },
  props: {
    emailTemplate: {
      type: Object,
    },
    getEditorType: {
      type: String,
      default: () => {
        return 'email';
      },
    },
    toggleDialog: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    smsMessageId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters('editor', [
      'getTemplateId',
      'getEditorSubject',
      'getEditorPreview',
      'getControlPaneShow',
      'getEditorName',
      'getTestEmails',
      'getTestPhoneNumbers',
    ]),
    disableSendButton() {
      let totalCount = 0;
      let checked = 0;
      let source =
        this.getEditorType === 'email'
          ? this.getTestEmails
          : this.getTestPhoneNumbers;
      for (const emails of source) {
        if (emails.send) {
          checked++;
        }
        totalCount++;
      }

      if (checked === 0) {
        return true;
      }
      if (this.getEditorType === 'email' && !this.fromAddressId) {
        return true;
      }

      return false;
    },
    countTestMessageChecked() {
      let totalCount = 0;
      let checked = 0;
      let source =
        this.getEditorType === 'email'
          ? this.getTestEmails
          : this.getTestPhoneNumbers;
      for (const emails of source) {
        if (emails.send) {
          checked++;
        }
        totalCount++;
      }

      return totalCount === checked ? 'all' : 'the ' + checked;
    },
  },
  data() {
    return {
      // Temp store
      testEmail: undefined,
      testText: undefined,

      // From Address
      fromAddressId: undefined,

      //Subject & Preview Line
      testEmailSubject: '',
      testPreviewLine: '',

      // Valid
      validEmail: false,
      validPhone: false,

      accountType: undefined,
      contactsExpunged: false,

      // send dialog
      showDialog: false,
      // Icons
      Delete,
    };
  },
  watch: {
    testEmail(n, o) {
      if (validateEmail(n)) {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
    },
    testText(n, o) {
      if (validatePhone(n)) {
        this.validPhone = true;
      } else {
        this.validPhone = false;
      }
    },
    async toggleDialog() {
      // Hacky approach.
      // We only allow this button to work in the template editor
      if (
        this.$route.name !== 'Template Editor' &&
        this.$route.name !== 'Email Editor' &&
        this.$route.name !== 'SMS Template Editor'
      ) {
        // We've had issues with the toggle display showing on
        //   none qualified pages.
        return;
      }
      this.showDialog = !this.showDialog;

      // Load The Subject line only if the template doesn't exist
      if (!this.emailTemplate && this.getEditorType == 'email') {
        await this.loadEditorSubject({ id: this.$route.params.id });
        this.testEmailSubject = this.getEditorSubject;
        this.testPreviewLine = this.getEditorPreview;
      }
    },
  },
  async mounted() {
    if (this.getEditorType == 'email') {
      this.fromAddressId = await this.primaryEmail();
    }

    // Hacky approach.
    // We only allow this button to work in the template editor
    if (
      this.$route.name !== 'Template Editor' ||
      this.$route.name !== 'Email Editor'
    ) {
      return;
    }

    // Load The Subject line only if the template doesn't exist
    if (!this.emailTemplate && this.getEditorType == 'email') {
      await this.loadEditorSubject({ id: this.$route.params.id });
      this.testEmailSubject = this.getEditorSubject;
      this.testPreviewLine = this.getEditorPreview;
    }
  },
  methods: {
    ...mapActions('editor', [
      'saveTemplate',
      'addTestEmail',
      'addTestPhoneNumber',
      'removeTestMessageContact',
      'changeTestMessageStatus',
      'loadEditorSubject',
    ]),
    async addEmailToList() {
      if (this.validEmail) {
        this.addTestEmail({
          send: true,
          email: this.testEmail,
        });
        this.testEmail = '';
      } else {
        this.$message.error('Email address is not valid');
      }
    },
    async addPhoneToList() {
      if (this.validPhone) {
        this.addTestPhoneNumber({
          send: true,
          phone: this.testText,
        });
        this.testText = '';
      } else {
        this.$message.error('Phone number is not valid');
      }
    },
    async removeTestMessage(contact) {
      let transport = {
        type: this.getEditorType,
        contact: contact,
      };
      const status = await this.removeTestMessageContact(transport);
      if (!status) {
        this.$message.error("We can't find that one... 🤷‍♂️");
      }
    },
    async changeSendStatus(contact) {
      let transport = {
        type: this.getEditorType,
        contact: contact,
      };
      const status = await this.changeTestMessageStatus(transport);
      if (!status) {
        this.$message.error("We can't find that email... 🤷‍♂️");
      }
    },
    async sendTestMessage(singleContact) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });

      let templateId;
      let emails = [];
      let phones = [];
      switch (this.getEditorType) {
        case 'email':
          for (const email of this.getTestEmails) {
            if (email.send) {
              emails.push(email.email);
            }
          }
          if (singleContact !== undefined && validateEmail(singleContact)) {
            emails = [];
            emails.push(singleContact);
          }

          // this saves the old template
          if (!this.emailTemplate) {
            templateId = await this.saveTemplate({
              type: this.getEditorType,
              system: false,
            });
          } else {
            if (this.emailTemplate.template_type == 'legacy') {
              throw 'legacy templates need to be saved';
            }
            templateId = this.emailTemplate.id;
          }
          break;

        case 'sms':
          for (const phone of this.getTestPhoneNumbers) {
            if (phone.send) {
              phones.push(phone.phone);
            }
          }
          if (singleContact !== undefined && validatePhone(singleContact)) {
            phones = [];
            phones.push(singleContact);
          }
          //
          templateId = this.smsMessageId;
          break;
      }

      // save template
      try {
        await templateService.sendTestMessage(
          templateId,
          emails.length > 0 ? emails : undefined,
          phones.length > 0 ? phones : undefined,
          this.fromAddressId,
          this.testEmailSubject,
          this.testPreviewLine
        );
        this.$emit('update:valid', {
          message: 'Message is being queued for delivery',
          messageType: 'success',
        });
      } catch (e) {
        this.handleServiceError(e);
      } finally {
        loading.close();
      }
      this.resetDialog();
    },

    resetDialog() {
      this.testEmail = '';
      this.showDialog = false;
    },

    handleServiceError(error) {
      const status = error.response?.status;
      const message =
        status === 422 ? error.response.data.message : error.response.data;
      this.$emit('update:dirty', { message, messageType: 'error' });
      this.showDialog = false;
    },

    async primaryEmail() {
      const domains = await companyServices.listDomains('email');
      const primaryEmail = domains.data.find(
        (d) => d.primary == true && d.type == 'email' && d.verified
      );
      return primaryEmail?.id;
    },
  },
};
</script>
