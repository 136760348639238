import { S2_EMAIL_TEMPLATES_PATH } from '../constants';
import { authedAxios } from '../shared/axiosWrapper';

export default {
  fetchTemplates(archive) {
    const params = {
      archived: false,
      is_system: false,
      limit: 1000,
      sort: 'name:asc',
    };
    // FIXME: confirm we need this.
    if (archive != undefined) {
      params['archived'] = archive;
    }

    return authedAxios.get(S2_EMAIL_TEMPLATES_PATH, { params: params });
  },
  fetchReplyTemplates() {
    const params = {
      is_reply: true,
      is_system: false,
      limit: 1000,
      sort: 'name:asc',
    };
    return authedAxios.get(S2_EMAIL_TEMPLATES_PATH, { params: params });
  },
  fetchTemplate(id) {
    return authedAxios.get(S2_EMAIL_TEMPLATES_PATH + id);
  },
  getSignedUrlUpload(id, contentType) {
    return authedAxios.post(S2_EMAIL_TEMPLATES_PATH + 'signed-url', {
      id,
      contentType,
    });
  },
  newTemplate(payload) {
    return authedAxios.post(S2_EMAIL_TEMPLATES_PATH, payload);
  },
  getSignedUrlDownload(id) {
    return authedAxios.get(S2_EMAIL_TEMPLATES_PATH + `signed-url/${id}`);
  },
  saveTemplate(id, payload) {
    return authedAxios.put(S2_EMAIL_TEMPLATES_PATH + id + '/', payload);
  },
  duplicateTemplate(id, name) {
    return authedAxios.post(S2_EMAIL_TEMPLATES_PATH + 'duplicate/' + id, {
      name: name,
    });
  },
  systemTemplate(id, payload) {
    return authedAxios.post(S2_EMAIL_TEMPLATES_PATH + 'system/' + id, payload);
  },
  templateStoreComplete(id) {
    return authedAxios.put(S2_EMAIL_TEMPLATES_PATH + id + '/complete');
  },
  renameTemplate(id, name) {
    return authedAxios.patch(S2_EMAIL_TEMPLATES_PATH + id + '/rename', {
      name,
    });
  },
  updateSubject(id, subject) {
    return authedAxios.patch(S2_EMAIL_TEMPLATES_PATH + id + '/subject', {
      subject,
    });
  },
  updatePreview(id, preview) {
    return authedAxios.patch(S2_EMAIL_TEMPLATES_PATH + id + '/preview', {
      preview,
    });
  },
  archiveTemplate(id, archive = true) {
    return authedAxios.patch(S2_EMAIL_TEMPLATES_PATH + id + '/archive', {
      is_archive: archive,
    });
  },
  replyTemplate(id, reply) {
    return authedAxios.put(S2_EMAIL_TEMPLATES_PATH + id + '/reply', {
      is_reply: reply,
    });
  },
  changeFolder(id, folderId) {
    return authedAxios.put(S2_EMAIL_TEMPLATES_PATH + id + '/folder', {
      folder_id: folderId,
    });
  },
  patchTemplate(templateId, payload) {
    return authedAxios.patch(S2_EMAIL_TEMPLATES_PATH + templateId, payload);
  },

  // Gallery
  async getGallery({
    type,
    sort,
    limit,
    page,
    templateTypes,
    templateSeasons,
    templateFeatures,
    templateIndustries,
  }) {
    return authedAxios.get(S2_EMAIL_TEMPLATES_PATH + 'public-templates', {
      params: {
        type,
        sort,
        limit,
        page,
        templateTypes,
        templateSeasons,
        templateFeatures,
        templateIndustries,
      },
    });
  },
  async fetchGalleryTemplate(id) {
    return authedAxios.get(S2_EMAIL_TEMPLATES_PATH + `public-template/${id}`);
  },

  /**
   * Get Template Merge
   *
   * Required a template and might need to pull data via the backend
   *
   * @param {*} template
   * @param {*} contactId
   * @param {*} campaignId
   * @param {*} reservationId
   * @param {*} leadId
   * @returns
   */
  getTemplateMerge(templateId, contactId, campaignId, reservationId, leadId) {
    const payload = {
      template_id: templateId,
      // Content will be provided with template id of 0
      content: undefined,
    };
    if (contactId) {
      payload.contact_id = contactId;
    }
    if (campaignId) {
      payload.campaign_id = campaignId;
    }
    if (reservationId) {
      payload.reservation_id = reservationId;
    }
    if (leadId) {
      payload.lead_id = leadId;
    }
    return authedAxios.post(S2_EMAIL_TEMPLATES_PATH + 'merge/', payload);
  },
};
