export default [
  {
    name: 'Inbox',
    icon: 'message',
    path: '/admin/inbox',
  },
  {
    name: 'Calls',
    icon: 'phone',
    children: [
      {
        name: 'Queue Dashboard',
        path: '/admin/call-center/call-dashboard',
        letter: 'Q',
      },
      {
        name: 'Call Log',
        path: '/admin/call-center/call-log',
        letter: 'I',
      },
      {
        name: 'Voicemails',
        path: '/admin/voicemail',
        letter: 'V',
      },
      {
        name: 'Leads',
        path: '/admin/call-center/leads',
        letter: 'L',
      },
    ],
  },
  {
    name: 'Reports',
    icon: 'chart-pie',
    children: [
      {
        name: 'Reports',
        path: '/admin/reports',
        letter: 'R',
      },
    ],
  },
  {
    name: 'Campaigns',
    icon: 'trophy',
    children: [
      {
        name: 'Campaigns',
        path: '/admin/campaigns/list',
        letter: 'E',
      },
      {
        name: 'Chat Campaigns',
        path: '/admin/campaigns/chat',
        letter: 'C',
      },
    ],
  },
  {
    name: 'Automations',
    icon: 'signs-post',
    children: [
      /* {
        name: 'Gallery',
        path: '/admin/automations/gallery',
        letter: 'G',
      }, */
      {
        name: 'All Automations',
        path: '/admin/automations/list',
        letter: 'A',
      },
      {
        name: 'New Automations',
        path: '/admin/automations/edit',
        letter: 'N',
      },
    ],
  },
  {
    name: 'Templates',
    icon: 'copy',
    children: [
      // With the conversion to email_templates, this will now be empty.
      /* {
        name: 'Email Templates',
        path: '/admin/templates/list/email',
        letter: 'E',
      }, */
      {
        name: 'Email Templates',
        path: '/admin/templates/list-emails',
        letter: 'E',
      },
      {
        name: 'SMS Templates',
        path: '/admin/templates/list/sms',
        letter: 'S',
      },
      {
        name: 'SMS Chat',
        path: '/admin/templates/sms-chat',
        letter: 'C',
      },
      {
        name: 'Postcards Templates',
        path: '/admin/templates/list-postcards',
        letter: 'p',
        appConfig: 'owner:displaySidebar',
      },

      // {
      //   name: 'Asset Manager',
      //   path: '/admin/assets',
      //   letter: 'A',
      // },
    ],
  },
  {
    name: 'Audience',
    icon: 'user',
    children: [
      {
        name: 'All Contacts',
        path: '/admin/contacts/list?contactType=contact',
        letter: 'A',
      },
      // {
      //   name: 'Dashboard',
      //   path: '/admin/contacts/dashboard',
      //   letter: 'D',
      // },
      {
        name: 'Search',
        path: '/admin/contacts/search',
        letter: 'S',
      },
      {
        name: 'Groups',
        path: '/admin/contacts/groups',
        letter: 'G',
      },
      {
        name: 'Segments',
        path: '/admin/contacts/segments',
        letter: 'S',
      },
      {
        name: 'Tags',
        path: '/admin/contacts/tags',
        letter: 'T',
      },
      {
        name: 'Custom Fields',
        path: '/admin/contacts/fields',
        letter: 'F',
      },
      {
        name: 'Contact Merge',
        path: '/admin/contacts/merge',
        letter: 'M',
        permission: 'merge_contacts',
      },
    ],
  },
  {
    name: 'Sales',
    icon: 'chart-line',
    children: [
      /* {
        name: 'All Companies',
        path: '/admin/companies/list?contactType=company',
        letter: 'C',
      }, */
      {
        name: 'Leads',
        path: '/admin/call-center/leads',
        letter: 'L',
      },
      {
        name: 'Pipeline',
        path: '/admin/pipeline',
        letter: 'P',
      },
      {
        name: 'Tasks',
        path: '/admin/tasks/view',
        letter: 'T',
      },
      {
        name: 'Reservations',
        path: '/admin/reservations',
        letter: 'R',
      },
    ],
  },
  {
    name: 'Owner',
    icon: 'house',
    appConfig: 'owner:displaySidebar',
    children: [
      {
        name: 'Scouting Dashboard',
        path: '/admin/owners/dashboard',
        letter: 'D',
      },
    ],
  },
];
