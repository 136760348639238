<template>
  <el-radio-group v-model="currentValue" size="small" @change="transportProperties()">
    <el-radio-button label="left">
      <font-awesome-icon icon="align-left" />
    </el-radio-button>
    <el-radio-button label="center">
      <font-awesome-icon icon="align-center" />
    </el-radio-button>
    <el-radio-button label="right">
      <font-awesome-icon icon="align-right" />
    </el-radio-button>
  </el-radio-group>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    disabled: Boolean,
    value: {
      required: true,
      default: ''
    },
    transport: {
      type: String,
      required: false,
      default: ''
    },
    useBackground: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('editor', ['getDecodedSelectedBlock'])
  },
  data() {
    return {
      currentValue: this.value,
      inputDisabled: false
    };
  },
  created() {
    if (this.value !== this.currentValue) {
      this.currentValue = this.value;
    }
  },
  watch: {
    value(val) {
      if (val !== this.currentValue) {
        this.currentValue = val;
      }
    }
  },
  methods: {
    ...mapActions('editor', ['transportBlockSettings']),
    transportProperties() {
      let transport = {};
      transport[this.transport] = this.currentValue;
      // Transport
      this.transportBlockSettings({
        section: this.getDecodedSelectedBlock.section,
        row: this.getDecodedSelectedBlock.row,
        column: this.getDecodedSelectedBlock.column,
        data: transport
      });
      this.$emit(
        'editor-redraw-block',
        this.getDecodedSelectedBlock.section +
          '-' +
          this.getDecodedSelectedBlock.row +
          '-' +
          this.getDecodedSelectedBlock.column
      );
    }
  }
};
</script>
