<template>
  <el-dropdown class="wrap-up-drop-down">
    <span class="el-dropdown-link">
      <span class="wrap-ups-text"
        >Wrap Ups ({{ getMyDispositions(getLoginAccount.userId).length }})</span
      >
      <font-awesome-icon icon="chevron-down" class="chevron-icon" />
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="wrapup in getMyDispositions(getLoginAccount.userId)"
          :key="wrapup.id"
          @click.native="viewDisp(wrapup.id)"
        >
          <div class="wrap-up-wrapper">
            <div class="wrap-up-name">
              <contact-field-view
                v-if="wrapup.contact_id"
                :id="wrapup.contact_id"
                :display="['first_name', 'last_name']"
              ></contact-field-view>
            </div>
            <div class="wrap-up-phone">
              <contact-field-view
                v-if="wrapup.contact_id"
                :id="wrapup.contact_id"
                :display="['mobile_phone']"
              ></contact-field-view>
            </div>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ContactFieldView from 'src/components/UIComponents/ContactFieldView';

export default {
  name: 'ActiveWrapUps',
  components: {
    ContactFieldView,
  },
  computed: {
    ...mapGetters('realtime', ['getMyAgentStatus', 'getMyDispositions']),
    ...mapGetters('auth', ['getLoginAccount']),
  },
  methods: {
    ...mapActions('realtime', ['modifyActiveDisposition']),
    async viewDisp(dispId) {
      await this.modifyActiveDisposition(dispId);
    },
  },
};
</script>
<style lang="scss">
.wrap-up-drop-down {
  .wrap-up-wrapper {
    padding-top: 0.7em;
    padding-bottom: 0.7em;
  }
  .wrap-up-name {
    line-height: 16px;
    font-weight: bold;
  }
  .wrap-up-phone {
    line-height: 16px;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
    margin-top: 4px;
    /* TODO: on select/active still shows a blue border.  Need to remove */
  }

  .wrap-up-drop-down .el-dropdown-link {
    display: flex;
    align-items: center;
  }

  .wrap-ups-text {
    /* Ensures the text is displayed by default */
  }

  .chevron-icon {
    margin-left: 3px;
  }

  @media (max-width: 999px) {
    .wrap-ups-text {
      display: none;
    }

    .wrap-up-drop-down .el-dropdown-link::after {
      content: attr(data-tooltip);
      display: block;
      background: #333;
      color: #fff;
      text-align: center;
      padding: 5px;
      border-radius: 4px;
      font-size: 0.75rem;
    }

    .wrap-up-drop-down .el-dropdown-link:hover::after {
      position: absolute;
      margin-top: 25px;
    }
  }
}
</style>
