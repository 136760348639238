<template>
  <el-dropdown class="agent-destination-frame">
    <span class="el-dropdown-link">
      <font-awesome-icon
        icon="house-chimney-window"
        size="2x"
        class="agent-destination-house-icon"
      ></font-awesome-icon>
      <font-awesome-icon icon="chevron-down" style="margin-left: 3px" />
    </span>
    <template #dropdown>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(dest, index) in getDialDestinations"
          v-bind:key="index"
          class="dropdown-item"
          :disabled="dest.id == currentDest.id"
          @click.native="changeDest(dest)"
        >
          <span class="mr-2">{{ dest.name }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';

import AgentService from 'src/services/agentService';

export default {
  name: 'AgentDestination',
  computed: {
    ...mapGetters('realtime', ['getMyAgentStatus']),
    ...mapGetters('auth', ['getLoginAccount']),
    ...mapGetters('dialDestinations', ['getDialDestinations']),
  },
  data() {
    return {
      currentDest: undefined,
    };
  },
  watch: {
    currentAgentStatus(newStatus) {
      if (newStatus.user_id != this.userId) {
        return;
      }
      this.updateStatus(newStatus);
    },
  },
  async created() {
    // Just set it for the first time, assume the primary is set.
    // Eventually we need to link this to the agent status but we dont have any
    // oush updates to track it on.
    this.currentDest = this.getDialDestinations.find((d) => d.is_primary);
  },
  methods: {
    async changeDest(dest) {
      const newToken = await AgentService.setDestination(dest.id);
      this.$emit('newToken', newToken);
      this.currentDest = this.getDialDestinations.find((d) => d.id == dest.id);
    },
  },
};
</script>

<style lang="scss">
.agent-destination-frame {
  margin-top: 10px;
  margin-right: 10px;
  &:focus-visible {
    outline: none;
  }
  .el-dropdown-link {
    &:focus-visible {
      outline: none;
    }
  }
}
.agent-destination-house-icon {
  height: 25px;
}
</style>
