<template>
  <div>
    <!-- <el-row v-if="!showNewNote && !listOnly" justify="end" align="middle">
      <el-button @click="showNewNote = true" size="small">New note</el-button>
    </el-row> -->
    <div v-if="showNewNote">
      <p>New note</p>
      <el-input type="textarea" :rows="10" v-model="newNote"></el-input>
      <el-row justify="end" align="middle" style="margin-top: 10px">
        <el-button size="small" @click="showNewNote = false">Cancel</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="primary" size="small" @click="saveNewNotes()"
          >Save Note</el-button
        >
      </el-row>
    </div>
    <el-table
      :data="
        notes.filter(
          (data) =>
            !search || data.notes.toLowerCase().includes(search.toLowerCase())
        )
      "
      highlight-current-row
      :stripe="true"
      v-loading="loading"
      size="small"
    >
      <el-table-column type="expand">
        <template #default="scope">
          <div style="padding: 10px" class="text-block">
            {{ scope.row.notes }}
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template #header align="left">
          <el-input
            v-if="notes.length > 5"
            v-model="search"
            size="small"
            style="width: 80%"
            placeholder="Type here to search"
          />
        </template>
        <template #default="scope">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="display: flex; align-items: center">
              <el-avatar
                :size="20"
                fit="cover"
                :src="getPhoto(scope)"
              ></el-avatar>
              <span style="margin-left: 8px"
                >{{ getMemberFromId(scope.row.user_id).first_name }}
                {{ getMemberFromId(scope.row.user_id).last_name }}</span
              >
            </div>
            <div>
              <span>{{ cleanNotesTime(scope.row.created_at) }}</span>
            </div>
          </div>
          <div style="padding: 8px">
            {{ previewLine(scope.row.notes, 85) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="!listOnly" align="right" width="100">
        <template #header align="right">
          <el-row v-if="!showNewNote && !listOnly" justify="end" align="middle">
            <el-button @click="showNewNote = true" size="small"
              >New note</el-button
            >
          </el-row>
        </template>
        <template #default="scope">
          <el-button
            size="small"
            @click.native="edit(scope.row.id, scope.row.notes)"
            >Edit</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="Edit Note" v-model="showEditDialog">
      <el-input
        type="textarea"
        :rows="4"
        v-model="editNote"
        class="no-word-break"
      ></el-input>
      <div class="mt-1">
        <el-button type="primary" size="small" @click="updateNote()">
          Update Note
        </el-button>
        <el-button @click="showEditDialog = false" size="small">
          Cancel
        </el-button>
        <div class="pull-right">
          <el-button type="danger" size="small" @click="deleteNote()"
            >Delete</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import notesServices from 'src/services/notesServices';

export default {
  computed: {
    ...mapGetters('team', ['getTeam']),
    ...mapGetters('auth', {
      getProfilePhotoURL: 'getProfilePhotoURL',
    }),
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: () => 'contact',
    },
    notesData: {
      type: Array,
      default: () => [],
    },
    listOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loading: true,
      notes: [],
      showNewNote: false,
      newNote: '',
      editId: 0,
      editNote: '',
      search: '',
      showEditDialog: false,
    };
  },
  watch: {
    id() {
      this.fetchTeam();
      if (this.listOnly) {
        this.notes = this.notesData;
        this.loading = false;
        return;
      }
      this.loadNotes();
    },
  },
  async created() {
    this.fetchTeam();
    if (this.listOnly) {
      this.notes = this.notesData;
      this.loading = false;
      return;
    }
    this.loadNotes();
  },
  methods: {
    ...mapActions('team', ['fetchTeam']),
    edit(id, notes) {
      this.editId = id;
      this.editNote = notes;
      this.showEditDialog = true;
    },
    getMemberFromId(id) {
      for (const member of this.getTeam) {
        if (member.id === id) {
          return member;
        }
      }
      return { first_name: 'unknown', last_name: 'user' };
    },
    getPhoto(scope) {
      if (
        this.getMemberFromId(scope.row.user_id) &&
        this.getMemberFromId(scope.row.user_id).users_meta
      ) {
        return this.getPhotoUrl(
          this.getMemberFromId(scope.row.user_id).users_meta
        );
      }
      return this.getPhotoUrl(undefined);
    },
    getPhotoUrl(s) {
      if (s && s.profilePhoto) {
        return this.getProfilePhotoURL + s.profilePhoto;
      }
      return this.getProfilePhotoURL + 'default-user-profile.png';
    },
    async saveNewNotes() {
      await notesServices.newNote(this.type, this.id, this.newNote);

      this.newNote = '';
      this.showNewNote = false;
      this.loadNotes();
    },
    async loadNotes() {
      if (this.id == 0 || this.id == undefined) {
        this.loading = false;
        return;
      }
      this.loading = true;
      this.notes = [];
      const notes = await notesServices.fetchNotes(this.type, this.id);
      this.notes = notes.data;
      this.loading = false;
    },
    cleanNotesTime(time) {
      return moment(time).local().format('LLLL');
    },
    async updateNote() {
      await notesServices.updateNote(this.editId, this.editNote);
      this.showEditDialog = false;
      this.loadNotes();
    },
    async deleteNote() {
      this.$confirm('Are you sure you want to delete this note?', 'Warning', {
        confirmButtonText: 'Yes, Delete',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(async () => {
          await notesServices.deleteNote(this.editId);
          this.showEditDialog = false;
          this.loadNotes();

          this.$message({
            message: 'Congrats, your note is now deleted. 🙌',
            type: 'success',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          });
        });
    },
    previewLine(input, maxLen, separator = ' ') {
      if (input.length <= maxLen) return input;
      return input.substr(0, input.lastIndexOf(separator, maxLen)) + '...';
    },
  },
};
</script>
<style scoped lang="scss">
.text-block {
  white-space: break-spaces; // or pre-line
  word-break: keep-all;
}
.no-word-break {
  word-break: keep-all;
}
</style>
