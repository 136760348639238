<template>
  <el-select
    v-model="selectedId"
    @change="do_change"
    :placeholder="placeholder"
    :size="size"
  >
      <el-option v-if="allowAll" :value="0" :label="'Any Reason'"></el-option>
    <el-option
      v-for="option in getLostReasons"
      :value="option.id"
      :key="option.id"
      :label="option.label"
    >
      <!-- TODO add label color -->
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'lostReasonSelect',
  computed: {
    ...mapGetters('lostReason', ['getLostReasons']),
  },
  props: {
    // Add v-model support
    value: {
      type: [Number, String],
    },
    initialId: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      default: () => {
        return 'small';
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return 'Select a Lost Reason';
      },
    },
    allowAll: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      selectedId: undefined,
    };
  },
  watch: {
    initialId(id) {
      this.selectedId = id;
    },
    value(n) {
      this.selectedId = parseInt(n);
    },
  },
  async created() {
    if (!this.getLostReasons || this.getLostReasons.length == 0) {
      await this.fetchLostReasons();
    }
    if (this.value != undefined) {
      this.selectedId = parseInt(this.value);
    }
    if (this.initialId) {
      this.selectedId = this.initialId;
    }
  },
  methods: {
    ...mapActions('lostReason', ['fetchLostReasons']),
    do_change() {
      const lostReason = this.getLostReasons.find(
        (s) => s.id == this.selectedId
      );
      this.$emit('update:lostReason', lostReason);
      if (lostReason) {
        this.$emit('update:modelValue', lostReason.id);
        this.$emit('change', lostReason.id);
      }
      else {
        // Because we parse from a string at times 0 instead of undefined
        this.$emit('update:modelValue', 0);
        this.$emit('change', 0);

      }
    },
  },
};
</script>

