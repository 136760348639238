<template>
  <div>
    <el-checkbox v-model="queueOnly" @change="updateCalls"
      >Show Queue Calls Only</el-checkbox
    >
    <el-table :data="activeCalls" size="small" :row-class-name="callRowStatus">
      <!--<el-table-column prop="call_id" label="Call" width="90"></el-table-column>
      -->

      <el-table-column prop="caller_name" label="Name">
        <template #default="props">
          {{ props.row.caller_name }}
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Created">
        <template #default="props">
          {{ cleanDate(props.row.created_at, true) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status"></el-table-column>
      <el-table-column
        prop="duration"
        label="Duration"
        width="100"
      ></el-table-column>
      <el-table-column prop="user_id" label="User">
        <template #default="props">
          <agent-field-view
            v-if="props.row.user_id"
            :agent-id="props.row.user_id"
            :display="['first_name', 'last_name']"
          ></agent-field-view>
        </template>
      </el-table-column>
      <el-table-column prop="queue_id" label="Queue">
        <template #default="props">
          {{ props.row.queue_id }}
          <queue-display
            v-if="props.row.queue_id"
            :queue-id="props.row.queue_id"
          ></queue-display>
        </template>
      </el-table-column>
      <el-table-column
        prop="queue_duration"
        label="Queued"
        width="100"
      ></el-table-column>
      <el-table-column prop="direction" label="Direction"></el-table-column>
      <el-table-column prop="source" label="Source" width="125">
        <template #default="props">
          <lead-source-select
            v-if="props.row.number_id"
            :editable="false"
            :initial-id="
              getPhoneNumbers.find((n) => n.id == props.row.number_id)
                ?.lead_source_id
            "
          ></lead-source-select>
        </template>
      </el-table-column>
      <el-table-column prop="last_action_type" label="Last Action" width="100">
        <template #default="props">
          <span>
            <direct-dial-select
              v-if="props.row.last_action_type == 'directDial'"
              :editable="false"
              :value="props.row.last_action_value"
            >
            </direct-dial-select>
            <menu-select
              v-if="props.row.last_action_type == 'menu'"
              :editable="false"
              :initial-id="parseInt(props.row.last_action_value)"
            >
            </menu-select>
            <queue-display
              v-if="props.row.last_action_type == 'queue'"
              :queue-id="parseInt(props.row.last_action_value)"
            >
            </queue-display>
            <span
              v-if="props.row.last_action_type == 'dial'"
              :initial-id="props.row.last_action_value"
            >
              {{ formatPhoneNumber(props.row.last_action_value) }}
            </span>
            <span
              v-if="
                ['voicemail', 'hangup'].includes(props.row.last_action_type)
              "
              :initial-id="props.row.last_action_value"
            >
              {{ props.row.last_action_type }}
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="220">
        <template #default="props">
          <el-button-group>
            <el-button
              size="small"
              type="primary"
              :disabled="
                isDisabled ||
                props.row.user_id != undefined ||
                !isMyQueue(props.row.queue_id)
              "
              @click="pickup(props.row.call_id)"
            >
              <font-awesome-icon :icon="['fas', 'phone']" />
            </el-button>
            <el-button
              v-if="isAdmin()"
              size="small"
              type="primary"
              :disabled="isDisabled"
              @click="listen(props.row.call_id)"
            >
              <font-awesome-icon :icon="['fas', 'phone-alt']" />
            </el-button>
            <el-button
              v-if="isAdmin()"
              size="small"
              type="warning"
              :disabled="isDisabled"
              @click="ghost(props.row.call_id)"
            >
              <font-awesome-icon :icon="['fas', 'eye']" />
            </el-button>
            <el-button
              v-if="isAdmin()"
              size="small"
              type="danger"
              :disabled="isDisabled"
              @click="barge(props.row.call_id)"
            >
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
            </el-button>
            <el-button
              v-if="isAdmin()"
              size="small"
              type="danger"
              @click="deleteCall(props.row.call_id)"
            >
              <font-awesome-icon :icon="['fas', 'times']" />
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { processError } from 'src/util/processError.js';
import AgentService from 'src/services/agentService';
import AgentFieldView from 'src/components/UIComponents/AgentFieldView';
import QueueDisplay from 'src/components/UIComponents/QueueDisplay';
import LeadSourceSelect from 'src/components/UIComponents/LeadSourceSelect';
import MenuSelect from 'src/components/UIComponents/Inputs/MenuSelect';
import DirectDialSelect from 'src/components/UIComponents/Inputs/DirectDialSelect';

const queueCallStatuses = ['answered', 'queue'];

export default {
  name: 'CallStatusList',
  components: {
    AgentFieldView,
    LeadSourceSelect,
    QueueDisplay,
    MenuSelect,
    DirectDialSelect,
  },
  computed: {
    ...mapGetters('realtime', ['getActiveCalls', 'getMySegments']),
    ...mapGetters('auth', ['getAcls', 'getLoginAccount']),
    ...mapGetters('queues', ['getQueues']),
    ...mapGetters('phoneNumbers', ['getPhoneNumbers']),
    isDisabled() {
      return this.getMySegments(this.userId).length ? true : false;
    },
  },
  props: {},
  data() {
    return {
      activeCalls: [],
      callStatuses: [],
      timer: undefined,
      queueOnly: true,
    };
  },
  watch: {
    // Copy the store values in so we are not updating the store
    // each second and that will cause lots of UI refreshes.
    getActiveCalls: {
      immediate: true,
      deep: true,
      handler(newVals, oldvals) {
        this.activeCalls.splice(0);
        // Define reactive attrs explicitly for el-table to update
        for (const c of newVals) {
          if (this.queueOnly && !queueCallStatuses.includes(c.status)) {
            continue;
          }
          this.activeCalls.push({
            call_id: c.call_id,
            caller_name: c.caller_name,
            status: c.status,
            user_id: c.user_id,
            duration: 0,
            queue_duration: 0,
            queued_at: c.queued_at,
            queue_id: c.queue_id,
            direction: c.direction,
            created_at: c.created_at,
            modified_at: c.modified_at,
            last_action_type: c.last_action_type,
            last_action_value: c.last_action_value,
            number_id: c.number_id,
          });
        }
      },
    },
  },
  async created() {
    this.userId = this.getLoginAccount.userId;
    this.updateTime();
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions('realtime', ['modifySegment']),
    callRowStatus({ row, rowIndex }) {
      if (row.user_id) {
        return 'answered';
      }
      if (row.queue_id) {
        return 'queued';
      }
      return 'call';
    },
    isMyQueue(queueId) {
      if (this.isAdmin()) {
        return true;
      }
      const queues = this.getQueues;
      if (!queues) {
        return false;
      }
      const queue = queues.find((q) => queueId == q.id);
      if (!queue || !queue.agents.find((a) => a.id == this.userId)) {
        return false;
      }

      return true;
    },
    updateTime() {
      const now = new Date();

      this.activeCalls.forEach((c) => {
        const duration = Math.round(now - new Date(c.created_at));
        c.duration = new Date(duration).toISOString().substr(11, 8);
        if (c.queued_at) {
          const queueDate = new Date(c.queued_at);
          if (queueDate.getYear() == 0) {
            c.queue_duration = undefined;
          } else {
            const queue_dur = Math.round(now - queueDate);
            c.queue_duration = new Date(queue_dur).toISOString().substr(11, 8);
          }
        }
      });
      this.activeCalls = this.activeCalls.slice();
    },
    updateCalls() {
      this.activeCalls.splice(0);
      for (const c of this.getActiveCalls) {
        if (this.queueOnly && !queueCallStatuses.includes(c.status)) {
          continue;
        }
        this.activeCalls.push({
          call_id: c.call_id,
          caller_name: c.caller_name,
          status: c.status,
          user_id: c.user_id,
          duration: 0,
          queue_duration: 0,
          queued_at: c.queued_at,
          queue_id: c.queue_id,
          direction: c.direction,
          created_at: c.created_at,
          modified_at: c.modified_at,
          last_action_type: c.last_action_type,
          last_action_value: c.last_action_value,
          number_id: c.number_id,
        });
      }
    },
    isAdmin() {
      return this.getAcls('is_agent_admin');
    },
    async pickup(callId) {
      try {
        await AgentService.pickupCall(callId, this.userId, true, false);
      } catch (e) {
        const errMsg = processError(e.response);

        this.$message({
          type: 'error',
          message: 'Error Taking Call: ' + errMsg,
          showClose: true,
        });
        return false;
      }
    },
    listen(callId) {
      AgentService.listenCall(callId, this.userId, true, false);
    },
    ghost(callId) {
      AgentService.listenCall(callId, this.userId, false); // No announce means ghost
      // We will not publish a client stream
    },
    barge(callId) {
      AgentService.bargeCall(callId, this.userId);
    },
    deleteCall(callId) {
      this.$confirm(
        'This will forcibly terminate a live call. Proceed with caution',
        'Terminate Call',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          AgentService.deleteCall(callId, this.userId);
        })
        .catch((e) => {
          this.$message({
            type: 'info',
            message: 'aborting deleting call',
            showClose: true,
          });
        });
    },
  },
};
</script>

<style>
.status-frame {
  margin-top: 10px;
  margin-right: 15px;
}
.el-table .call {
  background: rgb(245, 245, 245);
}
.el-table .answered {
  background: rgb(218, 236, 218);
}
.el-table .queued {
  background: rgb(245, 232, 232);
}
</style>
