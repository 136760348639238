// Globally load all the font awesome icons were going to use.
// this will allow us to not have to add individual icons per each file
// while also having a limited load of explicit set icons from a well
// maintained and broad set
import { library } from '@fortawesome/fontawesome-svg-core';

// Importing brand icons from free-brands-svg-icons
// I guess there isn't a pro bands to use
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// Importing regular icons from pro-regular-svg-icons
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faArrowDown,
  faArrowsAlt,
  faBan,
  faBriefcase,
  faBuilding,
  faCalendar,
  faChartLine,
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCloud,
  faCog,
  faComment,
  faCommenting,
  faCopy,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faHouse,
  faHouseChimneyWindow,
  faList,
  faListAlt,
  faLongArrowDown,
  faLongArrowUp,
  faMailbox,
  faMessage,
  faMessageSms,
  faPhone,
  faPhoneAlt,
  faPhoneSlash,
  faPlus,
  faPlusSquare,
  faRefresh,
  faSearch,
  faSignsPost,
  faTh,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrophy,
  faUpload,
  faUser,
  faUserPlus,
  faVoicemail,
  faUndo,
  faRedo,
  faCode,
  faSquareEnvelope,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';

// Importing solid icons from pro-solid-svg-icons
import {
  faAlignCenter as faAlignCenterSolid,
  faAlignLeft as faAlignLeftSolid,
  faAlignRight as faAlignRightSolid,
  faArrowDown as faArrowDownSolid,
  faArrowRight as faArrowRightSolid,
  faArrowsAlt as faArrowsAltSolid,
  faBan as faBanSolid,
  faBolt as faBoltSolid,
  faBriefcase as faBriefcaseSolid,
  faBuilding as faBuildingSolid,
  faCalendar as faCalendarSolid,
  faChartLine as faChartLineSolid,
  faChartPie as faChartPieSolid,
  faCheck as faCheckSolid,
  faChevronDown as faChevronDownSolid,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faChevronUp as faChevronUpSolid,
  faCircle as faCircleSolid,
  faCircleNotch as faCircleNotchSolid,
  faCloud as faCloudSolid,
  faCloudUpload as faCloudUploadSolid,
  faCog as faCogSolid,
  faComment as faCommentSolid,
  faCommenting as faCommentingSolid,
  faCopy as faCopySolid,
  faDownload as faDownloadSolid,
  faEdit as faEditSolid,
  faEllipsisV as faEllipsisVSolid,
  faEnvelope as faEnvelopeSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faEye as faEyeSolid,
  faFolder as faFolderSolid,
  faHouse as faHouseSolid,
  faHouseChimneyWindow as faHouseChimneyWindowSolid,
  faList as faListSolid,
  faListAlt as faListAltSolid,
  faLongArrowDown as faLongArrowDownSolid,
  faLongArrowUp as faLongArrowUpSolid,
  faMailbox as faMailboxSolid,
  faMessage as faMessageSolid,
  faMessageImage as faMessageImageSolid,
  faMessageLines as faMessageLinesSolid,
  faMessageSms as faMessageSmsSolid,
  faMinus as faMinusSolid,
  faPenToSquare as faPenToSquareSolid,
  faPhone as faPhoneSolid,
  faPhoneAlt as faPhoneAltSolid,
  faPhoneSlash as faPhoneSlashSolid,
  faPlus as faPlusSolid,
  faPlusSquare as faPlusSquareSolid,
  faRefresh as faRefreshSolid,
  faSearch as faSearchSolid,
  faSignsPost as faSignsPostSolid,
  faShareNodes as faShareNodesSolid,
  faSpinner as faSpinnerSolid,
  faTh as faThSolid,
  faTimes as faTimesSolid,
  faTimesCircle as faTimesCircleSolid,
  faTrash as faTrashSolid,
  faTrophy as faTrophySolid,
  faUpload as faUploadSolid,
  faUser as faUserSolid,
  faUserPlus as faUserPlusSolid,
  faVoicemail as faVoicemailSolid,
  faUndo as faUndoSolid,
  faRedo as faRedoSolid,
  faCode as faCodeSolid,
  faSquareEnvelope as faSquareEnvelopeSolid,
  faPaperPlane as faPaperPlaneSolid,
} from '@fortawesome/pro-solid-svg-icons';

// Adding all icons to the library in one call
library.add(
  faWhatsapp,
  // Regular icons
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faArrowDown,
  faArrowsAlt,
  faBan,
  faBriefcase,
  faBuilding,
  faCalendar,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCloud,
  faCog,
  faComment,
  faCommenting,
  faCopy,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faHouse,
  faHouseChimneyWindow,
  faList,
  faListAlt,
  faLongArrowDown,
  faLongArrowUp,
  faMailbox,
  faMessage,
  faMessageSms,
  faPhone,
  faPhoneAlt,
  faPhoneSlash,
  faPlus,
  faPlusSquare,
  faRefresh,
  faSearch,
  faSignsPost,
  faTh,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrophy,
  faUpload,
  faUser,
  faUserPlus,
  faVoicemail,
  faUndo,
  faRedo,
  faCode,
  faSquareEnvelope,
  faPaperPlane,
  // Solid icons
  faAlignCenterSolid,
  faAlignLeftSolid,
  faAlignRightSolid,
  faArrowDownSolid,
  faArrowRightSolid,
  faArrowsAltSolid,
  faBanSolid,
  faBoltSolid,
  faBriefcaseSolid,
  faBuildingSolid,
  faCalendarSolid,
  faChartLineSolid,
  faChartPieSolid,
  faCheckSolid,
  faChevronDownSolid,
  faChevronLeftSolid,
  faChevronRightSolid,
  faChevronUpSolid,
  faCircleNotchSolid,
  faCircleSolid,
  faCloudSolid,
  faCloudUploadSolid,
  faCogSolid,
  faCommentSolid,
  faCommentingSolid,
  faCopySolid,
  faDownloadSolid,
  faEditSolid,
  faEllipsisVSolid,
  faEnvelopeSolid,
  faExclamationTriangleSolid,
  faEyeSolid,
  faFolderSolid,
  faHouseChimneyWindowSolid,
  faHouseSolid,
  faListAltSolid,
  faListSolid,
  faLongArrowDownSolid,
  faLongArrowUpSolid,
  faMailboxSolid,
  faMessageImageSolid,
  faMessageLinesSolid,
  faMessageSmsSolid,
  faMessageSolid,
  faMinusSolid,
  faPenToSquareSolid,
  faPhoneAltSolid,
  faPhoneSlashSolid,
  faPhoneSolid,
  faPlusSolid,
  faPlusSquareSolid,
  faRefreshSolid,
  faSearchSolid,
  faShareNodesSolid,
  faSignsPostSolid,
  faSpinnerSolid,
  faThSolid,
  faTimesCircleSolid,
  faTimesSolid,
  faTrashSolid,
  faTrophySolid,
  faUploadSolid,
  faUserPlusSolid,
  faUserSolid,
  faVoicemailSolid,
  faUndoSolid,
  faRedoSolid,
  faCodeSolid,
  faSquareEnvelopeSolid,
  faPaperPlaneSolid,
);
