<template>
  <div class="name-wrapper">
    <span>Name:</span>
    <p contenteditable="contenteditable" @input="updateInput">{{ content }}</p>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return { content: '' };
  },
  created() {
    this.content = this.value;
  },
  methods: {
    updateInput() {
      // console.log(this.$el.innerText);
      // console.log(this.$el.children[1].innerText);
      this.$emit('update:modelValue', this.$el.children[1].innerText);
    }
  }
};
</script>
<style lang="scss" scoped>
.name-wrapper {
  span {
    display: inline-block;
    margin-right: 10px;
    font-size: 22px;
    font-weight: 600;
  }
  p {
    display: inline-block;
    color: #000;
    font-size: 22px;
  }
}
</style>
