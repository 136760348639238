<template>
  <div class="s2-spotlight s2-spotlight-overlay" v-show="widgetOpen">
    <global-events
      v-if="widgetOpen && open()"
      @keyup.up="up"
      @keyup.down="down"
      @keyup.tab="complete"
      @keyup.enter="select"
      @keyup.esc="clear"
    />
    <global-events v-if="widgetOpen && isCleared()" @keyup.esc="close" />
    <div class="s2-spotlight-search-bar">
      <div class="s2-spotlight-search-icon">
        <font-awesome-icon icon="search" size="2x" />
      </div>
      <input
        @input="debouncedQuerySearch"
        ref="queryInput"
        type="text"
        class="s2-spotlight-search-input"
        spellcheck="false"
        placeholder="Search..."
        v-model="term"
      />
      <span class="s2-spotlight-autocomplete">{{ suggestion }}</span>
    </div>
    <div class="s2-spotlight-hits" v-bind:class="{ open: open }">
      <ul>
        <li
          v-for="(item, index) in filtered"
          v-scrollto
          v-bind:key="item.id"
          class="s2-spotlight-hit-result"
          v-bind:class="{ selected: selected(index) }"
          @click="action(item)"
        >
          <!--  -->
          <el-row style="width: 100%">
            <el-col :span="2" style="text-align: center">
              <font-awesome-icon
                v-if="item.contact_type === 'contact'"
                :icon="['fas', 'user']"
                style="padding-right: 5px"
              />

              <font-awesome-icon
                v-if="item.contact_type === 'company'"
                :icon="['fas', 'building']"
                style="padding-right: 5px"
              />
            </el-col>
            <el-col :span="22">
              <div
                class="s2-spotlight-names"
                style="line-height: 20px; margin-top: 0.2em"
              >
                <template v-if="item.display_name">
                  {{ item.display_name }}
                </template>
                <template v-if="item.first_name || item.last_name">
                  {{ item.first_name + ' ' + item.last_name }}
                </template>
              </div>
              <div
                class="s2-spotlight-email-phone"
                style="line-height: 14px; margin-top: 0.2em"
              >
                <div v-if="item.email">
                  <p class="spotlight-email">{{ item.email }}</p>
                </div>
                <div v-if="item.mobile_phone">
                  <p class="spotlight-phone">
                    {{ formatPhoneNumber(item.mobile_phone) }}
                  </p>
                </div>
                <div v-if="item.company_name">
                  <p class="spotlight-phone">
                    {{ item.company_name }}
                  </p>
                </div>
              </div>
            </el-col>
          </el-row>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { GlobalEvents } from 'vue-global-events';

import searchService from 'src/services/searchServices';

export default {
  name: 'SendSquaredSpotlight',
  components: {
    GlobalEvents,
  },
  data() {
    return {
      term: '',
      selectedIndex: -1,

      // Build Suggestion at some point in the future.
      suggestion: '',

      // In the future, allow further refinement with filtered vs result
      filtered: [],
      result: [],

      // window open
      widgetOpen: false,

      debouncer: null,
    };
  },
  props: {
    toggleOpen: {
      type: Boolean,
      default: () => false,
    },
    doRedirect: {
      type: Boolean,
      default: () => true,
    },
  },
  watch: {
    toggleOpen() {
      this.widgetOpen = !this.widgetOpen;
    },
  },
  updated() {
    if (this.widgetOpen) {
      this.focusInput();
    }
  },
  methods: {
    debounce(func, wait) {
      return function(...args) {
        clearTimeout(this.debouncer);
        this.debouncer = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    },
    focusInput() {
      try {
        this.$refs.queryInput.focus();
      } catch (e) {}
    },
    clear() {
      this.selectedIndex = -1;
      this.filtered = [];
      this.term = '';
    },
    up() {
      this.selectedIndex = Math.max(this.selectedIndex - 1, -1);
    },
    down() {
      this.selectedIndex = Math.min(
        this.selectedIndex + 1,
        this.filtered.length - 1
      );
    },
    complete() {
      if (this.selectedIndex == -1) {
        this.term = this.suggestion;
        this.querySearch();
      }
    },
    select() {
      if (this.selectedIndex > -1) {
        const elem = this.filtered[this.selectedIndex];
        this.selectedIndex = -1;
        this.filtered = [];
        this.term = '';
        this.action(elem);
      }
    },
    open() {
      return this.filtered.length > 0;
    },
    close() {
      this.clear();
      this.widgetOpen = false;
    },
    isCleared() {
      return this.filtered.length === 0;
    },
    selected(index) {
      return this.selectedIndex === index;
    },

    async querySearch() {
      if (this.term === '') {
        this.suggestion = '';
        this.filtered = [];
        return;
      }
      const r = await searchService.searchByContactNameEmail(
        this.term,
        0,
        true
      );
      this.total = parseInt(r.data.length, 10);
      this.zeroResults = this.total === 0 ? true : false;

      this.filtered = [];
      for (const inc in r.data.contacts) {
        this.filtered.push(r.data.contacts[inc]);
      }
    },
    action(item) {
      let contactType = 'contacts';
      if (item.contact_type === 'company') contactType = 'companies';
      this.widgetOpen = false; // Close our window
      if (this.doRedirect) {
        this.$router.push('/admin/' + contactType + '/view/' + +item.id);
      } else {
        this.$emit('update:contactId', item.id);
      }
    },
  },
  mounted() {
    this.debouncedQuerySearch = this.debounce(this.querySearch, 300);
    this._keyListener = function (e) {
      if (e.key === 'p' && (e.ctrlKey || e.metaKey)) {
        // present "Print Page" from getting triggered.
        e.preventDefault();
        this.widgetOpen = !this.widgetOpen;
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this._keyListener);
    clearTimeout(this.debouncer);
  },
  directives: {
    scrollto: {
      querySearch(el) {
        if (el.classList.contains('selected')) {
          el.scrollIntoView({ block: 'nearest' });
        }
      },
    },
  },
};
</script>

<style lang="scss">
// FIXME: This is not centering on the screen horizontially,
.s2-spotlight-overlay {
  position: absolute;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  z-index: 61000;
  font-size: 12px;
  overflow: hidden;
  border-radius: 6px;
  margin-left: 0px;
  letter-spacing: 0.3px;
  font-family: Verdana, 'Lucida Sans Unicode', sans-serif;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.s2-spotlight {
  width: 50vw;
  align-items: center;
  .s2-spotlight-search-bar {
    z-index: 62000;
    height: 55px;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    // background-color: rgba(0, 21, 41, 0.918);
    background-color: #444444fa;
  }
  .s2-spotlight-search-icon {
    width: 22px;
    height: 22px;
    margin: 16px;
    position: static;
    background-size: cover;
  }
  .s2-spotlight-search-icon > svg {
    color: #a6a6a6;
  }
  .s2-spotlight-search-input {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 22px;
    height: 55px;
    font-weight: 300;
    padding-left: 5px;
    box-sizing: content-box;
    border: none !important;
    outline: none !important;
    max-width: 450px !important;
    background-color: transparent;
    z-index: 900;
    font-family: sans-serif;
    letter-spacing: 0.3px;
  }
  .s2-spotlight-search-input::-moz-placeholder {
    color: #a6a6a6;
  }
  .s2-spotlight-search-input::-webkit-input-placeholder {
    color: #a6a6a6;
  }
  .s2-spotlight-autocomplete {
    height: 55px;
    position: absolute;
    top: 14px;
    left: 60px;
    margin: 0;
    padding: 0;
    color: #a6a6a6;
    font-size: 22px;
    font-weight: 300;
    font-family: sans-serif;
    letter-spacing: 0.3px;
  }
  .s2-spotlight-hits {
    max-height: 0;
    min-height: 0;
    transition: all 0.3s;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .s2-spotlight-hits::-webkit-scrollbar {
    display: none;
  }
  .s2-spotlight-hits.open {
    max-height: 60vh;
    border-top: 1px solid #515253;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
  }
  .s2-spotlight-hit-result {
    color: #fff;
    cursor: pointer;
    font-size: 22px;
    font-weight: 100;
    background-color: #444444fa;
    padding: 6px 6px 6px 0 !important;
    display: flex;
    justify-content: left;
    align-items: center;
    &:hover {
      background: rgb(11, 80, 72);
    }
  }
  .s2-spotlight-hit-result.selected {
    background-color: rgb(11, 80, 72);
  }
  .s2-spotlight-hit-result-image {
    padding: 0 10px 0 10px;
  }
  .spotlight-email {
    font-size: 14px;
  }
  .spotlight-phone {
    font-size: 14px;
  }
  .s2-spotlight-names {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 20px;
    margin-top: 0.2em;
    line-height: 20px;
    margin-top: 0.2em;
  }
  .s2-spotlight-email-phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.flex-grow {
  flex: 1 0 auto;
}
</style>
