<template>
  <color-picker
    :value="currentValue"
    v-model="currentValue"
    @change="transportProperties"
    :show-alpha="transparent"
    size="small"
  ></color-picker>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ColorPicker from './ColorPicker';
export default {
  props: {
    disabled: Boolean,
    value: {
      required: false,
      default: '',
    },
    transport: {
      type: String,
      required: false,
      default: '',
    },
    useBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ColorPicker,
  },
  computed: {
    ...mapGetters('editor', ['getDecodedSelectedBlock']),
  },
  data() {
    return {
      currentValue: this.value,
      inputDisabled: false,
    };
  },
  created() {
    this.currentValue = this.value;
  },
  updated() {
    this.currentValue = this.value;
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
  },
  methods: {
    ...mapActions('editor', ['transportBlockSettings']),
    transportProperties(color) {
      //
      console.log('we are transporting from EditorColor', this.getDecodedSelectedBlock, color);

      let transport = { useBackground: this.useBackground };
      transport[this.transport] = this.currentValue;
      transport[this.transport] = color;
      // Transport
      console.log('editor color is transporting this', transport);
      this.transportBlockSettings({
        section: this.getDecodedSelectedBlock.section,
        row: this.getDecodedSelectedBlock.row,
        column: this.getDecodedSelectedBlock.column,
        data: transport,
      });
    },
  },
};
</script>
