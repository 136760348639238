<template>
    <el-dropdown trigger="click" @command="toggleValue"
      ref="menudrop" class="transfer-dropdown"
      v-if="useMenu"
    >
      <span class="el-dropdown-link"></span>
      <template #dropdown>
        <el-dropdown-menu slot="dropdown"
          class="transfer-dropdown-menu"
          >
          <el-dropdown-item divided
            @click.native.prevent.stop="toggleCallControl()"
            :command="'dialpad'"
            v-bind:key="'callControl'"
            >Dial Pad</el-dropdown-item>
            <call-control
              v-if="showCallControl"
              v-on:accept="showCallControl = !showCallControl"
              :callId="callId"
              v-on:dial="doDial"
              ></call-control>
          <el-dropdown-item divided disabled
              v-if="getStandardQueues.length && !showCallControl"
            >Standard Queues</el-dropdown-item>
            <el-dropdown-item
              v-for="queue in getStandardQueues"
              v-bind:key="'queue:' + queue.id"
              :command="'queue:' + queue.id"
              >{{queue.name}}</el-dropdown-item>

          <el-dropdown-item divided disabled
              v-if="getAgentQueues.length && !showCallControl"
            >Agent Queues</el-dropdown-item>
            <el-dropdown-item
              v-for="queue in getAgentQueues"
              v-bind:key="'queue:' + queue.id"
              :command="'queue:' + queue.id"
            >{{queue.name}}</el-dropdown-item>

          <el-dropdown-item divided disabled
              v-if="getDirectDials.length && !showCallControl"
            >Direct Dials</el-dropdown-item>
            <el-dropdown-item
              v-for="dial in getDirectDials"
              v-bind:key="'dial:' + dial.id"
              :command="'directDial:' + dial.id"
            >{{dial.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-select label="Select Queue" v-model="selected" @change="toggleValue()"
      v-if="!useMenu">
      <el-option-group v-if="getStandardQueues.length"
        >Standard Queues
        <el-option
            v-for="queue in getStandardQueues"
            v-bind:key="queue.id"
          :value="queue.id"
          :label="queue.name"
        ></el-option>
      </el-option-group>

      <el-option-group v-if="getDirectDials.length"
        >Direct Dials
        <el-option
            v-for="dial in getDirectDials"
            v-bind:key="dial.id"
          :value="dial.id"
          :label="dial.name"
        ></el-option>
      </el-option-group>

      <el-option-group v-if="getAgentQueues.length"
        >Agent Queues
        <el-option
            v-for="queue in getAgentQueues"
            v-bind:key="queue.id"
          :value="queue.id"
          :label="queue.name"
        ></el-option>
      </el-option-group>
    </el-select>
</template>

<script setup>
  import { reactive, computed, ref, watch, onMounted } from 'vue'
  import { mapActions } from 'vuex';
  import { useStore } from 'vuex'

  import CallControl from 'src/components/UIComponents/CallControl';

  // Component bits

  const name = 'transfer-select';
  const store = useStore();

  const emit = defineEmits(['input', 'update', 'updateContent']);

  // Data
  // import type { DropdownInstance } from 'element-plus'
  const menudrop = ref(null);
  const selected = ref(0);
  const showCallControl = ref(false);
  const orderedQueues = ref([]);

  // Computed
  const getQueues = computed(() => {
    return store.getters['queues/getQueues'];
  });
  const getDirectDials = computed(() => {
    return store.getters['voice/getDirectDials'];
  });
  const getAgentQueues = computed(() => {
    return getQueues.value.filter(q => q.queue_type == 'agent');
  });
  const getStandardQueues = computed(() => {
    return getQueues.value.filter(q => q.queue_type == 'standard');
  });

  // Props
  const props = defineProps({
      value: {
          type: String,
          required: false,
      },
      reload: {
          type: Boolean,
          required: false,
          default: () => { return false },
      },
      useMenu: {
          type: Boolean,
          required: false,
          default: () => { return false },
      },
      callId: {
        type: Number,
        required: true,
        description: 'Current Call that is being controlled',
      },
  });


  // Methods
  async function fetchQueues() {
    return store.dispatch('queues/fetchQueues');
  }
  async function fetchDirectDials() {
    return store.dispatch('voice/fetchDirectDials');
  }

  // const menuDrop = ref<DropdownInstance>();
  function toggleValue(value) {
    // Set internal when using menu
    if (props.useMenu) {
      selected.value = value;
    }
    // It passed the value to the children so it needs to be false to prevent child errors
    if (value === 'dialpad') {
      menudrop.value.handleClose();
      return undefined;
    }
    emit('update:value', String(selected.value));
    return undefined;
  };
  function toggleCallControl() {
    showCallControl.value = !showCallControl.value;
    return false;
  };
  function doDial(digits) {
    emit('update:value', 'dial:' + digits);
  }


  /*
   Not sure we need this
  watch(props.value, (n) => {
    selected.value = n;
  });
  */

  // Created

  onMounted(async () => {
    // Force it open on show
    if (props.useMenu && menudrop) {
      menudrop.value.handleOpen();
    }
    if (props.value) {
      selected.value = parseInt(props.value);
    }
    if (props.reload || !getQueues.value.length) {
      await fetchQueues();
    }
    if (props.reload || !getDirectDials.value.length) {
      await fetchDirectDials();
    }
  })
</script>
<style scoped lang="scss">
.transfer-dropdown{
  position: relative;
  left: -200px;
  .transfer-dropdown-menu{
    overflow-y: scroll; height: 85%; overflow-x: clip;
  }
}
</style>
