<template>
  <div
    class="incoming-call-frame"
    :class="showIncomingCall ? 'display-incoming-call' : ''"
    :style="'height:' + this.attrHeight + 'px;'"
  >
    <!-- Sidebar -->
    <div class="call-center-toggle-menu">
      Incoming Call
      <font-awesome-icon
        @click="closeIncomingCall()"
        icon="times-circle"
        class="call-toggle-button pull-right"
      />
    </div>
    <!-- Load in each component -->
    <active-call-name
      :caller-name="callerName"
      :phone-number="phoneNumber"
    ></active-call-name>
    <div class="queueName" v-if="queueName">
      {{ queueName }}
    </div>
    <div v-if="attributesLength > 0" class="incoming-call-attributes">
      <el-row v-for="row in stayAttributes" :key="row.label">
        <el-col :span="8"
          ><small>{{ row.label }}</small></el-col
        >
        <el-col :span="16" align="center"
          ><small>{{ row.value }}</small></el-col
        >
      </el-row>
    </div>
    <el-row style="margin-top: 10px">
      <el-col :span="12" align="center">
        <el-button type="danger" @click="$emit('reject', callId)" circle
          ><font-awesome-icon icon="phone" /></el-button
        ><br />Decline
      </el-col>
      <el-col :span="12" align="center" v-show="allowAnswer">
        <el-button type="primary" @click="answerCall()" circle
          ><font-awesome-icon icon="phone" /></el-button
        ><br />Accept
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import ActiveCallName from './ActiveCallName';

const RING_URL =
  'https://adbase-static.s3.amazonaws.com/sounds/telephone_digital_ring.mp3';

export default {
  components: {
    ActiveCallName,
  },
  computed: {
    ...mapGetters('appConfig', ['getAppConfig']),
    ...mapGetters('queues', ['getQueues']),
    ...mapGetters('realtime', ['getCallData', 'getCallById']),
    attributesLength() {
      return Object.keys(this.stayAttributes).length;
    },
  },
  created() {
    // Set these immediately but show the popup right away
    // Then as more data comes in populate the rest
    // Only need to do this for Band
    if (this.playRing) {
      const ringUrl = this.getAppConfig?.callCenter?.ringAudio ?? RING_URL;
      this.ringAudio = new Audio(ringUrl);
      // Play ring right away too
      this.ringAudio.play();
      this.ringInterval = setInterval(() => {
        this.ringAudio.play();
      }, 2000);
    }
    // Only bandwidth as they dont have callbacks
    if (this.autoReject) {
      this.rejectTimeout = setTimeout(() => {
        if (this.getCallById(this.callId).call_status != 'answered') {
          this.$emit('reject', this.callId);
        }
      }, this.rejectDelay);
    }
  },
  beforeUnmount() {
    if (this.ringAudio) {
      this.ringAudio.pause();
      this.ringAudio = undefined;
    }
    if (this.ringInterval) {
      clearInterval(this.ringInterval);
      this.ringInterval = undefined;
    }
    if (this.rejectTimeout) {
      clearTimeout(this.rejectTimeout);
      this.rejectTimeout = undefined;
    }
  },
  props: {
    callData: Object, // Updated data, eventually
    allowAnswer: Boolean,
    playRing: Boolean,
    callId: {
      type: Number, // Call object id
      required: true,
    },
    // call: Object,
    autoReject: {
      type: Boolean,
      required: true,
    },
    queueId: Number,
  },
  watch: {
    callData: {
      immediate: true,
      handler(callData) {
        try {
          this.buildIncomingCallData(this.callData);
        } catch (e) {
          // sometimes we can't always build the data fast enough.
          // - this will fault the front end, and
          //   place the backend in wrapup once the call ends.
          console.error("Can't render incoming data:", e);
        }
      },
    },
    queueId: {
      immediate: true,
      handler(queueId) {
        if (!queueId) {
          return;
        }
        this.queueName = this.getQueues.find((q) => queueId == q.id)?.name;
      },
    },
  },
  data() {
    return {
      attrHeight: 180,
      callerName: '',
      queueName: undefined,
      phoneNumber: '',
      ringAudio: undefined,
      ringInterval: undefined,
      rejectTimeout: undefined,
      rejectDelay: 15000, // miliseconds before auto reject
      showIncomingCall: true, // TODO: remove this
      stayAttributes: [
        // { key: 'total_revenue', label: 'Revenue', value: '' },
        // { key: 'arrival_date', label: 'Arrival', value: '' },
        // { key: 'departure_date', label: 'Departure', value: '' },
        // { key: 'unit_code', label: 'unit', value: '' },
      ],
    };
  },
  methods: {
    closeIncomingCall() {
      this.showIncomingCall = false;
    },
    answerCall() {
      if (this.rejectTimeout) {
        clearTimeout(this.rejectTimeout);
        this.rejectTimeout = undefined;
      }
      this.$emit('answer', this.callId);
      if (this.ringAudio) {
        this.ringAudio.pause();
      }
      if (this.ringInterval) {
        clearInterval(this.ringInterval);
        this.ringInterval = undefined;
      }
    },
    async buildIncomingCallData(data) {
      if (data == undefined) {
        return;
      }
      this.callerName = data.first_name + ' ' + data.last_name;
      this.phoneNumber = data.from_number;

      // Populate the rest when it becomes available
      if (!data.reservation) {
        return;
      }

      const fieldTypes = {
        total_revenue: 'currency',
        arrival_date: 'date',
        departure_date: 'date',
        unit_code: 'string',
      };
      const fieldLabels = {
        total_revenue: 'Revenue',
        arrival_date: 'Arrival',
        departure_date: 'Departure',
        unit_code: 'Unit Code',
      };

      for (const [k, v] of Object.entries(fieldTypes)) {
        let value;
        if (data.reservation[k]) {
          switch (v) {
            case 'currency':
              value = this.toCurrency(data.reservation[k], true);
              break;
            case 'date':
              value = this.cleanDate(data.reservation[k], true);
              break;
            case 'string':
              value = String(data.reservation[k]);
              break;
          }
          this.stayAttributes.push({
            key: k,
            label: fieldLabels[k],
            value: value,
          });
        }
      }
      this.attrHeight = this.attributesLength * 36 + 180;
    },
  },
};
</script>
<style>
.incoming-call-frame {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  background: #fff;
  z-index: 60000;
  height: 320px;
  display: none;

  border: 2px #3333333a solid;
  border-radius: 2px;

  box-shadow: 5px 5px 0px 0px #00201d3a, 10px 10px 0px 0px #0037313a,
    15px 15px 0px 0px #0b50493a, 20px 20px 0px 0px #1f68603a,
    25px 25px 0px 0px #3b827a3a, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
.incoming-call-attributes {
  background: #eee;
  border-radius: 2px;
  padding: 1.2em;
  margin-left: 1em;
  margin-right: 1em;
}
.queueName {
  text-align: center;
  padding-top: 0.2em;
  font-family: 'open sans';
  font-size: 18px;
}
.display-incoming-call {
  display: block;
}
.call-center-toggle-menu {
  width: 100%;
  height: 25px;
  text-align: center;
  padding-top: 0.2em;
  font-family: 'open sans';
  font-size: 18px;
  font-weight: 500;
}
.call-toggle-button {
  cursor: pointer;
}
</style>
