import reservationService from 'src/services/reservationServices';

const state = {
  units: undefined
};

const getters = {
  getUnits: (state) => state.units,
};
const actions = {
  async fetchUnits({ commit, state, rootGetters }) {
    // Only load the data once
    if (state.units == undefined) {
      // Works up to 1000 units. We need search and paging after that anyway
      return reservationService.fetchUnits()
      .then(r => {
        commit('setUnits', r.data);
      });
    }
  },
  unloadUnits({ commit }) {
    commit('setUnits', undefined);
  }
};
const mutations = {
  setUnits(state, units) {
    state.units = units;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
