<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page section-image">
        <el-row justify="center" align="middle" class="login-frame">
          <el-col :md="8" :xs="24">
            <el-card>
              <div class="logo">
                <img
                  src="~img/logo/ss-logo-8-12-2021.svg"
                  style="width: 230px; margin-top: 1em; filter: brightness(1%)"
                  alt="SendSquared"
                />
              </div>
              <p>We can't connect to our servers, perhaps you're offline?</p>
              <p>
                This page will automatically go back to the login screen once
                you're back online.
              </p>
              <p>
                If you feel you're here in error, click to &quot;check now&quot;
                below.
              </p>
              <br />
              <p-button
                native-type="button"
                slot="footer"
                block
                class="mb-3 btn-primary"
                @click="checkConnection()"
                >Check now</p-button
              >
            </el-card>
          </el-col>
        </el-row>
        <app-footer></app-footer>
        <div
          class="full-page-background color-yellow"
          :style="getBackground"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import AppFooter from './Layout/AppFooter';
import { publicAxios } from 'src/shared/axiosWrapper';

export default {
  computed: {
    ...mapState({
      account: (state) => state.auth.authAccount,
    }),
    getBackground() {
      let number = Math.floor(Math.random() * 4 + 1);
      return `background-image: url('https://static.sendsquared.com/images/oct-2021-background-${number}.jpg');`;
    },
  },
  components: {
    AppFooter,
  },
  methods: {
    async checkConnection() {
      let response;
      try {
        response = await publicAxios.get('/v1/pub/online');
        if (response.status === 200) {
          this.$router.push('/login');
        }
      } catch (err) {
        console.log(err);
      }
    },
    addLoginClass() {
      document.body.classList.add('drop-margin');
    },
    removeLoginClass() {
      document.body.classList.remove('drop-margin');
    },
  },
  data() {
    return {
      heartbeatInterval: undefined,
    };
  },
  beforeUnmount() {
    this.removeLoginClass();
  },
  created() {
    this.addLoginClass();
    this.heartbeatInterval = setInterval(
      async function () {
        let response;
        let good = 0;
        try {
          // response = await authedAxios.get('/v1/admin/valid');
          response = await publicAxios.get('/v1/pub/online');
          good = 1;
        } catch (err) {}
        if (good === 1) {
          this.$router.push('/login');
        }
      }.bind(this),
      10 * 1000 // 5 seconds
    );
  },
  beforeUnmount() {
    clearInterval(this.heartbeatInterval);
  },
};
</script>
<style lang="scss" scoped>
.logo {
  text-align: center;
  img {
    // width: 75%;
    margin: 0 auto;
    margin-bottom: 2em;
  }
}

.login-frame {
  z-index: 4;
  height: 90vh;
}
</style>
